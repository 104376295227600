<template>
  <div>
    <h2 class="mb8">{{ content }}</h2>
    <Checkbox v-model="readTalg" class="mb10" @on-change="checkboxChange">
      <span>{{ $t('key1000326') }} </span>
      <span class="cursor" :class="systemType === 'distributor'? 'distributorThemeColor' : 'themeColor'"
        @click.stop.prevent="termsOfServiceChange">{{ $t('key1000039') }}</span>
      <span class="ml3 mr3">{{ $t('key1000327') }}</span>
      <span class="cursor" :class="systemType === 'distributor'? 'distributorThemeColor' : 'themeColor'"
        @click.stop.prevent="privacyPolicyChange">{{ $t('key1000040') }}</span>
    </Checkbox>
    <div class="flex align-items-center justify-content-end mt20">
      <Button class="mr8" @click="cancelBtn">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="queryBtn" :loading="loading">{{ okText }}</Button>
    </div>
    <!--服务条款弹窗-->
    <Modal v-model="termsOfServiceModal" width="1000" :styles="{top: '100px'}" :title="$t('key1000039')">
      <div class="docx">
        <component :is="termsOfService"></component>
      </div>
      <template #footer>
        <Button @click="termsOfServiceModal=false">{{ $t('key1000204') }}</Button>
      </template>
    </Modal>
    <!--隐私政策弹窗-->
    <Modal v-model="privacyPolicyModal" width="1000" :styles="{top:'100px'}" :title="$t('key1000040')">
      <div class="docx">
        <component :is="privacyPolicy"></component>
      </div>
      <template #footer>
        <Button @click="privacyPolicyModal=false">{{ $t('key1000204') }}</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';


import distributorTermsOfServiceCN from "@/views/shopping/common/termsOfServiceCN";
import distributorTermsOfServiceUS from "@/views/shopping/common/termsOfServiceUS";
import distributorPrivacyPolicyCN from "@/views/shopping/common/privacyPolicyCN";
import distributorPrivacyPolicyUS from "@/views/shopping/common/privacyPolicyUS";

import supplierServiceTermsCN from "@/views/shopping/common/supplierServiceTermsCN";
import supplierServiceTermsUS from "@/views/shopping/common/supplierServiceTermsUS";
import supplierPrivacyPolicyCN from "@/views/shopping/common/privacyPolicyCN";
import supplierPrivacyPolicyUS from "@/views/shopping/common/privacyPolicyUS";
import api from '@/api/';
import {getHrefPath, errorUrlHandel, deleteTicket} from "@/utils/common";
import {getToken, removeToken} from "@/utils/cookie";


export default {
  name: "becomingSupplierDistributorModal",
  props: {
    systemType: {
      type: String,
      default: 'distributor'
    },
    content: {
      type: String,
      default: ''
    },
    okText: {
      type: String,
      default: ''
    },
    $t: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      readTalg: false,
      termsOfServiceModal: false,
      privacyPolicyModal: false,
      termsOfService: distributorTermsOfServiceCN,
      privacyPolicy: distributorPrivacyPolicyCN,
    }
  },
  methods: {
    // 服务条款
    termsOfServiceChange() {
      let v = this;
      let talg = localStorage.getItem('setLanguages') === 'CN';
      if (['distributor', 'tongTool'].includes(v.systemType)) {
        v.termsOfService = talg ? distributorTermsOfServiceCN : distributorTermsOfServiceUS;
      } else {
        v.termsOfService = talg ? supplierServiceTermsCN : supplierServiceTermsUS;
      }
      v.termsOfServiceModal = true;
    },
    // 隐私政策
    privacyPolicyChange() {
      let v = this;
      let talg = localStorage.getItem('setLanguages') === 'CN';
      if (['distributor', 'tongTool'].includes(v.systemType)) {
        v.privacyPolicy = talg ? distributorPrivacyPolicyCN : distributorPrivacyPolicyUS;
      } else {
        v.privacyPolicy = talg ? supplierPrivacyPolicyCN : supplierPrivacyPolicyUS;
      }
      v.privacyPolicyModal = true;
    },
    // 勾选框
    checkboxChange(value) {
      this.$emit('checkboxChange', value);
    },
    // 开通按钮
    queryBtn() {
      let v = this;
      let merchantId = localStorage.getItem('merchantId');
      let urlObj = {
        distributor: api.post_ymsDistributorMerchant_confirmAccount,
        supplier: api.post_ymsSupplierMerchant_confirmAccount,
        tongTool: api.put_ymsDistributorMerchant_settleIn
      }
      let url = urlObj[v.systemType];

      if (v.readTalg) {
        v.loading = true;
        if (v.systemType !== 'tongTool') {
          let href = window.location.href;
          const regex = /emailOrMobile=([^&]+)/;
          const match = href.match(regex);
          let emailOrMobile = '';
          if (match) {
            emailOrMobile = decodeURIComponent(match[1]); // 解码特殊字符
          }
          let query = {
            callBackLink: href,
            merchantId: merchantId,
            mobileOrEmail: emailOrMobile
          }
          v.axios.post(url, query, {laoding: true, loadingText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212')}).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              if (data) {
                if (v.systemType === 'distributor') {
                  v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000328'));
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 500);
                }
                // 跳转到商城--供应商入驻页面---填写企业信息
                else {
                  if (merchantId) {
                    let url = `/login.html#/supplierSettlement?supplierMerchantId=${merchantId}`;
                    let service = '/yms-shopping-service';
                    window.location.href = getHrefPath(url, service);
                  }
                }
              }
            } else {
              v.loading = false;
            }
          });
        } else {
          v.axios.put(url + `?merchantId=${merchantId}`, null, {laoding: true, loadingText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212')}).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000328'));
              setTimeout(() => {
                window.location.reload(true);
              }, 200);
            } else {
              v.loading = false;
            }
          });
        }
      } else {
        v.$Message.warning(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000329'));
        return false;
      }
    },
    // 取消
    cancelBtn() {
      let v = this;
      v.axios.get(api.get_logout + getToken()).then(response => {
        if (response.data.code === 0) {
          removeToken().then(() => {
            let url = deleteTicket(window.location.href);
            url = errorUrlHandel(url);
            window.location.href = response.data.others.logOutUrl + encodeURIComponent(url);
          });
        }
      });
    }
  },
  components: {
    distributorTermsOfServiceCN,
    distributorTermsOfServiceUS,
    distributorPrivacyPolicyCN,
    distributorPrivacyPolicyUS,
    supplierServiceTermsCN,
    supplierServiceTermsUS,
    supplierPrivacyPolicyCN,
    supplierPrivacyPolicyUS
  }
}
</script>

<style scoped lang="less">
.docx {
  padding: 38px;
  background-color: #ffffff;
  color: #000;
  max-height: 600px;
  overflow-y: auto;
}
</style>