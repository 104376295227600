export default {
  key1000000: `复制`,
  key1000001: `标题：`,
  key1000002: `链接地址：`,
  key1000003: `内容已被停用或删除！`,
  key1000004: `复制成功！`,
  key1000005: `请输入菜单关键词`,
  key1000006: `请输入正确的关键词`,
  key1000007: `请输入关键词`,
  key1000008: `上架工具`,
  key1000009: `通途Listing`,
  key1000010: `YMS商城`,
  key1000011: `请输入关键字`,
  key1000012: `搜索`,
  key1000013: `全部商品类目`,
  key1000014: `当前有 `,
  key1000015: ` 条订单超时未发，`,
  key1000016: `请点击处理`,
  key1000017: `运营管理`,
  key1000018: `仓储管理`,
  key1000019: `查看更多`,
  key1000020: `时区：`,
  key1000021: `您当前的时间：`,
  key1000022: `世界标准时间：`,
  key1000023: `登出`,
  key1000024: `运营系统`,
  key1000025: `供应商系统`,
  key1000026: `分销商系统`,
  key1000027: `商户编号：`,
  key1000028: `通途客户编号：`,
  key1000029: `正在退出...`,
  key1000030: `成为分销商`,
  key1000031: `立即注册`,
  key1000032: `成为供应商`,
  key1000033: `供应商入驻`,
  key1000034: `入驻指南`,
  key1000035: `供应商登录`,
  key1000036: `帮助中心`,
  key1000037: `常见问题`,
  key1000038: `法律声明`,
  key1000039: `服务条款`,
  key1000040: `隐私政策`,
  key1000041: `关于我们`,
  key1000042: `YMS商城介绍`,
  key1000043: `平台业务`,
  key1000044: `联系我们`,
  key1000045: `业务咨询`,
  key1000046: `分销商在线服务`,
  key1000047: `深圳云卖供应链管理有限公司`,
  key1000048: `备案号：粤ICP备2020140415号-1`,
  key1000049: `多`,
  key1000050: `海量优质货源`,
  key1000051: `20w+SKU，每日上新500+`,
  key1000052: `快`,
  key1000053: `一键上传全平台`,
  key1000054: `开店必备，5分钟开好店铺`,
  key1000055: `好`,
  key1000056: `专业团队品质保障`,
  key1000057: `专业拣货、分拣、打包、发货等服务`,
  key1000058: `省`,
  key1000059: `0库存开店，无资金压力`,
  key1000060: `无需囤货备货，先出单后付款`,
  key1000061: `请输入要搜索的关键词`,
  key1000062: `上传图片搜索`,
  key1000063: `一站式跨境服装分销`,
  key1000064: `上传文件格式有误,`,
  key1000065: `请选择jpg、jpeg、png格式文件`,
  key1000066: `文件大小受限,文件`,
  key1000067: `太大,`,
  key1000068: `不能超过20M`,
  key1000069: `全部商品`,
  key1000070: `已停售`,
  key1000071: `商户号：`,
  key1000072: `退出`,
  key1000073: `请登录`,
  key1000074: `免费注册`,
  key1000075: `消息`,
  key1000076: `入门指引`,
  key1000077: `个人中心`,
  key1000078: `我的收藏`,
  key1000079: `我的订单`,
  key1000080: `Hi，欢迎来到TONGTOOL！`,
  key1000081: `Hi，欢迎来到YMS商城！`,
  key1000082: `搜全站`,
  key1000083: `搜本店`,
  key1000084: `操作成功!`,
  key1000085: `商品名称：`,
  key1000086: `请选择`,
  key1000087: `无匹配数据`,
  key1000088: `加载中`,
  key1000089: `暂无数据`,
  key1000090: `更多`,
  key1000091: `删除`,
  key1000092: `暂无筛选结果`,
  key1000093: `筛选`,
  key1000094: `重置`,
  key1000095: `全部`,
  key1000096: `确定`,
  key1000097: `取消`,
  key1000098: `自定义列展示`,
  key1000099: `自定义列显示`,
  key1000100: `恢复默认`,
  key1000101: `保存为默认`,
  key1000102: `保存成功`,
  key1000103: `更多筛选项`,
  key1000104: `最近一周`,
  key1000105: `最近一个月`,
  key1000106: `最近三个月`,
  key1000107: `现货一件代发`,
  key1000108: `物流面单`,
  key1000109: `云仓面单`,
  key1000110: `无面单`,
  key1000111: `混合面单`,
  key1000112: `文件大小超出限制，最大为10M`,
  key1000113: `系统繁忙，上传文件失败！`,
  key1000114: `文件格式有误`,
  key1000115: `收起`,
  key1000116: `没有相关记录`,
  key1000117: `系统繁忙，请重新尝试`,
  key1000118: `密码不能为空`,
  key1000119: `密码不能包含中文`,
  key1000120: `请填写邮件地址`,
  key1000121: `不是有效的邮箱或者邮箱格式不正确`,
  key1000122: `手机号码不能为空`,
  key1000123: `手机号码格式不正确`,
  key1000124: `只允许输入数字格式号码`,
  key1000125: `固定电话号码格式不正确`,
  key1000126: `省市区地址不能为空`,
  key1000127: `请选择完整的省市区地址`,
  key1000128: `确认是否删除`,
  key1000129: `温馨提示`,
  key1000130: `操作成功`,
  key1000131: `包裹号`,
  key1000132: `资料覆盖提醒`,
  key1000133: `新的售卖产品资料将覆盖已经生成过的售卖产品资料，生成后不可恢复，是否生成？`,
  key1000134: `立即生成`,
  key1000135: `请先勾选要生成售卖资料的数据！`,
  key1000136: `任务生成成功，请至生成售卖资料模块中查看结果。`,
  key1000137: `当前用户没有未开启仓储管理设置`,
  key1000138: `知道了`,
  key1000139: `当前用户已开启仓储管理设置`,
  key1000140: `调价`,
  key1000141: `跳转失败！`,
  key1000142: `普货`,
  key1000143: `纯电池`,
  key1000144: `配套电池`,
  key1000145: `液体`,
  key1000146: `药品`,
  key1000147: `粉末`,
  key1000148: `危险品`,
  key1000149: `内置电池`,
  key1000150: `纽扣电池`,
  key1000151: `带磁`,
  key1000152: `1天`,
  key1000153: `2天`,
  key1000154: `3天`,
  key1000155: `4天`,
  key1000156: `5天`,
  key1000157: `6天`,
  key1000158: `7天`,
  key1000159: ` 相同`,
  key1000160: `图片`,
  key1000161: `未匹配`,
  key1000162: `指定产品`,
  key1000163: `指定站点`,
  key1000164: `指定帐号`,
  key1000165: `并且`,
  key1000166: `或者`,
  key1000167: `以`,
  key1000168: `不以`,
  key1000169: `运输类型为空`,
  key1000170: `添加成功`,
  key1000171: `操作失败，请重新尝试`,
  key1000172: `删除失败,请重新尝试`,
  key1000173: `指定店铺`,
  key1000174: `指定国家`,
  key1000175: `指定分类`,
  key1000176: `请将邮编段填写完整`,
  key1000177: `指定基础物流`,
  key1000178: `多品`,
  key1000179: `单品`,
  key1000180: `拣货单:`,
  key1000181: `拣货仓库：`,
  key1000182: `物流商:`,
  key1000183: `拣货单类型：`,
  key1000184: `出库单数量：`,
  key1000185: `创建时间：：`,
  key1000186: `SKU总数：`,
  key1000187: `拣货人员：`,
  key1000188: `SKU种类：`,
  key1000189: `所在库区`,
  key1000190: `所在库位`,
  key1000191: `需拣货数量`,
  key1000192: `中文描述`,
  key1000193: `产品规格`,
  key1000194: `英文描述`,
  key1000195: `批次号`,
  key1000196: `物流商`,
  key1000197: `等`,
  key1000198: `物流渠道`,
  key1000199: `多种物流渠道`,
  key1000200: `选择引用的渠道`,
  key1000201: `请选择物流商：`,
  key1000202: `请选择物流渠道：`,
  key1000203: `物流渠道名称不能为空`,
  key1000204: `关闭`,
  key1000205: `SKU信息`,
  key1000206: `规格：`,
  key1000207: `分配数量`,
  key1000208: `可用数量`,
  key1000209: `可用数量必须是大于等于0的整数`,
  key1000210: `供应商库存详情`,
  key1000211: `中心仓库存详情`,
  key1000212: `处理中...`,
  key1000213: `选择类目`,
  key1000214: `已选类名：`,
  key1000215: `操作日志`,
  key1000216: `属性`,
  key1000217: `属性值`,
  key1000218: `操作类型`,
  key1000219: `操作内容描述`,
  key1000220: `操作时间`,
  key1000221: `操作人`,
  key1000222: `分销商操作`,
  key1000223: `供应商`,
  key1000224: `原值：`,
  key1000225: `修改后：`,
  key1000226: `编辑属性`,
  key1000227: `必填`,
  key1000228: `非必填`,
  key1000229: `推荐填写`,
  key1000230: `规格属性`,
  key1000231: `普通属性`,
  key1000232: `属性值多选`,
  key1000233: `属性值单选`,
  key1000234: `是尺码属性`,
  key1000235: `不是尺码属性`,
  key1000236: `允许自定义属性值`,
  key1000237: `不允许自定义属性值`,
  key1000238: `编辑属性值`,
  key1000239: `菜单设置`,
  key1000240: `快捷搜索侧边栏菜单`,
  key1000241: `仓库：`,
  key1000242: `上传文件：`,
  key1000243: `文件名称：`,
  key1000244: `导入的数据已存在时：`,
  key1000245: `请选择文件`,
  key1000246: `模板下载`,
  key1000247: `忽略`,
  key1000248: `更新`,
  key1000249: `覆盖`,
  key1000250: `追加`,
  key1000251: `导入成功，当前导入的ymsSku不存在：`,
  key1000252: `仓库`,
  key1000253: `导入`,
  key1000254: `上传文件失败，请重新尝试`,
  key1000255: `提示`,
  key1000256: `上传成功`,
  key1000257: `库位所属库区不匹配：`,
  key1000258: `所选仓库内无此库区，请核对后重试`,
  key1000259: `所选仓库内无此库位，请核对后重试`,
  key1000260: `存在重复数据，请确认后重试`,
  key1000261: `导入新增库存货主角色存在异常，请核对后重试`,
  key1000262: `导入新增库存货品供应商商户错误`,
  key1000263: `下载文件模板失败！`,
  key1000264: `请先选择上传文件`,
  key1000265: `导出类型：`,
  key1000266: `任务编号：`,
  key1000267: `导出时间：`,
  key1000268: `选择日期`,
  key1000269: `查询`,
  key1000270: `序号`,
  key1000271: `任务编号`,
  key1000272: `导出类型`,
  key1000273: `导出时间`,
  key1000274: `导出状态`,
  key1000275: `正在导出`,
  key1000276: `系统操作`,
  key1000277: `备注`,
  key1000278: `操作`,
  key1000279: `文件保留7天，超过7天将不能下载！`,
  key1000280: `下载文件`,
  key1000281: `导出中`,
  key1000282: `导出完成`,
  key1000283: `导出失败`,
  key1000284: `日期格式有误`,
  key1000285: `消息中心`,
  key1000286: `查看全部通知`,
  key1000287: `请输入`,
  key1000288: `已选`,
  key1000289: `项`,
  key1000290: `提交`,
  key1000291: `跳过`,
  key1000292: `其他`,
  key1000293: `必填项不能为空！`,
  key1000294: `请输入类目关键词`,
  key1000295: `全部分类`,
  key1000296: `请输入承运商名称`,
  key1000297: `承运商名称：`,
  key1000298: `承运人`,
  key1000299: `承运商名称`,
  key1000300: `承运人电话`,
  key1000301: `是否可用`,
  key1000302: `可用`,
  key1000303: `不可用`,
  key1000304: `选取`,
  key1000305: `承运商的名称不能为空!`,
  key1000306: `稍后提醒`,
  key1000307: `审核成功，系统将在指定时间发送公告`,
  key1000308: `退回成功`,
  key1000309: `取消成功`,
  key1000310: `操作成功！`,
  key1000311: `添加多张图片`,
  key1000312: `已选择上传图片：`,
  key1000313: `图片要求：`,
  key1000314: `建议上传800*800像素以上正方形实拍大图，比如：800*800px，1000*1000px，1500*1500px，最大不超过5MB，，若不符合该尺寸则会导致上传失败。`,
  key1000315: `图片清晰无水印；`,
  key1000316: `包含商品整体款式和商品细节，比如：自拍模特图、海报图、白底图、3D图、细节图等。`,
  key1000317: `4.点击图片可使用系统提供的裁切工具助您更好的展示商品的特点！`,
  key1000318: `点击裁剪`,
  key1000319: `删除图片`,
  key1000320: `上传图片`,
  key1000321: `确定上传`,
  key1000322: `图片加载失败`,
  key1000323: `文件大小超出限制，最大为5M！`,
  key1000324: `上传图片格式错误！系统仅支持JPG、JPEG、PNG、WEBP、BMP格式图片上传`,
  key1000325: `上传图片中...`,
  key1000326: `已阅读并接受`,
  key1000327: `和`,
  key1000328: `开通成功！`,
  key1000329: `需阅读并接受服务条款和隐私政策`,
  key1000330: `缺货数量不能为空`,
  key1000331: `预计到货时间不能为空`,
  key1000332: `请选择预计到货时间`,
  key1000333: `按订单查看`,
  key1000334: `按SKU查看`,
  key1000335: `缺货`,
  key1000336: `不缺货`,
  key1000337: `补货`,
  key1000338: `停售`,
  key1000339: `系统将自动将此SKU进行停售`,
  key1000340: `弹窗内单次最多可处理100行订单数据`,
  key1000341: `稍后处理`,
  key1000342: `确认`,
  key1000343: `超时订单处理`,
  key1000344: `订单号`,
  key1000345: `数量`,
  key1000346: `缺货数量`,
  key1000347: `预计到货时间`,
  key1000348: `标记缺货`,
  key1000349: `以下订单已超时，请更新预计发货时间。`,
  key1000350: `所选订单商品信息如下：`,
  key1000351: `预计到货时间要大于今天的日期！`,
  key1000352: `标记成功！`,
  key1000353: `导入运费模板`,
  key1000354: `计费方式：`,
  key1000355: `计重方式：`,
  key1000356: `选择文件：`,
  key1000357: `导入处理：`,
  key1000358: `（材积重：`,
  key1000359: `批量上传`,
  key1000360: `下载`,
  key1000361: `保存`,
  key1000362: `普通计费`,
  key1000363: `首续重计费`,
  key1000364: `固定收费`,
  key1000365: `实重`,
  key1000366: `抛重`,
  key1000367: `两者比较`,
  key1000368: `覆盖原有数据`,
  key1000369: `国家相同覆盖，不同追加`,
  key1000370: `选择上传文件`,
  key1000371: `选择指定异常`,
  key1000372: `本条件用于筛选异常状况，以下条件符合任何一项，即认为符合本条件。`,
  key1000373: `姓名字符中空格数小于`,
  key1000374: `俄罗斯邮政要求收件人地址为全名，此处可输入2`,
  key1000375: `姓名字符数小于`,
  key1000376: `输入1时，相当于收件人姓名为空`,
  key1000377: `地址字符数小于`,
  key1000378: `地址1+地址2的总字符长度`,
  key1000379: `城市名字字符数小于`,
  key1000380: `输入1时，相当于城市名称为空`,
  key1000381: `省/州名字字符数小于`,
  key1000382: `输入1时，相当于省州名称为空`,
  key1000383: `邮编字符数小于`,
  key1000384: `输入1时，相当于邮编为空`,
  key1000385: `电话号码数字字符个数小于`,
  key1000386: `电话、手机两个号码必须都小于该值才认为该条件成立`,
  key1000387: `审核通过后，自动生成`,
  key1000388: `分类：`,
  key1000389: `商品状态：`,
  key1000390: `中文标题：`,
  key1000391: `供应商SPU：`,
  key1000392: `SKU数量：`,
  key1000393: `商品总数量：`,
  key1000394: `暂存`,
  key1000395: `在售`,
  key1000396: `供应商SKU`,
  key1000397: `规格`,
  key1000398: `商品状态`,
  key1000399: `供货价`,
  key1000400: `上架`,
  key1000401: `打印条码`,
  key1000402: `打印二维码`,
  key1000403: `是否确认删除？`,
  key1000404: `打印提示`,
  key1000405: `SKU中包含中文，暂不支持条形码打印，请使用二维码打印!`,
  key1000406: `驳回原因`,
  key1000407: `大量现货`,
  key1000408: `放心推广`,
  key1000409: `图片裁剪`,
  key1000410: `裁切须知`,
  key1000411: `1.为了保证您的商品可以更好的展示给分销商并带来更多的订单，上传的商品图片长宽尺码必须≥800。`,
  key1000412: `2.若原始上传的图片尺码长宽≤800，则需要重新上传图片。`,
  key1000413: `3.商户可通过裁切，调整已上传图片的展示比例，突出商品的特点。`,
  key1000414: `图片宽度：`,
  key1000415: `图片高度：`,
  key1000416: `预览`,
  key1000417: `+重新上传图片`,
  key1000418: `图片尺寸至少要≥`,
  key1000419: `且`,
  key1000420: `文件大小超出限制，最大为`,
  key1000421: `加载图片中...`,
  key1000422: `裁剪图片.jpeg`,
  key1000423: `退回`,
  key1000424: `退回说明：`,
  key1000425: `请输入退回说明`,
  key1000426: `退回后此公告将流转为待修改状态，请确认是否退回修改？`,
  key1000427: `退回说明不能为空`,
  key1000428: `日志内容`,
  key1000429: `改为`,
  key1000430: `操作日期`,
  key1000431: `运营系统操作`,
  key1000432: `供应商系统操作`,
  key1000433: `SPU价格调整`,
  key1000434: `部分SKU价格调整`,
  key1000435: `SKU价格调整`,
  key1000436: `允许不同库区组的出库单进入同一张拣货单？`,
  key1000437: `允许不同库区的出库单进入同一张拣货单？`,
  key1000438: `允许不同物流商的包裹进入同一张拣货单？`,
  key1000439: `允许相同物流商不同邮寄方式的包裹进入同一张拣货单？`,
  key1000440: `每张常规拣货单最大包裹数`,
  key1000441: `每张备货拣货单最大包裹数`,
  key1000442: `允许`,
  key1000443: `不允许`,
  key1000444: `所属站点：`,
  key1000445: `规格/普通属性：`,
  key1000446: `属性值单选/多选：`,
  key1000447: `填写要求：`,
  key1000448: `是否尺码属性：`,
  key1000449: `是否需填写比例：`,
  key1000450: `普通`,
  key1000451: `多选`,
  key1000452: `单选`,
  key1000453: `否`,
  key1000454: `是`,
  key1000455: `属性名称`,
  key1000456: `可选值`,
  key1000457: `允许自定义`,
  key1000458: `尺码组名：`,
  key1000459: `添加属性值`,
  key1000460: `属性已被使用，不可进行修改。`,
  key1000461: `属性值已被使用，不可进行修改。`,
  key1000462: `新增属性`,
  key1000463: `中文名称`,
  key1000464: `英文-US`,
  key1000465: `英文-UK`,
  key1000466: `英文-AU`,
  key1000467: `英文-EU`,
  key1000468: `法语名称`,
  key1000469: `德语名称`,
  key1000470: `西班牙语名称`,
  key1000471: `荷兰语名称`,
  key1000472: `波兰语名称`,
  key1000473: `葡萄牙语名称`,
  key1000474: `意大利语名称`,
  key1000475: `罗马尼亚语名称`,
  key1000476: `法码`,
  key1000477: `德码`,
  key1000478: `西班牙码`,
  key1000479: `荷兰码`,
  key1000480: `波兰码`,
  key1000481: `葡萄牙码`,
  key1000482: `意大利码`,
  key1000483: `罗马尼亚码`,
  key1000484: `已存在相同名称的属性，请修改属性名称后再提交！`,
  key1000485: `中文、英文-US属性名称不能为空!`,
  key1000486: `同语种的属性值都要必填!`,
  key1000487: `同一个语种下的属性值不能相同！`,
  key1000488: `新增成功`,
  key1000489: `上传失败`,
  key1000490: `是否取消该尺码组别？`,
  key1000491: `是否确认取消该尺码组别？确认后该尺码组别不会显示在属性值里，所填写的数据也会清空。`,
  key1000492: `确定取消`,
  key1000493: `状态`,
  key1000494: `启用`,
  key1000495: `停用`,
  key1000496: `参考图片`,
  key1000497: `简介`,
  key1000498: `请填写国家尺码信息`,
  key1000499: `同语种下属性值不能相同`,
  key1000500: `选择要刊登的店铺`,
  key1000501: `站点：`,
  key1000502: `刊登店铺：`,
  key1000503: `刊登模版：`,
  key1000504: `设置`,
  key1000505: `请选择刊登店铺`,
  key1000506: `请选择刊登模版`,
  key1000507: `刊登草稿失败！`,
  key1000508: `消息通知`,
  key1000509: `没有更多数据了...`,
  key1000510: `稍后再看`,
  key1000511: `您的商品：`,
  key1000512: `，审核未通过，驳回原因：`,
  key1000513: `处理中`,
  key1000514: `已标记已读！`,
  key1000515: `不能为空`,
  key1000516: `供应商币种`,
  key1000517: `添加供应商币种`,
  key1000518: `添加分销商币种`,
  key1000519: `更换币种`,
  key1000520: `分销商币种`,
  key1000521: `币种`,
  key1000522: `库存事务明细`,
  key1000523: `库存变化类型：`,
  key1000524: `操作时间：`,
  key1000525: `选择开始日期`,
  key1000526: `选择结束日期`,
  key1000527: `到`,
  key1000528: `excel导出`,
  key1000529: `可用库存变化`,
  key1000530: `已分配库存变化`,
  key1000531: `事务类型`,
  key1000532: `配货`,
  key1000533: `取消配货`,
  key1000534: `出库`,
  key1000535: `盘点`,
  key1000536: `分拣撤回`,
  key1000537: `加工入库`,
  key1000538: `加工出库`,
  key1000539: `可用库存结存`,
  key1000540: `分配库存变化`,
  key1000541: `分配库存结存`,
  key1000542: `相关单据号`,
  key1000543: `收货`,
  key1000544: `分配`,
  key1000545: `取消分配`,
  key1000546: `库存移动`,
  key1000547: `库存冻结`,
  key1000548: `取消冻结`,
  key1000549: `库存调整`,
  key1000550: `调拨出库`,
  key1000551: `收货上架`,
  key1000552: `调整顺序`,
  key1000553: `取消出库`,
  key1000554: `同步库存`,
  key1000555: `取消收货`,
  key1000556: `回收库存`,
  key1000557: `归库`,
  key1000558: `导入库存`,
  key1000559: `更新库存`,
  key1000560: `获取列表数据失败`,
  key1000561: `选择日期时间`,
  key1000562: `请输入模板名称`,
  key1000563: `已生成的报表文件，系统最多保留7天，超过7天不支持下载。`,
  key1000564: `查看导出列表`,
  key1000565: `导出模板：`,
  key1000566: `管理导出模板`,
  key1000567: `返回`,
  key1000568: `时间范围：`,
  key1000569: `请选择导出维度：`,
  key1000570: `请勾选需导出的字段：`,
  key1000571: `全选`,
  key1000572: `存为新模板`,
  key1000573: `导出`,
  key1000574: `未选择`,
  key1000575: `新增成功！`,
  key1000576: `模板名称不能为空！`,
  key1000577: `是否要删除当前模板？`,
  key1000578: `删除成功`,
  key1000579: `选择邮寄方式`,
  key1000580: `全部收起`,
  key1000581: `全部展开`,
  key1000582: `图片上传规范`,
  key1000583: `轮播图上传规范`,
  key1000584: `推荐按以下顺序上传图片，合理清晰的图片顺序方便查看商品。`,
  key1000585: `单张图片尺寸`,
  key1000586: `尺寸建议800*800px以上（800*800px，1000*1000px，1500*1500px）最大不超过5MB。`,
  key1000587: `比例为正方形，合适的尺寸能看清楚细节。`,
  key1000588: `我知道了`,
  key1000589: `enter可以输入自定义属性`,
  key1000590: `请选择规格`,
  key1000591: `基本信息`,
  key1000592: `类型：`,
  key1000593: `商品类目：`,
  key1000594: `商品货号/SPU：`,
  key1000595: `请输入商品货号/SPU`,
  key1000596: `商品轮播图：`,
  key1000597: `批量编辑组装信息：`,
  key1000598: `图片类型：`,
  key1000599: `商品标题组成:商品特征+商品品类即可，不需要加年份、eBay、 亚马逊等字眼`,
  key1000600: `商品属性`,
  key1000601: `请输入属性值`,
  key1000602: `价格库存`,
  key1000603: `服务与承诺`,
  key1000604: `商品服务：`,
  key1000605: `发货时效：`,
  key1000606: `商品销售建议`,
  key1000607: `查看示例`,
  key1000608: `组装信息`,
  key1000609: `、主属性图片不能为空`,
  key1000610: `供货价格`,
  key1000611: `设置此商品在`,
  key1000612: `累计销售达到一定数量后进行优惠的规则，此优惠将直接给到分销商，但分销商无法直接查看设置的优惠规则，仅在分销商达到某一分层的数量后才能查看下一分层优惠，请放心设置。`,
  key1000613: `提交审批`,
  key1000614: `普通商品`,
  key1000615: `组装商品`,
  key1000616: `原材料`,
  key1000617: `选择商品类目`,
  key1000618: `修改类目`,
  key1000619: `准确选择类目有利于商品曝光，促进转化。`,
  key1000620: `为了方便管理，可以自定义商品编码，比如：货号，发布成功后生效。`,
  key1000621: `首图`,
  key1000622: `上传网络图片`,
  key1000623: `首图优先带背景模特拼图，没有拼图时选择模特正面图，带背景模特图按一定顺序排列。`,
  key1000624: `查看规范`,
  key1000625: `图片要求`,
  key1000626: `建议上传800*800像素以上正方形实拍大图，比如：800*800px，1000*1000px，1500*1500px，最大不超过5MB，若不符合该尺寸则会导致上传失败。`,
  key1000627: `推荐JPG、JPEG格式图片，但平台支持PNG、WEBP、BMP格式图片上传（受限平台限制会将非JPG、JPEG格式都转化为JPEG格式，但会导致图片会有略微失真）`,
  key1000628: `编辑`,
  key1000629: `自拍模特图`,
  key1000630: `网红图`,
  key1000631: `实物摆拍图`,
  key1000632: `自动生成`,
  key1000633: `Listing商品属性信息：`,
  key1000634: `展开填写更多属性`,
  key1000635: `以下属性需填写属性值比例：`,
  key1000636: `请先选择商品类目`,
  key1000637: `请准确填写属性，更多的商品属性将有利于提高下单转化率。`,
  key1000638: `商品规格`,
  key1000639: `仅支持第一组规格设置图片，套装首图要选择套装图，每个规格最多上传5张图片，建议尺寸：800*800px。`,
  key1000640: `多规格排序`,
  key1000641: `规格明细`,
  key1000642: `自定义列`,
  key1000643: `尺码表`,
  key1000644: `请先添加商品规格`,
  key1000645: `固定金额`,
  key1000646: `折扣`,
  key1000647: `添加分层`,
  key1000648: `提交审核后暂不上架`,
  key1000649: `审核通过`,
  key1000650: `驳回`,
  key1000651: `商品分类不能为空`,
  key1000652: `商品货号/SPU不能为空`,
  key1000653: `商品轮播图不能为空`,
  key1000654: `图片类型不能为空`,
  key1000655: `累计购买数量`,
  key1000656: `至`,
  key1000657: `不可小于等于左区间`,
  key1000658: `优惠金额`,
  key1000659: `折扣系数`,
  key1000660: `请输入优惠金额,如：1.00`,
  key1000661: `请输入折扣系数,如：0.1`,
  key1000662: `分层价格参考`,
  key1000663: `原价：`,
  key1000664: `优惠价：`,
  key1000665: `无限`,
  key1000666: `删除分层`,
  key1000667: `商品`,
  key1000668: `属性值已被停用，请更换为相近的属性值`,
  key1000669: `属性值异常，请更换属性值`,
  key1000670: `基础属性的可选值最多只能选取5个!`,
  key1000671: `此属性已存在，请输入其他属性名。`,
  key1000672: `更新成功`,
  key1000673: `选项未填写完整`,
  key1000674: `商品中文名称不能为空！`,
  key1000675: `属性值比例不能为空`,
  key1000676: `属性值比例必须是大于0的正整数`,
  key1000677: `每个属性的属性值比例总和要等于100%`,
  key1000678: `规格属性存在已停用属性值，更换为相近的属性值后重试。`,
  key1000679: `规格属性属性值异常，请更换属性值后重试。`,
  key1000680: `基础属性的属性值异常，请更换属性值后重试。`,
  key1000681: `均码`,
  key1000682: `、输入值后enter`,
  key1000683: ` 自动生成`,
  key1000684: `自动生成sku规则：商品货号/SPU + 规格属性`,
  key1000685: `包装尺寸（长，宽，高cm）`,
  key1000686: `包装长宽高各边最长不超过40cm，泳装除外，春夏款式高度最高不超过3cm，最低不低于1cm`,
  key1000687: `长`,
  key1000688: `宽`,
  key1000689: `高`,
  key1000690: `长宽高`,
  key1000691: `重量`,
  key1000692: `重量(g)`,
  key1000693: `重量填写最大码克重`,
  key1000694: `供货价（CNY）`,
  key1000695: `供货价（`,
  key1000696: `建议供货价小于或等于1688价格`,
  key1000697: `库存`,
  key1000698: `库存为整数`,
  key1000699: `是否组装`,
  key1000700: `(属性值已被停用)`,
  key1000701: `预览图`,
  key1000702: `活动价`,
  key1000703: `活动价（`,
  key1000704: `销售价配置`,
  key1000705: `百分比加价数值不能为空`,
  key1000706: `固定加价数值不能为空`,
  key1000707: `仅支持输入数字及小数点`,
  key1000708: `减价不能大于等于供货价`,
  key1000709: `下浮不能大于等于100%`,
  key1000710: `最多只允许输入四位小数`,
  key1000711: `上浮`,
  key1000712: `下浮`,
  key1000713: `加价`,
  key1000714: `减价`,
  key1000715: `百分比加价`,
  key1000716: `固定加价`,
  key1000717: `请输入浮动比例`,
  key1000718: `请输入数值`,
  key1000719: `销售价`,
  key1000720: `销售价（`,
  key1000721: `商品货号/SPU不能为空!`,
  key1000722: `尺码`,
  key1000723: `单个规格最多上传5张图片`,
  key1000724: `关闭提示`,
  key1000725: `内容未保存提醒`,
  key1000726: `未保存的内容将丢失，请确认是否退出？`,
  key1000727: `确认退出`,
  key1000728: `标题不能为空`,
  key1000729: `计费模型编辑`,
  key1000730: `支持中文、英文、二字码搜索`,
  key1000731: `材积(kg)：`,
  key1000732: `材积重：`,
  key1000733: `（材积(kg) = 长(cm) × 宽(cm) × 高(cm) ÷`,
  key1000734: `验证运费是否正确：重量(`,
  key1000735: `已选中国家：`,
  key1000736: `清空`,
  key1000737: `设置邮编`,
  key1000738: `查看邮编`,
  key1000739: `新增重量区间`,
  key1000740: `验证运费`,
  key1000741: `测试运费：`,
  key1000742: `运费 = (重量 * 单价 + 挂号费 + 操作费) * (1 + 附加费)`,
  key1000743: `重量小于等于首重，运费=首重运费*（1+附加费）`,
  key1000744: `重量大于首重，运费={首重运费 +向上取整(总重量-首重)/续重单位重量) * 续重单价 + 挂号费 + 操作费}*（1+附加费）`,
  key1000745: `运费 = (单价 + 挂号费 + 操作费) * (1 + 附加费)`,
  key1000746: `不在重量区间之内`,
  key1000747: `起始重量`,
  key1000748: `截止重量`,
  key1000749: `挂号费`,
  key1000750: `操作费`,
  key1000751: `附加费（%）`,
  key1000752: `价格`,
  key1000753: `首重（`,
  key1000754: `首重运费(`,
  key1000755: `续重单位重量（`,
  key1000756: `续重单价(`,
  key1000757: `截止重量要大于起始重量`,
  key1000758: `请选择国家`,
  key1000759: `价格不能为空`,
  key1000760: `首重不能为空`,
  key1000761: `首重运费不能为空`,
  key1000762: `续重单位重量不能为空`,
  key1000763: `续重单价不能为空`,
  key1000764: `材积重不能为空`,
  key1000765: `商品搜索：`,
  key1000766: `请输入完整SPU / SKU或商品名称`,
  key1000767: `订阅状态：`,
  key1000768: `条`,
  key1000769: `已选择：`,
  key1000770: `设为主料`,
  key1000771: `SPU,支持多个按行分隔`,
  key1000772: `SKU,支持多个按行分隔`,
  key1000773: `中心仓库存`,
  key1000774: `添加商品`,
  key1000775: `供应商SPU`,
  key1000776: `请输入供应商SPU,支持多个按行分隔`,
  key1000777: `已收藏`,
  key1000778: `未收藏`,
  key1000779: `供应商商户号`,
  key1000780: `商品标题`,
  key1000781: `请输入商品标题`,
  key1000782: `商品信息`,
  key1000783: `供应商名称`,
  key1000784: `供应商库存`,
  key1000785: `商品SPU：`,
  key1000786: `商品SKU：`,
  key1000787: `可用库存`,
  key1000788: `近30天销量`,
  key1000789: `选择`,
  key1000790: `我的库存`,
  key1000791: `在途库存：`,
  key1000792: `可用库存：`,
  key1000793: `单价`,
  key1000794: `长宽高(cm)`,
  key1000795: `试卖`,
  key1000796: `清仓`,
  key1000797: `商品数量不能为空!`,
  key1000798: `请先选择商品！`,
  key1000799: `系统公告`,
  key1000800: `不再提醒`,
  key1000801: `主料`,
  key1000802: `辅料`,
  key1000803: `请输入完整SPU或商品名称`,
  key1000804: `辅料的数量不能为空！`,
  key1000805: `主料的数量不能为空！`,
  key1000806: `请先选择主料数据！`,
  key1000807: `你没有访问该页面的权限`,
  key1000808: `返回上一页`,
  key1000809: `抱歉，您访问的页面不存在！`,
  key1000810: `返回系统首页`,
  key1000811: `返回有权限菜单`,
  key1000812: `请先勾选需要展示的自定义列表字段！`,
  key1000813: `介质材料`,
  key1000814: `标签大小`,
  key1000815: `起止信息`,
  key1000816: `最大支持100mm`,
  key1000817: `字体`,
  key1000818: `每pt约占0.35mm宽`,
  key1000819: `*注:打印内容可拖动改变位置`,
  key1000820: `打印`,
  key1000821: `不打印`,
  key1000822: `条码`,
  key1000823: `自定义内容`,
  key1000824: `产品库位`,
  key1000825: `产品库位预览占位`,
  key1000826: `产品绑定库区`,
  key1000827: `产品绑定库区预览占位`,
  key1000828: `参考编号`,
  key1000829: `参考编号预览占位`,
  key1000830: `通过拖拽调整规格排序`,
  key1000831: `打印箱唛`,
  key1000832: `创建时间：`,
  key1000833: `打印数量：`,
  key1000834: `打印YMS面单`,
  key1000835: `打印数量必须大于0`,
  key1000836: `无可用选项`,
  key1000837: `分类检索`,
  key1000838: `最近使用：`,
  key1000839: `已选类目：`,
  key1000840: `切换类目确认`,
  key1000841: `切换类目后，商品属性及规格等信息需要重新填写。请确认是否切换分类?`,
  key1000842: `确认切换`,
  key1000843: `请选择分类`,
  key1000844: `请输入手机号码`,
  key1000845: `请输入图形验证码`,
  key1000846: `验证码图片`,
  key1000847: `请输入验证码`,
  key1000848: `请输入密码`,
  key1000849: `请再次确认密码`,
  key1000850: `邮箱（选填）`,
  key1000851: `完善账号信息`,
  key1000852: `温馨提示:为了你的账号安全，请绑定手机号码，绑定后可通过手机号码和密码登录。`,
  key1000853: `绑定手机号码`,
  key1000854: `暂不绑定`,
  key1000855: `发送验证码`,
  key1000856: `图形验证码不能为空`,
  key1000857: `验证码不能为空`,
  key1000858: `重新发送`,
  key1000859: `手机验证码已发送！`,
  key1000860: `确认新密码不能为空`,
  key1000861: `密码与确认密码不一致`,
  key1000862: `绑定手机成功`,
  key1000863: `请先勾选已阅读并接受`,
  key1000864: `编辑模板`,
  key1000865: `模板名称：`,
  key1000866: `编辑成功！`,
  key1000867: `支持批量输入，请换行分隔`,
  key1000868: `截取邮编前`,
  key1000869: `位`,
  key1000870: `邮编前`,
  key1000871: `位：`,
  key1000872: `邮编：`,
  key1000873: `邮编不能为空`,
  key1000874: `邮编格式只能是数字或者字母`,
  key1000875: `订单取消提示`,
  key1000876: `本次作业中存在已取消的订单，订单所含出库单详情信息如下。`,
  key1000877: `继续分拣`,
  key1000878: `移除已取消的出库单`,
  key1000879: `出库单号`,
  key1000880: `打印配置`,
  key1000881: `常规订单面单`,
  key1000882: `长：`,
  key1000883: `宽：`,
  key1000884: `速卖通全托管货品标签`,
  key1000885: `速卖通全托管物流面单`,
  key1000886: `常规订单面单长度和宽度不能为空！`,
  key1000887: `速卖通全托管货品标签长度和宽度不能为空！`,
  key1000888: `速卖通全托管物流面单长度和宽度不能为空！`,
  key1000889: `保存成功！`,
  key1000890: `商品驳回通知`,
  key1000891: `以下商品审核被驳回，请修改后重新提交审核:`,
  key1000892: `下次提醒`,
  key1000893: `已复制！`,
  key1000894: `选择要刊登的平台`,
  key1000895: `此类目暂未维护平台映射，如有需要请咨询客服。`,
  key1000896: `暂无可支持刊登的平台！`,
  key1000897: `未绑定店铺`,
  key1000898: `你的账号暂时未绑定店铺，或绑定的店铺已过期。`,
  key1000899: `前往绑定`,
  key1000900: `从地址添加图片`,
  key1000901: `图片地址：`,
  key1000902: `请填写图片地址`,
  key1000903: `链接地址格式不正确`,
  key1000904: `获取上传图片失败！`,
  key1000905: `新品`,
  key1000906: `已授权列表`,
  key1000907: `可输入代号、账户名进行搜索`,
  key1000908: `代号`,
  key1000909: `账户名`,
  key1000910: `有效`,
  key1000911: `无效`,
  key1000912: `更新店铺`,
  key1000913: `添加店铺`,
  key1000914: `关键字`,
  key1000915: `规则`,
  key1000916: `可输入多个，中间用逗号或换行隔开`,
  key1000917: `可输入多个，中间用英文逗号隔开`,
  key1000918: `请输入关键字...`,
  key1000919: `多邮编换行请求半角输入逗号隔开`,
  key1000920: `请选择平台`,
  key1000921: `请输入运输类型名称搜索`,
  key1000922: `新增运输类型`,
  key1000923: `您确定要删除吗？`,
  key1000924: `美元(USD)`,
  key1000925: `或欧元(EUR)`,
  key1000926: `或英镑(GBP)`,
  key1000927: `或澳元(AUD)`,
  key1000928: `或人民币(CNY)`,
  key1000929: `请输入SKU，多个SKU用逗号或换行隔开`,
  key1000930: `请选择或输入所在地`,
  key1000931: `请选择店铺，可输入搜索`,
  key1000932: `请选择物流`,
  key1000933: `十日达订单即X日达订单，如5日达、10日达`,
  key1000934: `指定范围`,
  key1000935: `指定类型`,
  key1000936: `开头`,
  key1000937: `结尾`,
  key1000938: `指定规律`,
  key1000939: `指定SKU`,
  key1000940: `指定仓库`,
  key1000941: `或`,
  key1000942: `指定平台`,
  key1000943: `已选条件`,
  key1000944: `订单包含货品:`,
  key1000945: `体积重计重因子`,
  key1000946: `包裹货品总数量满足以下选中的条件：`,
  key1000947: `订单重量满足以下全部条件：`,
  key1000948: `订单货品长度满足以下全部条件：`,
  key1000949: `订单货品宽度满足以下全部条件：`,
  key1000950: `订单货品高度满足以下全部条件：`,
  key1000951: `订单货品体积满足以下全部条件`,
  key1000952: `订单货品总数量满足以下选中的条件：`,
  key1000953: `订单收货邮编字符长度满足以下选中的条件:`,
  key1000954: `订单包含货品：`,
  key1000955: `并且上述货品的数量总和满足以下选中的所有条件：`,
  key1000956: `与体积重取重对比取重的作为订单重量计算`,
  key1000957: `提示：订单中每件货品在商品管理中记录的最长边为a1、中间值为b1、最短边为c，所有`,
  key1000958: `a1中的最大值为a，所有b1中的最大值为b，订单中所有货品的短边c相加为d，则a、b、`,
  key1000959: `d中的最大值为订单货品长度，中间值为订单货品宽度，最小值为订单货品高度。体积`,
  key1000960: `重(g)=(a*b*d)/计重因子*1000。`,
  key1000961: `提示：订单中每件货品在商品管理中记录的最长边为a1、中间值为b1、最短边为c，`,
  key1000962: `所有a1中的最大值为a，所有b1中的最大值为b，订单中所有货品的短边c相加为d，`,
  key1000963: `则a、b、d中的最大值为订单货品长度，中间值为订单货品宽度，最小值为订单货品高度。`,
  key1000964: `则a、b、d中的最大值为订单货品长度，中间值为订单货品宽度，最小值为订单货品`,
  key1000965: `高度，a*b*d即订单货品体积。`,
  key1000966: `订单包含多个交易（且运输类型完全相同`,
  key1000967: `订单仅包含一个交易`,
  key1000968: `订单包含多个交易（且运输类型不完全相同`,
  key1000969: `指定条件`,
  key1000970: `订单收件人电话符合以下选中的条件：`,
  key1000971: `移动电话可读字符长度≤`,
  key1000972: `固定电话可读字符长度≤`,
  key1000973: `移动电话前缀`,
  key1000974: `指定字符串`,
  key1000975: `指定要在地址(仅街道1+街道2，不含国家/地区省市信息)中查找的字符：`,
  key1000976: `新增`,
  key1000977: `指定长度`,
  key1000978: `订单地址信息字符长度小于：`,
  key1000979: `*此处地址信息由街道1与街道2合并组成，不包含国家/地区、省州、城市信息。`,
  key1000980: `,并且`,
  key1000981: `,或者`,
  key1000982: `包含`,
  key1000983: `指定格式`,
  key1000984: `订单邮编至少符合以下选中的条件中的任意一个条件(输入内容仅限数字、英文字母、中划线和空隔)：`,
  key1000985: `指定异常`,
  key1000986: `且邮编在`,
  key1000987: `邮编段`,
  key1000988: `邮编列表`,
  key1000989: `已选择`,
  key1000990: `注：其他币种转化成美元,所有选项都必须填写`,
  key1000991: `买家支付的运费转换为币种：`,
  key1000992: `之后满足以下全部条件：`,
  key1000993: `指定的商品状态`,
  key1000994: `并且为`,
  key1000995: `指定货品`,
  key1000996: `指定邮寄方式`,
  key1000997: `包裹总金额包含多订单合并到一包裹中的所有订单的金额，不同币种的订单的金额将被统一按汇率转换为如下设置中的币种。`,
  key1000998: `包裹总金额转换为币种：`,
  key1000999: `指定要在买家ID中查找的字符：`,
  key1001000: `规则名称：`,
  key1001001: `可选条件`,
  key1001002: `请指定`,
  key1001003: `未选择规则`,
  key1001004: `规则名称不能为空`,
  key1001005: `更多平台`,
  key1001006: `导入类型：`,
  key1001007: `请输入任务编号`,
  key1001008: `导入时间：`,
  key1001009: `导入中`,
  key1001010: `导入完成`,
  key1001011: `导入失败`,
  key1001012: `导入类型`,
  key1001013: `导入文件`,
  key1001014: `导入时间`,
  key1001015: `导入状态`,
  key1001016: `成功/失败`,
  key1001017: `成功`,
  key1001018: `失败`,
  key1001019: `跟踪信息`,
  key1001020: `运单号：`,
  key1001021: `状态：`,
  key1001022: `待查询`,
  key1001023: `查询中`,
  key1001024: `未查到`,
  key1001025: `运输中`,
  key1001026: `等待领取`,
  key1001027: `已签收`,
  key1001028: `投递失败`,
  key1001029: `运输过久`,
  key1001030: `可能异常`,
  key1001031: `申请成为分销商`,
  key1001032: `寻找商品`,
  key1001033: `收藏商品`,
  key1001034: `商品刊登&下载资料包`,
  key1001035: `下单发货`,
  key1001036: `订单管理`,
  key1001037: `财务管理`,
  key1001038: `去注册`,
  key1001039: `点击商城首页右上角“成为分销商”，使用手机号注册或使用通途账号授权登录。`,
  key1001040: `去浏览`,
  key1001041: `搜索关键字，找到想要分销的商品。`,
  key1001042: `在分类导航，找到想要分销的商品。`,
  key1001043: `在首页和核心页面，找到想要分销的商品。`,
  key1001044: `去收藏`,
  key1001045: `在商品列表页，找到商品后，点击“收藏”按钮收藏商品，以便刊登时使用。`,
  key1001046: `在商品详情页，点击“立即收藏”按钮收藏商品，以便刊登时使用。`,
  key1001047: `4.商品刊登&下载资料包`,
  key1001048: `商品刊登`,
  key1001049: `使用通途Listing，将商品一键刊登到ebay、Aliexpress、Wish、Amazon等海外电商平台。`,
  key1001050: `下载资料包`,
  key1001051: `您也可以在“商品列表”、“商品详情页”或“商品管理”页面，免费下载资料包，再到第三方销售平台商家后台上架商品。`,
  key1001052: `基础设置`,
  key1001053: `启用物流`,
  key1001054: `在通途ERP2.0，启用第三方物流，通途系统已内置了行业内用户使用数较多，口碑较好的一些物流商，您可以根据需要选择并启用。`,
  key1001055: `启用邮寄方式`,
  key1001056: `选择希望启用哪种邮寄方式，启用后会提示“邮寄方式启用成功”。`,
  key1001057: `仓库设置`,
  key1001058: `在第三方仓库中选择“云卖供应链“启用。`,
  key1001059: `绑定物流渠道`,
  key1001060: `选择“云卖供应链“仓库，在右侧点击”绑定物流渠道“，选择物流方式后，点“确认”按钮。`,
  key1001061: `生成通途SKU`,
  key1001062: `在通途ERP2.0的“商品管理-云卖分销商品”模块，点击已收藏的商品，在商品详情中，点击“生成商品资料”。`,
  key1001063: `在通途ERP2.0中，选择“发货管理-托管仓库-等待配货”模块，仓库选择“云卖供应链”，点击“云卖下单”，余额不足时，可联系客服充值。`,
  key1001064: `去查看`,
  key1001065: `在“订单管理-我的订单“模块，进行订单查询，进入“订单详情页”可以查看发货情况。`,
  key1001066: `在“财务管理”模块，可以查询充值记录和查看费用明细。`,
  key1001067: `发货方式：`,
  key1001068: `运输方式：`,
  key1001069: `物流方案：`,
  key1001070: `发货地址：`,
  key1001071: `物流商：`,
  key1001072: `物流单号：`,
  key1001073: `YMS物流`,
  key1001074: `自有物流`,
  key1001075: `快递`,
  key1001076: `YMS上门揽收`,
  key1001077: `请在`,
  key1001078: `账户管理-基本信息`,
  key1001079: `中维护发货地址信息`,
  key1001080: `请务必提前与YMS进行线下确认，是否可进行上门揽收！！`,
  key1001081: `发货时货品需贴YMS商品条码，大包外需粘贴箱唛在显眼处！`,
  key1001082: `结束装箱`,
  key1001083: `标记发货成功，提单号：`,
  key1001084: `请选择物流方案`,
  key1001085: `请选择发货地址`,
  key1001086: `请选择物流商`,
  key1001087: `物流单号不能为空`,
  key1001088: `物流信息`,
  key1001089: `标记发货`,
  key1001090: `修改物流信息`,
  key1001091: `中国`,
  key1001092: `您的浏览器不支持websocket！`,
  key1001093: `分钟前`,
  key1001094: `小时前`,
  key1001095: `天前`,
  key1001096: `周前`,
  key1001097: `个月前`,
  key1001098: `年前`,
  key1001099: `周日`,
  key1001100: `周一`,
  key1001101: `周二`,
  key1001102: `周三`,
  key1001103: `周四`,
  key1001104: `周五`,
  key1001105: `周六`,
  key1001106: `天`,
  key1001107: `小时`,
  key1001108: `分钟`,
  key1001109: `1分钟`,
  key1001110: `刚刚`,
  key1001111: `供应商操作`,
  key1001112: `该功能需使用通途打印控件，请下载安装`,
  key1001113: `点击下载`,
  key1001114: `如果已安装，请开启通途打印控件`,
  key1001115: `当前浏览器 Not support websocket`,
  key1001116: `连接菜鸟打印控件失败,请确认是否安装或启用菜鸟打印控件`,
  key1001117: `加载中...`,
  key1001118: `请求超时`,
  key1001119: `账户已被停用，若需重新启用账户，请联系`,
  key1001120: `运营人员。`,
  key1001121: `您已有`,
  key1001122: `账号，可直接使用此`,
  key1001123: `账号作为供应商进行入驻。`,
  key1001124: `账号开通分销商账户。`,
  key1001125: `没有权限访问该系统`,
  key1001126: `亲爱的通途用户，您没有权限访问该系统!`,
  key1001127: `请联系，系统管理员！`,
  key1001128: `账单待确认提示`,
  key1001129: `存在未确认账单，请及时确认，若账单生成后14天内未确认账单系统将默认账单无误，自动确认账单。`,
  key1001130: `查看账单`,
  key1001131: `稍后确认`,
  key1001132: `账户停用提示`,
  key1001133: `成为供应商确认`,
  key1001134: `前往入驻`,
  key1001135: `成为分销商确认`,
  key1001136: `确认开通`,
  key1001137: `您已有通途账号，可直接使用此账号开通分销商账户。`,
  key1001138: `未揽收`,
  key1001139: `已揽收`,
  key1001140: `已分拣`,
  key1001141: `已出库`,
  key1001142: `提单，`,
  key1001143: `【提单号】`,
  key1001144: `，存在货品多发，已转为备货库存存放于`,
  key1001145: `中心仓。`,
  key1001146: `订单自动取消通知`,
  key1001147: `您的订单`,
  key1001148: `订单号】`,
  key1001149: `，已超时自动取消，请及时关注。`,
  key1001150: `直接出库`,
  key1001151: `待拣货`,
  key1001152: `拣货中`,
  key1001153: `待分拣`,
  key1001154: `分拣中`,
  key1001155: `待包装`,
  key1001156: `包装中`,
  key1001157: `包装完成`,
  key1001158: `已取消`,
  key1001159: `已创建`,
  key1001160: `备货中`,
  key1001161: `已拣货`,
  key1001162: `已换单`,
  key1001163: `已装箱`,
  key1001164: `已发货`,
  key1001165: `等待揽收`,
  key1001166: `已上架`,
  key1001167: `已入库`,
  key1001168: `已作废`,
  key1001169: `揽收分拣异常`,
  key1001170: `已打单`,
  key1001171: `单选可自定值`,
  key1001172: `多选可自定义值`,
  key1001173: `已匹配商品`,
  key1001174: `已匹配物流`,
  key1001175: `已下单`,
  key1001176: `核查中`,
  key1001177: `待发货`,
  key1001178: `部分发货`,
  key1001179: `部分签收`,
  key1001180: `部分取消`,
  key1001181: `销售出库`,
  key1001182: `自有仓备货`,
  key1001183: `三方仓备货`,
  key1001184: `多发补单`,
  key1001185: `编辑中`,
  key1001186: `待审核`,
  key1001187: `审核驳回`,
  key1001188: `货品金额`,
  key1001189: `包裹处理费`,
  key1001190: `退费`,
  key1001191: `物流处理费`,
  key1001192: `补贴金额`,
  key1001193: `国内快递费`,
  key1001194: `清关费`,
  key1001195: `订单取消处理费`,
  key1001196: `正常`,
  key1001197: `完成扣款`,
  key1001198: `预扣款`,
  key1001199: `商城注册`,
  key1001200: `人工添加`,
  key1001201: `待确认`,
  key1001202: `待收款`,
  key1001203: `已收款`,
  key1001204: `待供应商确认`,
  key1001205: `待打款`,
  key1001206: `已打款`,
  key1001207: `/yms-distributor-service/video/YMS功能介绍.mp4`,
  key1001208: `/yms-distributor-service/video/店铺授权.mp4`,
  key1001209: `/yms-distributor-service/video/刊登模板设置.mp4`,
  key1001210: `/yms-distributor-service/video/一键刊登（YMS).mp4`,
  key1001211: `/yms-distributor-service/video/一键刊登规则.mp4`,
  key1001212: `/yms-distributor-service/video/注册云卖供应链.mp4`,
  key1001213: `中文`,
  key1001214: `英文`,
  key1001215: `订单缺货提醒`,
  key1001216: `订单状态标记为缺货时`,
  key1001217: `订单编号`,
  key1001218: `你的订单：<span class="highlight_color">【订单编号】</span>缺货，请及时关注！`,
  key1001219: `备货`,
  key1001220: `订单备货提醒`,
  key1001221: `你有<span class="highlight_color">【X】</span>笔订单，备货已超时，请及时备货！`,
  key1001222: `拣货`,
  key1001223: `订单拣货提醒`,
  key1001224: `你有<span class="highlight_color">【X】</span>笔订单，拣货已超时，请及时拣货！`,
  key1001225: `换单`,
  key1001226: `订单换单提醒`,
  key1001227: `你有<span class="highlight_color">【X】</span>笔订单，换单已超时，请及时换单！`,
  key1001228: `装箱`,
  key1001229: `订单装箱提醒`,
  key1001230: `你有<span class="highlight_color">【X】</span>笔订单，装箱已超时，请及时装箱！`,
  key1001231: `发货`,
  key1001232: `订单发货提醒`,
  key1001233: `你有<span class="highlight_color">【X】</span>笔订单，发货已超时，请及时发货！`,
  key1001234: `订单取消提醒`,
  key1001235: `订单状态标记为取消时`,
  key1001236: `分销商已取消订单：<span class="highlight_color">【订单编号】</span>，请及时关注发货情况`,
  key1001237: `商品停售通知`,
  key1001238: `供应商标记SPU或SKU停售时`,
  key1001239: `商品名称`,
  key1001240: `尊敬的分销商您好：您收藏的：<span class="highlight_color">【商品名称】</span>，商品编码：<span class="highlight_color">【SPU/SKU】</span>已停售，如果您已上架该商品，请及时下架。`,
  key1001241: `商品描述或图片被修改时`,
  key1001242: `商品信息修改通知`,
  key1001243: `商品<span class="highlight_color">【SPU】</span>信息修改通知`,
  key1001244: `尊敬的分销商您好：您收藏的商品 <span class="highlight_color">【SPU】</span>，描述/图片已修改，如果您已上架该商品，请及时更新成最新的产品信息。`,
  key1001245: `商品价格被修改时`,
  key1001246: `商品调价通知`,
  key1001247: `商品<span class="highlight_color">【SPU】</span>调价通知`,
  key1001248: `尊敬的分销商您好：由于成本变动，您收藏的商品 <span class="highlight_color">【SPU】</span>，价格有调整，请及时关注。`,
  key1001249: `关注的供应商上新商品通过审核时`,
  key1001250: `商品上新通知`,
  key1001251: `您关注的供应商有商品上新`,
  key1001252: `您关注的供应商：<span class="highlight_color">【供应商名称】</span>有商品上新。`,
  key1001253: `入库分拣结束时，提单内存在多收货品时`,
  key1001254: `货品多发通知`,
  key1001255: `提单号`,
  key1001256: `通过分销商配置的自动规则调整库存或上架工具状态时`,
  key1001257: `商品状态自动同步通知`,
  key1001258: `您订阅的商品<span class="highlight_color">【SPU】</span>，已停售，已根据规则自动调整相关信息，请及时关注。`,
  key1001259: `通过分销商配置的自动规则调整在线刊登价格时`,
  key1001260: `在线刊登自动调价通知`,
  key1001261: `您订阅的商品<span class="highlight_color">【SPU】</span>，供货价格有调整，在线刊登价格根据规则自动调整，请及时关注。`,
  key1001262: `通过分销商配置的超时时间自动取消订单时`,
  key1001263: `通过平台订单自动下单由于余额不足导致下单失败时`,
  key1001264: `余额不足下单失败通知`,
  key1001265: `余额不足通知`,
  key1001266: `平台`,
  key1001267: `您的余额不足，<span class="highlight_color">【平台】</span>订单<span class="highlight_color">【订单号】</span>自动下单失败，请及时充值。`,
  key1001268: `商品审核被驳回时`,
  key1001269: `商品审核驳回通知`,
  key1001270: `您的商品：<span class="highlight_color">【SPU】</span>，审核未通过，驳回原因：XXXXXXXXX。`,
  key1001271: `待装箱`,
  key1001272: `缺货中`,
  key1001273: `常规单`,
  key1001274: `Temu样品订单`,
  key1001275: `Temu货品订单`,
  key1001276: `速卖通全托管订单`,
  key1001277: `使用已有IOSS`,
  key1001278: `物流代缴`,
  key1001279: `手动录入`,
  key1001280: `等待在途库存`,
  key1001281: `待商家拣货`,
  key1001282: `待商家复核`,
  key1001283: `待商家打包`,
  key1001284: `快递运输中`,
  key1001285: `到达中心仓`,
  key1001286: `中心仓已到货`,
  key1001287: `待物流商上门取件`,
  key1001288: `已交付物流商`,
  key1001289: `英文-US名称`,
  key1001290: `英文-UK名称`,
  key1001291: `英文-AU名称`,
  key1001292: `英文-EU名称`,
  key1001293: `登录后可见`,
  key1001294: `原收件名称`,
  key1001295: `原收件邮箱`,
  key1001296: `原收件电话`,
  key1001297: `原收件用户`,
  key1001298: `原收国家编码`,
  key1001299: `原收件人手机`,
  key1001300: `原收件收货地址1`,
  key1001301: `原收件收货地址2`,
  key1001302: `原收件城市`,
  key1001303: `原收件人州/省`,
  key1001304: `原收件人邮编`,
  key1001305: `原收件人护照编码`,
  key1001306: `订单[`,
  key1001307: `]，修改地址成功:`,
  key1001308: `付款，实付金额：`,
  key1001309: `分销商已付款，实付金额：`,
  key1001310: `调价;货品原价总金额：`,
  key1001311: `;分销商应付金额：`,
  key1001312: `;供应商应收金额：`,
  key1001313: `已到仓`,
  key1001314: `标记为非`,
  key1001315: `订单,清空商品&物流信息`,
  key1001316: `自动匹配订单商品或物流失败`,
  key1001317: `原始单订单取消`,
  key1001318: `未匹配到任何物流`,
  key1001319: `匹配基础物流失败`,
  key1001320: `取消订单成功`,
  key1001321: `自动匹配物流成功：`,
  key1001322: `订单`,
  key1001323: `自动匹配物流成功:`,
  key1001324: `]，匹配物流特殊规则：[`,
  key1001325: `]，匹配适用范围:`,
  key1001326: `匹配方案规则:`,
  key1001327: `手工匹配商品成功：平台SKU：`,
  key1001328: `，匹配`,
  key1001329: `手工匹配商品失败：平台SKU：`,
  key1001330: `，未匹配`,
  key1001331: `自动匹配商品成功：平台SKU：`,
  key1001332: `自动匹配商品失败：平台SKU：`,
  key1001333: `SKU失败，SKU已停售`,
  key1001334: `下单失败，分销商余额不足`,
  key1001335: `变更物流信息，原物流方式：`,
  key1001336: `原运单号：`,
  key1001337: `原物流商单号：`,
  key1001338: `标发货失败，原因：`,
  key1001339: `标发货成功，运单号：`,
  key1001340: `取消订单失败，原因：`,
  key1001341: `下单失败`,
  key1001342: `下单成功`,
  key1001343: `自动匹配物流失败，未找到符合条件的物流方式`,
  key1001344: `(未找到符合基本条件的物流`,
  key1001345: `修改地址信息失败`,
  key1001346: `]，修改物流成功:`,
  key1001347: `原物流`,
  key1001348: `改成`,
  key1001349: `]，修改物流信息失败`,
  key1001350: `添加授权`,
  key1001351: `更新授权`,
  key1001352: `授权失效`,
  key1001353: `手动标记平台订单标发货成功`,
  key1001354: `编辑IOSS;IOSS名称：`,
  key1001355: `;IOSS编号：`,
  key1001356: `添加IOSS;IOSS名称：`,
  key1001357: `添加规则;规则名称：`,
  key1001358: `;使用的IOSS：`,
  key1001359: `;适用平台：`,
  key1001360: `;适用店铺：`,
  key1001361: `编辑规则;规则名称：`,
  key1001362: `取消交易，取消金额：`,
  key1001363: `部分取消，取消金额：`,
  key1001364: `创建备货单`,
  key1001365: `确认接单，接受调价`,
  key1001366: `拒绝调价`,
  key1001367: `未到货部分已退款，退款金额`,
  key1001368: `，币种：`,
  key1001369: `子单全部到仓，已完结`,
  key1001370: `下单`,
  key1001371: `货品缺货,补货中：;预计到货时间：`,
  key1001372: `;缺货内容如下;`,
  key1001373: `货品缺货,已停售：;缺货内容如下;`,
  key1001374: `请补充邮编信息后重试`,
  key1001375: `添加属性`,
  key1001376: `启用属性`,
  key1001377: `停用属性`,
  key1001378: `出库成功`,
  key1001379: `下发物流成功`,
  key1001380: `变更物流信息;原物流方式：`,
  key1001381: `;原运单号：`,
  key1001382: `;原物流商单号：`,
  key1001383: `支付成功`,
  key1001384: `作废订单成功`,
  key1001385: `生成账单`,
  key1001386: `更新仓储账单`,
  key1001387: `生成客户账单`,
  key1001388: `物流分拣完成`,
  key1001389: `新增费率：`,
  key1001390: `编辑费率：`,
  key1001391: `生成一条供应商账单`,
  key1001392: `生成一条分销商账单`,
  key1001393: `换单失败退回到生成拣货单列表`,
  key1001394: `从拣货单【`,
  key1001395: `】移除`,
  key1001396: `重置分拣`,
  key1001397: `标记已拣货`,
  key1001398: `已生成拣货单，拣货单编号:【`,
  key1001399: `换单失败退回到拣货单列表`,
  key1001400: `提单已揽收`,
  key1001401: `提单已创建`,
  key1001402: `提单已入库`,
  key1001403: `提单已上架`,
  key1001404: `提单已分拣`,
  key1001405: `有多余包裹，请联系供应商`,
  key1001406: `审核不通过的原因：`,
  key1001407: `由审核通过`,
  key1001408: `更改为`,
  key1001409: `审核不通过：`,
  key1001410: `由审核不通过`,
  key1001411: `审核不通过`,
  key1001412: `调整补贴`,
  key1001413: `由`,
  key1001414: `调整为`,
  key1001415: `;调整说明：`,
  key1001416: `运营人工添加供应商`,
  key1001417: `停用供应商`,
  key1001418: `启用供应商`,
  key1001419: `编辑供应商`,
  key1001420: `商城入驻注册供应商`,
  key1001421: `商城入驻完善供应商企业信息`,
  key1001422: `审核驳回，驳回原因：`,
  key1001423: `新增配置`,
  key1001424: `编辑配置`,
  key1001425: `已分配库存`,
  key1001426: `匹配智能物流规则：`,
  key1001427: `创建订单`,
  key1001428: `出库单号：`,
  key1001429: `自动匹配仓库成功;发货仓库：`,
  key1001430: `已取消订单`,
  key1001431: `取消订单，取消原因：`,
  key1001432: `物流[`,
  key1001433: `]修改为[`,
  key1001434: `;特殊规则：`,
  key1001435: `,适用范围`,
  key1001436: `,运费/时效优先级`,
  key1001437: `上传包裹前获取包裹状态异常`,
  key1001438: `申请运单号失败`,
  key1001439: `获取运单号失败`,
  key1001440: `获取速卖通货品标签异常`,
  key1001441: `申请运单号成功`,
  key1001442: `(运单号：`,
  key1001443: `(失败原因:：`,
  key1001444: `edis重新上传包裹异常异常`,
  key1001445: `手动修改：更新仓储账单【单据费用】`,
  key1001446: `手动修改：更新仓储账单【其他费用】`,
  key1001447: `手动修改：新增其他费用【`,
  key1001448: `添加备注`,
  key1001449: `手动修改`,
  key1001450: `币种修改`,
  key1001451: `导入账单更新`,
  key1001452: `确认结算`,
  key1001453: `人工标记标记允许取消订单`,
  key1001454: `由于地址修改，当前物流无法送达，原订单已取消，请重新匹配物流后进行下单。`,
  key1001455: `收货中`,
  key1001456: `由于地址修改，原订单已取消。`,
  key1001457: `作废提单，原因：`,
  key1001458: `手动标记付款成功`,
  key1001459: `付款成功`,
  key1001460: `买家修改收货地址，更新订单收货地址`,
  key1001461: `缴费金额：`,
  key1001462: `，时长：`,
  key1001463: `个月`,
  key1001464: `备货单首次分配`,
  key1001465: `商户工商信息变更，驳回原因：`,
  key1001466: `供应商修改了:`,
  key1001467: `商户工商信息变更，审核通过`,
  key1001468: `订单新增标签：`,
  key1001469: `订单删除标签：`,
  key1001470: `存在备货单尚未到货，等待在途库存。`,
  key1001471: `订单新增备注：`,
  key1001472: `美西仓库存不足，等待下次分配`,
  key1001473: `[ 运费最低 ]`,
  key1001474: `[ 时效最快 ]`,
  key1001475: `无法备货至`,
  key1001476: `仓`,
  key1001477: `当前最多可下单`,
  key1001478: `个`,
  key1001479: `存在重复，请修改`,
  key1001480: `尺码值语种：`,
  key1001481: `不可为空`,
  key1001482: `出库单:`,
  key1001483: `不是等待拣货状态，请移除有再完成追加`,
  key1001484: `速卖通预约交货失败`,
  key1001485: `数量超出限制，请核对数量`,
  key1001486: `当前容器`,
  key1001487: `正在被占用`,
  key1001488: `已存在`,
  key1001489: `运营下发物流异常：不同仓库`,
  key1001490: `订单不可同时发货`,
  key1001491: `运营下发物流异常：店铺地址不匹配`,
  key1001492: `已预约，揽收单号：`,
  key1001493: `供应商系统出库异常，提单单号：`,
  key1001494: `交接单号：`,
  key1001495: `预约交货异常`,
  key1001496: `非此订单所需货品，请确认货品所属订单`,
  key1001497: `提单：`,
  key1001498: `不存在，请输入正确提单号`,
  key1001499: `存在已下发的云卖订单`,
  key1001500: `启用状态：`,
  key1001501: `请输入规则名称`,
  key1001502: `新增规则`,
  key1001503: `优先级`,
  key1001504: `置底`,
  key1001505: `置顶`,
  key1001506: `移动`,
  key1001507: `规则名称`,
  key1001508: `启用状态`,
  key1001509: `创建人`,
  key1001510: `创建时间`,
  key1001511: `最后更新时间`,
  key1001512: `查看`,
  key1001513: `修改成功`,
  key1001514: `删除提示`,
  key1001515: `规则删除后，将不可恢复，是否删除？`,
  key1001516: `仓库不能为空`,
  key1001517: `动作类型：`,
  key1001518: `选择仓库：`,
  key1001519: `指定履约方`,
  key1001520: `设定动作`,
  key1001521: `分配发货仓库`,
  key1001522: `所分配仓库缺货时支持继续匹配其他分配仓库规则`,
  key1001523: `禁用`,
  key1001524: `阿里国际`,
  key1001525: `查看规则`,
  key1001526: `编辑规则`,
  key1001527: `复制规则`,
  key1001528: `请先选择规则项！`,
  key1001529: `复制成功`,
  key1001530: `新增容器类型`,
  key1001531: `容器类型：`,
  key1001532: `请输入容器类型，比如：周转箱`,
  key1001533: `重量(kg)：`,
  key1001534: `体积(cm³)：`,
  key1001535: `长(cm)：`,
  key1001536: `宽(cm)：`,
  key1001537: `高(cm)：`,
  key1001538: `备注：`,
  key1001539: `容器类型`,
  key1001540: `重量(kg)`,
  key1001541: `体积(cm³)`,
  key1001542: `长(cm)`,
  key1001543: `宽(cm)`,
  key1001544: `高(cm)`,
  key1001545: `容器类型不能为空`,
  key1001546: `容器重量不能为空`,
  key1001547: `容器体积不能为空`,
  key1001548: `容器长度不能为空`,
  key1001549: `容器宽度不能为空`,
  key1001550: `容器高度不能为空`,
  key1001551: `平台币种：`,
  key1001552: `供应商币种：`,
  key1001553: `添加支持币种`,
  key1001554: `分销商币种：`,
  key1001555: `平台币种`,
  key1001556: `交易币种`,
  key1001557: `汇率配置`,
  key1001558: `固定汇率`,
  key1001559: `实时汇率浮动`,
  key1001560: `浮动比例`,
  key1001561: `请输入固定汇率`,
  key1001562: `汇率展示`,
  key1001563: `实时汇率：`,
  key1001564: `系统汇率：`,
  key1001565: `固定汇率不能为空！`,
  key1001566: `固定汇率必须是大于等于0且最多允许4位小数`,
  key1001567: `实时汇率浮动不能为空！`,
  key1001568: `实时汇率浮动必须是大于等于0且最多允许4位小数`,
  key1001569: `打印容器条码`,
  key1001570: `介质材料：`,
  key1001571: `标签大小：`,
  key1001572: `自定义内容：`,
  key1001573: `自定义内容字体`,
  key1001574: `条码字体`,
  key1001575: `每pt约占0.35mm宽，请确保最长的SKU字符数*左侧pt值*0.35小于介质尺寸`,
  key1001576: `不打印自定义内容`,
  key1001577: `打印自定义内容`,
  key1001578: `增加`,
  key1001579: `统一设置打印数量`,
  key1001580: `注:打印内容可拖动改变位置`,
  key1001581: `请输入自定义内容`,
  key1001582: `新增消息模板`,
  key1001583: `消息名称`,
  key1001584: `消息类型`,
  key1001585: `通知渠道`,
  key1001586: `通知方式`,
  key1001587: `站内信`,
  key1001588: `邮箱->短信`,
  key1001589: `短信->邮箱`,
  key1001590: `删除消息模板后，将不再发送提醒消息，你确定要删除？`,
  key1001591: `消息名称：`,
  key1001592: `请输入消息名称，最多输入20个汉字`,
  key1001593: `消息类型：`,
  key1001594: `触发条件：`,
  key1001595: `通知渠道：`,
  key1001596: `通知方式：`,
  key1001597: `是否弹窗：`,
  key1001598: `提醒时间：`,
  key1001599: `提醒时间段为：6:00-23:00，其他时间段不提醒`,
  key1001600: `请选择时间`,
  key1001601: `状态停留时间超过`,
  key1001602: `及时提醒`,
  key1001603: `固定时间`,
  key1001604: `每`,
  key1001605: `提醒一次`,
  key1001606: `1)可从右侧拖拽添加动态字段。`,
  key1001607: `2)蓝色字体文字为动态参数，请勿修改。`,
  key1001608: `3)自定义内容仅支持站内信与邮件，短信按默认内容发送。`,
  key1001609: `动态参数`,
  key1001610: `标题不能为空！`,
  key1001611: `内容不能为空！`,
  key1001612: `消息类型不能为空`,
  key1001613: `内容：`,
  key1001614: `编辑消息模板`,
  key1001615: `创建成功`,
  key1001616: `编辑成功`,
  key1001617: `提醒时间类型为固定时间且单位为天时，具体的提醒时间段不能为空！`,
  key1001618: `触发条件的频率次数不能为空！`,
  key1001619: `提醒类型为固定时间的频率次数不能为空！`,
  key1001620: `库区名称：`,
  key1001621: `库区代码：`,
  key1001622: `库区状态：`,
  key1001623: `仓库名称：`,
  key1001624: `打印库区条码`,
  key1001625: `库区代码`,
  key1001626: `库区`,
  key1001627: `新建库区`,
  key1001628: `批量打印条码`,
  key1001629: `库区名称`,
  key1001630: `仓库名称不能为空`,
  key1001631: `库区名称不能为空`,
  key1001632: `库区代码不能为空`,
  key1001633: `状态不能为空`,
  key1001634: `打印数量`,
  key1001635: `查看库区`,
  key1001636: `编辑库区`,
  key1001637: `请先勾选需要打印的数据！`,
  key1001638: `所属仓库：`,
  key1001639: `容器编号：`,
  key1001640: `请入输入容器编号`,
  key1001641: `容器颜色：`,
  key1001642: `作业状态：`,
  key1001643: `容器数量：`,
  key1001644: `请输入颜色名称`,
  key1001645: `批量删除`,
  key1001646: `上传音频`,
  key1001647: `容器编号`,
  key1001648: `容器颜色`,
  key1001649: `作业状态`,
  key1001650: `所属仓库`,
  key1001651: `最后一次占用时间`,
  key1001652: `编辑容器颜色`,
  key1001653: `所属仓库不能为空`,
  key1001654: `空闲`,
  key1001655: `货品分拣完成`,
  key1001656: `质检中`,
  key1001657: `待贴单`,
  key1001658: `贴单中`,
  key1001659: `待上架`,
  key1001660: `上架中`,
  key1001661: `待多品分拣`,
  key1001662: `多品分拣中`,
  key1001663: `物流分拣中`,
  key1001664: `新增容器`,
  key1001665: `请选择数据`,
  key1001666: `容器数量不能为空`,
  key1001667: `容器数量必须是大于0的正整数`,
  key1001668: `上传音频失败，请重新尝试`,
  key1001669: `模板选择不能为空`,
  key1001670: `仓库编码：`,
  key1001671: `仓库类型：`,
  key1001672: `模板选择：`,
  key1001673: `常规订单处理费：`,
  key1001674: `常规多品订单续件：`,
  key1001675: `常规订单耗材费：`,
  key1001676: `备货类订单处理费：`,
  key1001677: `仓库租金：`,
  key1001678: ` / 件`,
  key1001679: ` / 单`,
  key1001680: ` / 月`,
  key1001681: `添加`,
  key1001682: `仓库处理费`,
  key1001683: `+添加分层价`,
  key1001684: `每日`,
  key1001685: `时`,
  key1001686: `新增仓库`,
  key1001687: `仓库编码不能为空`,
  key1001688: `仓库类型不能为空`,
  key1001689: `常规订单处理费不能为空`,
  key1001690: `常规多品订单续件不能为空`,
  key1001691: `备货类订单处理费不能为空`,
  key1001692: `仓库发货地址`,
  key1001693: `仓库收货地址`,
  key1001694: `仓库退货地址`,
  key1001695: `姓名`,
  key1001696: `国家/地区`,
  key1001697: `省/州`,
  key1001698: `城市`,
  key1001699: `区县`,
  key1001700: `街道地址`,
  key1001701: `手机号`,
  key1001702: `邮政编码`,
  key1001703: `公司名称`,
  key1001704: `邮箱`,
  key1001705: `名称：`,
  key1001706: `编辑仓库`,
  key1001707: `查看仓库`,
  key1001708: `名称不能为空`,
  key1001709: `左区间不能为空！`,
  key1001710: `右区间不能为空！`,
  key1001711: `分层价不能为空！`,
  key1001712: `左区间不能大于等于右区间`,
  key1001713: `标记为已读`,
  key1001714: `共`,
  key1001715: `条消息，其中未读消息`,
  key1001716: `点击查看`,
  key1001717: `消息标题`,
  key1001718: `时间`,
  key1001719: `请先勾选要标记已读的数据！`,
  key1001720: `目的国`,
  key1001721: `清关费率`,
  key1001722: `更新人`,
  key1001723: `更新时间`,
  key1001724: `日志`,
  key1001725: `启用确认`,
  key1001726: `停用确认`,
  key1001727: `启用后，此目的国将校验IOSS编号，请确认是否启用？`,
  key1001728: `停用后，此目的国将不再校验IOSS编号，请确认是否停用？`,
  key1001729: `确认启用`,
  key1001730: `确认停用`,
  key1001731: `启用成功`,
  key1001732: `停用成功`,
  key1001733: `目的国：`,
  key1001734: `清关费率：`,
  key1001735: `目的国不能为空`,
  key1001736: `清关费率不能为空`,
  key1001737: `最多支持两位小数，需大于等于0`,
  key1001738: `添加税率设置`,
  key1001739: `编辑税率设置`,
  key1001740: `清关费率不能为空！`,
  key1001741: `申报的SKU品种数`,
  key1001742: `每个申报的SKU品种申报的数量`,
  key1001743: `包裹包含多SKU时选择申报的货品信息`,
  key1001744: `申报重量的计算方法`,
  key1001745: `申报价格的计算方法`,
  key1001746: `默认申报设置`,
  key1001747: `全部申报`,
  key1001748: `限制最多申报品种数`,
  key1001749: `实际数量`,
  key1001750: `限制最多申报数量`,
  key1001751: `本项设置仅为确定申报所使用的品名及数量，对报关重量和价格无影响`,
  key1001752: `使用固定的总重量申报`,
  key1001753: `固定包裹总重量为`,
  key1001754: `如果申报时各品种需要详细的申报重量，系统将自动按照申报的品种的真实货品重量加权平均计算`,
  key1001755: `使用货品真实重量申报`,
  key1001756: `设置包裹封顶重量为`,
  key1001757: `申报币种`,
  key1001758: `使用固定价格申报`,
  key1001759: `固定申报价格`,
  key1001760: `本项设置仅录入金额，币种取本申报币种`,
  key1001761: `使用价格比例申报`,
  key1001762: `使用包裹`,
  key1001763: `的`,
  key1001764: `设置最低价格`,
  key1001765: `设置封顶价格`,
  key1001766: `设置为本选项后，系统将根据申报币种自动做汇率转换`,
  key1001767: `总金额`,
  key1001768: `商品总金额`,
  key1001769: `按照货品成本单价由高到低顺序选择`,
  key1001770: `按照货品成本单价X数量由高到低顺序选择`,
  key1001771: `按照货品单品体积由大到小顺序选择`,
  key1001772: `按照货品单品体积X数量由大到小顺序选择`,
  key1001773: `按照货品单品重量由大到小顺序选择`,
  key1001774: `按照货品单品重量X数量由大到小顺序选择`,
  key1001775: `封顶价格要大于最低价格`,
  key1001776: `请输入设置最低价格`,
  key1001777: `请输入设置封顶价格`,
  key1001778: `请输入设置包裹封顶重量`,
  key1001779: `仓库名称`,
  key1001780: `仓库代码`,
  key1001781: `仓库类型`,
  key1001782: `国家`,
  key1001783: `自营`,
  key1001784: `请先选择所属仓库`,
  key1001785: `所属库区名称：`,
  key1001786: `库位代码：`,
  key1001787: `库位状态：`,
  key1001788: `打印库位条码`,
  key1001789: `库位代码`,
  key1001790: `库位`,
  key1001791: `导入库位`,
  key1001792: `新建库位`,
  key1001793: `提示：`,
  key1001794: `1.支持扩展名:`,
  key1001795: `2.当导入数据存在异常时，数据导入失败;`,
  key1001796: `3.导入操作后，请稍后在“导入/导出任务-导入查看”中查看导入结果。`,
  key1001797: `所属库区名称`,
  key1001798: `所属库区代码`,
  key1001799: `库位代码不能为空`,
  key1001800: `查看库位`,
  key1001801: `编辑库位`,
  key1001802: `返回列表`,
  key1001803: `请入输入模板名称`,
  key1001804: `停用地址`,
  key1001805: `新增地址`,
  key1001806: `需要选择新的地址替换被停用的地址，请选择要替换的地址模板`,
  key1001807: `模板名称`,
  key1001808: `手机号码`,
  key1001809: `已启用`,
  key1001810: `已停用`,
  key1001811: `停用成功！`,
  key1001812: `入库分拣设置`,
  key1001813: `拣货容器：`,
  key1001814: `包装容器：`,
  key1001815: `切换仓库`,
  key1001816: `提单揽收`,
  key1001817: `流程状态：启用`,
  key1001818: `提单入库`,
  key1001819: `入库分拣`,
  key1001820: `极速出库模式`,
  key1001821: `分拣时进行贴单`,
  key1001822: `商品上架`,
  key1001823: `多品分拣`,
  key1001824: `单品任务跳过此流程`,
  key1001825: `包装作业`,
  key1001826: `物流分拣`,
  key1001827: `上架容器`,
  key1001828: `贴单+上架容器`,
  key1001829: `出库容器`,
  key1001830: `包装容器`,
  key1001831: `贴单+包装容器`,
  key1001832: `拣货容器`,
  key1001833: `贴单+拣货容器`,
  key1001834: `分拣容器出库单数量限制`,
  key1001835: `暂无仓库数据权限，请配置仓库权限后重试。`,
  key1001836: `存在进行中的入库分拣任务，请完成后再进行修改。`,
  key1001837: `姓名：`,
  key1001838: `国家/地区：`,
  key1001839: `省/州：`,
  key1001840: `城市：`,
  key1001841: `区县：`,
  key1001842: `街道地址：`,
  key1001843: `手机号：`,
  key1001844: `邮政编码：`,
  key1001845: `公司名称：`,
  key1001846: `邮箱：`,
  key1001847: `模板名称不能为空`,
  key1001848: `姓名不能为空`,
  key1001849: `国家/地区不能为空`,
  key1001850: `省州不能为空`,
  key1001851: `城市不能为空`,
  key1001852: `区县不能为空`,
  key1001853: `街道地址不能为空`,
  key1001854: `邮政编码不能为空`,
  key1001855: `编辑地址`,
  key1001856: `查看地址`,
  key1001857: `特殊申报规则`,
  key1001858: `添加时间`,
  key1001859: `订单自动规则--编辑`,
  key1001860: `等级名称：`,
  key1001861: `被展示分销商：`,
  key1001862: `新增供应商等级`,
  key1001863: `所有（包含未登录）`,
  key1001864: `部分`,
  key1001865: `等级名称不能为空`,
  key1001866: `等级名称`,
  key1001867: `被展示分销商`,
  key1001868: `查看供应商等级`,
  key1001869: `编辑供应商等级`,
  key1001870: `是否要删除当前供应商等级？`,
  key1001871: `请选择分销等级！`,
  key1001872: `手机号码：`,
  key1001873: `请填写邮箱`,
  key1001874: `营业执照：`,
  key1001875: `法定代表人身份证照：`,
  key1001876: `经营规模：`,
  key1001877: `联系人姓名：`,
  key1001878: `请填写联系人姓名`,
  key1001879: `联系人手机号码：`,
  key1001880: `请填联系人手机号码`,
  key1001881: `固定电话：`,
  key1001882: `请填写固定电话号码`,
  key1001883: `店铺名称：`,
  key1001884: `请填写店铺名称`,
  key1001885: `店铺Logo：`,
  key1001886: `经营模式：`,
  key1001887: `计划经营类目：`,
  key1001888: `预计上架商品数：`,
  key1001889: `商品发布频率：`,
  key1001890: `请填写具体数量`,
  key1001891: `营业地址：`,
  key1001892: `请填写详细地址`,
  key1001893: `归属仓库为供应商发货的目的仓库，至少选择1个。`,
  key1001894: `供应商等级：`,
  key1001895: `是否允许打印物流面单：`,
  key1001896: `是否允许直接发货：`,
  key1001897: `申请时间：`,
  key1001898: `驳回原因：`,
  key1001899: `账号信息`,
  key1001900: `密码：`,
  key1001901: `重新生成`,
  key1001902: `企业信息`,
  key1001903: `仅支持JPG、JPEG、PNG格式。`,
  key1001904: `人面像`,
  key1001905: `国徽面`,
  key1001906: `店铺信息`,
  key1001907: `尺寸：300*300px，仅支持JPG、JPEG、PNG格式。`,
  key1001908: `款`,
  key1001909: `归属仓库：`,
  key1001910: `首选仓库`,
  key1001911: `设为首选仓库`,
  key1001912: `账期结算：`,
  key1001913: `第`,
  key1001914: `天后出账单`,
  key1001915: `收款信息`,
  key1001916: `审核结果`,
  key1001917: `上传`,
  key1001918: `上传文件格式有误,文件`,
  key1001919: `格式错误,`,
  key1001920: `请选择[jpg,jpeg,png]`,
  key1001921: `添加供应商`,
  key1001922: `请选择经营规模`,
  key1001923: `联系人不能为空`,
  key1001924: `店铺名称不能为空`,
  key1001925: `请选择经营模式`,
  key1001926: `请选择计划经营类目`,
  key1001927: `请选择预计上架商品数`,
  key1001928: `详细地址不能为空`,
  key1001929: `供应商等级不能为空`,
  key1001930: `月结`,
  key1001931: `半月结`,
  key1001932: `周结`,
  key1001933: `10人以下`,
  key1001934: `10~30人`,
  key1001935: `30~50人`,
  key1001936: `50~100人`,
  key1001937: `100人以上`,
  key1001938: `自有工厂`,
  key1001939: `渠道代理`,
  key1001940: `经销批发`,
  key1001941: `女装`,
  key1001942: `男装`,
  key1001943: `男童`,
  key1001944: `女童`,
  key1001945: `婴童`,
  key1001946: `10以下`,
  key1001947: `100以上`,
  key1001948: `待定`,
  key1001949: `每天发布`,
  key1001950: `每周发布`,
  key1001951: `每月发布`,
  key1001952: `收款方式：`,
  key1001953: `开户人姓名：`,
  key1001954: `开户行：`,
  key1001955: `银行卡号：`,
  key1001956: `身份证号：`,
  key1001957: `支付宝账户：`,
  key1001958: `开户人证件号：`,
  key1001959: `公司`,
  key1001960: `入驻审核状态：`,
  key1001961: `工商信息变更审核状态：`,
  key1001962: `供应商详情`,
  key1001963: `编辑供应商信息`,
  key1001964: `审核供应商`,
  key1001965: `审核供应商工商信息变更`,
  key1001966: `银行卡`,
  key1001967: `支付宝`,
  key1001968: `营业执照`,
  key1001969: `法定代表人身份证国徽面`,
  key1001970: `法定代表人身份证人像面`,
  key1001971: `店铺logo`,
  key1001972: `请先上传营业执照`,
  key1001973: `请先上传法定代表人身份证国徽面`,
  key1001974: `请先上传法定代表人身份证人像面`,
  key1001975: `请先上传店铺logo`,
  key1001976: `请先选择商品发布频率类型！`,
  key1001977: `账期结算不能为空！`,
  key1001978: `审核成功！`,
  key1001979: `商品发布数不能为空`,
  key1001980: `商品发布数必须是大于0的正整数`,
  key1001981: `营业地址不能为空`,
  key1001982: `请选择完整的省市区营业地址`,
  key1001983: `提交时间：`,
  key1001984: `搜索字符：`,
  key1001985: `供应商名称/供应商商户号/联系人/手机/邮箱`,
  key1001986: `新增供应商`,
  key1001987: `供应商编号`,
  key1001988: `请输入供应商编号，支持多个逗号或换行分隔`,
  key1001989: `请输入手机号，支持多个逗号或换行分隔`,
  key1001990: `请输入邮箱，支持多个逗号或换行分隔`,
  key1001991: `已认证供应商`,
  key1001992: `黑名单供应商`,
  key1001993: `启用成功！`,
  key1001994: `联系人`,
  key1001995: `手机`,
  key1001996: `电话`,
  key1001997: `SKU总数量`,
  key1001998: `注册时间`,
  key1001999: `停用时间`,
  key1002000: `是否启用该供应商？启用后该供应商的账号可正常登录平台进行操作。`,
  key1002001: `名称`,
  key1002002: `停用原因：`,
  key1002003: `请输入停用原因`,
  key1002004: `停用后供应商将无法登录系统，无法进行发货等任何操作，请谨慎操作！`,
  key1002005: `供应商信息是否允许通过审核？`,
  key1002006: `供应商状态`,
  key1002007: `初审`,
  key1002008: `信息变更`,
  key1002009: `法人代表身份证`,
  key1002010: `经营规模`,
  key1002011: `联系人姓名`,
  key1002012: `联系人手机号码`,
  key1002013: `固定电话`,
  key1002014: `提交审核时间`,
  key1002015: `驳回时间`,
  key1002016: `审核`,
  key1002017: `通过审核`,
  key1002018: `入驻审核`,
  key1002019: `工商信息变更审核`,
  key1002020: `驳回时间：`,
  key1002021: `确认通过`,
  key1002022: `驳回申请`,
  key1002023: `请输入驳回原因`,
  key1002024: `驳回成功！`,
  key1002025: `统计`,
  key1002026: `订单状态为已出库的累计订单总金额。`,
  key1002027: `订单状态为已出库的累计订单总数量。`,
  key1002028: `商城SKU状态为在售的总数量。`,
  key1002029: `统计所有分销商订阅商品总量，同一个商品多个分销商订阅只计算1次。`,
  key1002030: `统计所有分销商已上架到平台的商品总数量，同一个商品不同的Item只计算一次。`,
  key1002031: `统计所有分销商发布刊登的总数量，按Item数量统计。`,
  key1002032: `销售额`,
  key1002033: `订单量`,
  key1002034: `供应商订单排名`,
  key1002035: `分销商订单排名`,
  key1002036: `总销售额(`,
  key1002037: `本月销售额`,
  key1002038: `昨日销售额`,
  key1002039: `今日销售额`,
  key1002040: `订单总数（单）`,
  key1002041: `本月订单数`,
  key1002042: `昨日订单数`,
  key1002043: `今日订单数`,
  key1002044: `在售商品数（SKU）`,
  key1002045: `本月上新`,
  key1002046: `昨日上新`,
  key1002047: `今日上新`,
  key1002048: `商品订阅数（SPU）`,
  key1002049: `本月订阅`,
  key1002050: `昨日订阅`,
  key1002051: `今日订阅`,
  key1002052: `上架商品数（SPU）`,
  key1002053: `本月上架数`,
  key1002054: `昨日上架数`,
  key1002055: `今日上架数`,
  key1002056: `发布刊登数（Item）`,
  key1002057: `本月刊登数`,
  key1002058: `昨日刊登数`,
  key1002059: `今日刊登数`,
  key1002060: `供应商总数`,
  key1002061: `本月新增`,
  key1002062: `昨日新增`,
  key1002063: `今日新增`,
  key1002064: `分销商总数`,
  key1002065: `订单状态`,
  key1002066: `待下单`,
  key1002067: `待换单`,
  key1002068: `本周`,
  key1002069: `本月`,
  key1002070: `本年`,
  key1002071: `月`,
  key1002072: `时间不能跨度超过一年`,
  key1002073: `排名`,
  key1002074: `较上月`,
  key1002075: `较上周`,
  key1002076: `分销商商户号`,
  key1002077: `分销商名称`,
  key1002078: `商品列表`,
  key1002079: `商品贴单`,
  key1002080: `库存余量`,
  key1002081: `提单列表`,
  key1002082: `出库明细`,
  key1002083: `问卷统计`,
  key1002084: `补贴审核`,
  key1002085: `充值记录`,
  key1002086: `供应商总账单`,
  key1002087: `供应商明细账单`,
  key1002088: `供应商订单`,
  key1002089: `物品属性池`,
  key1002090: `客户账单`,
  key1002091: `物流账单`,
  key1002092: `仓储账单`,
  key1002093: `库存明细`,
  key1002094: `物品属性`,
  key1002095: `商品资料`,
  key1002096: `类目映射`,
  key1002097: `通知对象：`,
  key1002098: `创建人：`,
  key1002099: `发送时间：`,
  key1002100: `创建公告`,
  key1002101: `草稿`,
  key1002102: `待修改`,
  key1002103: `待发送`,
  key1002104: `已发送`,
  key1002105: `公告名称`,
  key1002106: `发送状态`,
  key1002107: `通知对象`,
  key1002108: `发送时间`,
  key1002109: `（预定）`,
  key1002110: `审核人`,
  key1002111: `审核通过提示`,
  key1002112: `审核通过后系统将按指定时间发送公告，请确认是否审核通过？`,
  key1002113: `删除公告提示`,
  key1002114: `公告删除后不可恢复，请确认是否删除？`,
  key1002115: `删除成功！`,
  key1002116: `取消发送`,
  key1002117: `取消发送提示`,
  key1002118: `取消后此公告将流转为草稿状态，请确认是否取消发送？`,
  key1002119: `新建问卷`,
  key1002120: `问卷名称`,
  key1002121: `问卷状态`,
  key1002122: `已暂停`,
  key1002123: `答题中`,
  key1002124: `浏览量`,
  key1002125: `回收率`,
  key1002126: `最后修改时间`,
  key1002127: `停用后不可答题`,
  key1002128: `停用后，问卷答题将暂停，你确定停用？`,
  key1002129: `问卷标题`,
  key1002130: `问卷说明`,
  key1002131: `请输入题目标题`,
  key1002132: `请输入选项`,
  key1002133: `投放渠道`,
  key1002134: `显示方式`,
  key1002135: `触发事件`,
  key1002136: `选择题型`,
  key1002137: `选填`,
  key1002138: `添加选项`,
  key1002139: `添加其他`,
  key1002140: `问卷设置`,
  key1002141: `文本`,
  key1002142: `分销商端`,
  key1002143: `弹窗`,
  key1002144: `不显示【跳过】按钮`,
  key1002145: `显示【跳过】按钮`,
  key1002146: `首次登录`,
  key1002147: `选项`,
  key1002148: `其他____`,
  key1002149: `是否保存问卷内容`,
  key1002150: `你有未保存的问卷，是否需要保存？`,
  key1002151: `不保存`,
  key1002152: `新建成功！`,
  key1002153: `题目标题不能为空！`,
  key1002154: `题目标题不能重复！`,
  key1002155: `新增指引`,
  key1002156: `PDF文件`,
  key1002157: `在线内容`,
  key1002158: `指引标题`,
  key1002159: `所属模块`,
  key1002160: `指引类型`,
  key1002161: `排序`,
  key1002162: `停用提醒`,
  key1002163: `停用后，用户将无法查看使用教程，`,
  key1002164: `你确定要停用吗？`,
  key1002165: `删除提醒`,
  key1002166: `你即将永久删除，删除后将无法恢复，`,
  key1002167: `你确定要删除吗？`,
  key1002168: `目录名称`,
  key1002169: `内容标题：`,
  key1002170: `请输入内容标题`,
  key1002171: `正文内容：`,
  key1002172: `排序：`,
  key1002173: `展示状态：`,
  key1002174: `复制文章标题`,
  key1002175: `最多可输入28个中文字符`,
  key1002176: `数字越大，越靠后`,
  key1002177: `开`,
  key1002178: `关`,
  key1002179: `添加内容`,
  key1002180: `是否确认删除【`,
  key1002181: `】分类？`,
  key1002182: `标题`,
  key1002183: `发布日期`,
  key1002184: `最后更新日期`,
  key1002185: `展示状态`,
  key1002186: `首页推荐`,
  key1002187: `新增目录`,
  key1002188: `文章标题不能为空`,
  key1002189: `目录`,
  key1002190: `首页最多推荐5条帮助文档，请先关闭其他推荐，再开启！`,
  key1002191: `是否确认删除当前文章？`,
  key1002192: `编辑目录`,
  key1002193: `目录分类最多只能有三个层级关系，且只允许同级拖动排序！`,
  key1002194: `内容`,
  key1002195: `在新的标签页打开图片`,
  key1002196: `1:1显示图片`,
  key1002197: `问题汇总`,
  key1002198: `鼠标滚轮缩放图片`,
  key1002199: `指引标题：`,
  key1002200: `请输入指引标题`,
  key1002201: `所属模块：`,
  key1002202: `显示位置：`,
  key1002203: `指引类型：`,
  key1002204: `提示:`,
  key1002205: `1.仅支持扩展名为PDF的文件。`,
  key1002206: `2.文件大小不超过10Mb。`,
  key1002207: `指引标题不能为空`,
  key1002208: `所属模块不能为空`,
  key1002209: `显示位置不能为空`,
  key1002210: `面包屑右侧`,
  key1002211: `编辑指引`,
  key1002212: `上传文件成功`,
  key1002213: `请先上传PDF文件！`,
  key1002214: `正文内容不能为空！`,
  key1002215: `公告名称：`,
  key1002216: `若审核通过时已超出发送时间，公告将在审核通过后立即发送。`,
  key1002217: `存为草稿`,
  key1002218: `提交审核`,
  key1002219: `请输入公告名称`,
  key1002220: `请选择通知对象`,
  key1002221: `编辑公告`,
  key1002222: `提交审核成功`,
  key1002223: `请输入正文内容`,
  key1002224: `请选择发送时间`,
  key1002225: `导出数据`,
  key1002226: `按选中导出`,
  key1002227: `按查询结果导出`,
  key1002228: `请先勾选需要导出的数据！`,
  key1002229: `当前筛选结果暂无数据，无法导出！`,
  key1002230: `商户号`,
  key1002231: `开始答题时间`,
  key1002232: `结束答题时间`,
  key1002233: `客户名称：`,
  key1002234: `联系人：`,
  key1002235: `手机：`,
  key1002236: `请输入手机号`,
  key1002237: `客户等级：`,
  key1002238: `仓库权限：`,
  key1002239: `客户类型：`,
  key1002240: `分销客户`,
  key1002241: `阿里国际分销`,
  key1002242: `客户等级不能为空`,
  key1002243: `链接详情`,
  key1002244: `链接名称：`,
  key1002245: `来源渠道：`,
  key1002246: `推广人：`,
  key1002247: `推广方式：`,
  key1002248: `推广内容：`,
  key1002249: `推广组：`,
  key1002250: `备注信息`,
  key1002251: `链接基本信息`,
  key1002252: `链接数据`,
  key1002253: `点击总人数`,
  key1002254: `注册总人数`,
  key1002255: `注册转换率`,
  key1002256: `请填写链接名称`,
  key1002257: `分时数据`,
  key1002258: `点击人数`,
  key1002259: `注册人数`,
  key1002260: `创建链接`,
  key1002261: `请填写链接地址`,
  key1002262: `创建成功！`,
  key1002263: `客户详情`,
  key1002264: `客户信息`,
  key1002265: `编号：`,
  key1002266: `客户来源：`,
  key1002267: `客户币种：`,
  key1002268: `关联商户：`,
  key1002269: `客户负责人：`,
  key1002270: `请填写备注`,
  key1002271: `余额：`,
  key1002272: `预扣金额：`,
  key1002273: `总额度：`,
  key1002274: `已使用额度：`,
  key1002275: `请输入姓名`,
  key1002276: `职务：`,
  key1002277: `请输入职务`,
  key1002278: `请输入邮箱`,
  key1002279: `请输入固定电话`,
  key1002280: `微信号：`,
  key1002281: `请输入微信号`,
  key1002282: `QQ号：`,
  key1002283: `请输入微QQ号`,
  key1002284: `客户旅程`,
  key1002285: `主联系人`,
  key1002286: `下次联系时间：`,
  key1002287: `操作人：`,
  key1002288: `客户基本信息`,
  key1002289: `客户财务信息`,
  key1002290: `客户仓库权限`,
  key1002291: `客户API权限`,
  key1002292: `添加联系人`,
  key1002293: `设为主要联系人`,
  key1002294: `暂无旅程数据`,
  key1002295: `联系记录`,
  key1002296: `开通产品记录`,
  key1002297: `日志信息`,
  key1002298: `不是有效的邮箱或者邮箱格式不正确！`,
  key1002299: `信息至少要填写其中一个！`,
  key1002300: `创建分组`,
  key1002301: `分组名称`,
  key1002302: ` 分组客户数量`,
  key1002303: `分组备注`,
  key1002304: `删除分组提示`,
  key1002305: `正在操作删除分组，请确认是否删除？`,
  key1002306: `删除分组`,
  key1002307: `注册时间：`,
  key1002308: `注册来源：`,
  key1002309: `请选择注册来源`,
  key1002310: `客户标签：`,
  key1002311: `请选择客户标签`,
  key1002312: `请选择客户等级`,
  key1002313: `客户名称/客户编号/联系人/手机/邮箱/备注`,
  key1002314: `新增客户`,
  key1002315: `移动分组`,
  key1002316: `批量配置标签`,
  key1002317: `批量审核`,
  key1002318: `客户编号`,
  key1002319: `请输入客户编号，支持多个逗号或换行分隔`,
  key1002320: `是否确认启用？`,
  key1002321: `是否确认停用？`,
  key1002322: `仅待审核客户可进行审核，请重新选择!`,
  key1002323: `请先勾选要审核的数据`,
  key1002324: `未分组`,
  key1002325: `客户名称`,
  key1002326: `客户分组`,
  key1002327: `客户标签`,
  key1002328: `客户等级`,
  key1002329: `客户币种`,
  key1002330: `余额`,
  key1002331: `预扣金额`,
  key1002332: `总额度`,
  key1002333: `已使用额度`,
  key1002334: `注册来源`,
  key1002335: `客户备注`,
  key1002336: `请先选择客户`,
  key1002337: `标签名称：`,
  key1002338: `请输入标签名称`,
  key1002339: `标签备注：`,
  key1002340: `请填写标签名称`,
  key1002341: `创建标签`,
  key1002342: `编辑标签`,
  key1002343: `指定分组：`,
  key1002344: `请选择分组`,
  key1002345: `本次操作中存在删除分组行为，请为分组下的客户重新进行分组`,
  key1002346: `非通途客户`,
  key1002347: `通途客户`,
  key1002348: `通途商户号不能为空`,
  key1002349: `查看客户`,
  key1002350: `标签名称`,
  key1002351: `标签客户数量`,
  key1002352: `标签备注`,
  key1002353: `删除标签提示`,
  key1002354: `删除标签后，此标签将同步从客户上去除，请确认是否删除？`,
  key1002355: `删除标签`,
  key1002356: `标签详情`,
  key1002357: `标签基本信息`,
  key1002358: `标签类型：`,
  key1002359: `标签数据`,
  key1002360: `统计时间：`,
  key1002361: `搜索链接名称`,
  key1002362: `请选择标签类型`,
  key1002363: `搜索标签名称`,
  key1002364: `链接名称`,
  key1002365: `链接类型`,
  key1002366: `查看二维码`,
  key1002367: `复制链接`,
  key1002368: `标签：`,
  key1002369: `请选择标签`,
  key1002370: `添加标签`,
  key1002371: `分组名称：`,
  key1002372: `请输入分组名称`,
  key1002373: `分组备注：`,
  key1002374: `请填写分组名称`,
  key1002375: `最多输入20个字符`,
  key1002376: `编辑分组`,
  key1002377: `新增客户等级`,
  key1002378: `等级`,
  key1002379: `查看客户等级`,
  key1002380: `编辑客户等级`,
  key1002381: `是否要删除当前客户等级？`,
  key1002382: `注册时默认等级`,
  key1002383: `默认`,
  key1002384: `设为默认`,
  key1002385: `更换注册默认等级`,
  key1002386: `当前操作将会影响商城客户注册时的默认等级，是否确认更换默认等级？`,
  key1002387: `输入出库单号查找`,
  key1002388: `删除确认`,
  key1002389: `拣货单详情`,
  key1002390: `拣货单号：`,
  key1002391: `拣货单状态：`,
  key1002392: `是否打印：`,
  key1002393: `打印拣货单`,
  key1002394: `创建人员：`,
  key1002395: `是否删除最后一条？如果删除该拣货单也会删除！`,
  key1002396: `按出库单查看`,
  key1002397: `按商品查看`,
  key1002398: `出库单状态`,
  key1002399: `物流下发状态`,
  key1002400: `未下发`,
  key1002401: `已下发`,
  key1002402: `从拣货单移除`,
  key1002403: `所在仓库`,
  key1002404: `产品图片`,
  key1002405: `已拣货数量`,
  key1002406: `体积`,
  key1002407: `未拣货`,
  key1002408: `成品`,
  key1002409: `原料`,
  key1002410: `拣货单：`,
  key1002411: `物料类型`,
  key1002412: `原料SPU`,
  key1002413: `原料SKU`,
  key1002414: `制单人`,
  key1002415: `（签字/日期）`,
  key1002416: `拣货人`,
  key1002417: `（签字日期）`,
  key1002418: `取消打印`,
  key1002419: `标记打印成功`,
  key1002420: `订单号：`,
  key1002421: `多个出库单请用逗号分隔`,
  key1002422: `品类：`,
  key1002423: `排序方式：`,
  key1002424: `生成拣货单`,
  key1002425: `按选中生成拣货单`,
  key1002426: `生成拣货单（所有结果集）`,
  key1002427: `按创建时间`,
  key1002428: `按物流方式`,
  key1002429: `Temu 样`,
  key1002430: `Temu 货`,
  key1002431: `出库单类型`,
  key1002432: `分销商订单号`,
  key1002433: `SKU数量`,
  key1002434: `物品数量`,
  key1002435: `查看详情`,
  key1002436: `请先勾选需要生成拣货单的数据！`,
  key1002437: `当前筛选结果暂无数据，无法生成拣货单！`,
  key1002438: `未指定物流商`,
  key1002439: `未指定邮寄方式`,
  key1002440: `拣货单编号：`,
  key1002441: `打印状态：`,
  key1002442: `批量操作`,
  key1002443: `标记为已拣货`,
  key1002444: `未打印`,
  key1002445: `已打印`,
  key1002446: `按添加时间`,
  key1002447: `按拣货单状态`,
  key1002448: `查询已包装的拣货单，创建时间不能为空！`,
  key1002449: `标记成功`,
  key1002450: `请选择操作数据`,
  key1002451: `拣货单编号`,
  key1002452: `拣货单类型`,
  key1002453: `拣货状态`,
  key1002454: `打印状态`,
  key1002455: `SKU数`,
  key1002456: `货品数`,
  key1002457: `拣货完成时间`,
  key1002458: `拣货单来源`,
  key1002459: `出库单`,
  key1002460: `分拣容器`,
  key1002461: `打印组装标签`,
  key1002462: `获取打印组装标签链接地址失败!`,
  key1002463: `属性名称：`,
  key1002464: `请输入属性名称，支持多语种搜索`,
  key1002465: `是否自定义：`,
  key1002466: `单选/多选：`,
  key1002467: `物品属性导入`,
  key1002468: `导出属性`,
  key1002469: `2.当导入数据存在异常时，数据导入失败。`,
  key1002470: `编号`,
  key1002471: `属性中文名称`,
  key1002472: `规格/普通属性`,
  key1002473: `是否尺码属性`,
  key1002474: `填写要求`,
  key1002475: `是否自定义`,
  key1002476: `单选/多选`,
  key1002477: `规格/普通属性(规格、普通)`,
  key1002478: `是否自定义(是、否)`,
  key1002479: `属性值单选/多选(多选、单选)`,
  key1002480: `是否尺码属性(是、否)`,
  key1002481: `填写要求(必填、非必填、推荐填写)`,
  key1002482: `属性信息`,
  key1002483: `中文-简体`,
  key1002484: `法语`,
  key1002485: `德语`,
  key1002486: `西班牙语`,
  key1002487: `荷兰语`,
  key1002488: `波兰语`,
  key1002489: `葡萄牙语`,
  key1002490: `意大利语`,
  key1002491: `属性状态`,
  key1002492: `属性值信息`,
  key1002493: `属性值状态`,
  key1002494: `属性删除提示`,
  key1002495: `本次修改中存在删除属性行为，删除后现有商品信息中该属性字段将一并删除，请确认是否保存此次修改？`,
  key1002496: `停用属性后，供应商发布新商品和修改已发布的商品时，此属性将不可使用，你确定要停用?`,
  key1002497: `立即停用`,
  key1002498: `新增模板`,
  key1002499: `部位`,
  key1002500: `单位`,
  key1002501: `是否要删除当前尺码模板？`,
  key1002502: `请输入中文标题,支持多个按行分隔`,
  key1002503: `供应商：`,
  key1002504: `商品类型：`,
  key1002505: `导出商品列表`,
  key1002506: `取消选择`,
  key1002507: `面单`,
  key1002508: `供应商信息`,
  key1002509: `分类`,
  key1002510: `最近停售时间`,
  key1002511: `首次上架时间`,
  key1002512: `首次提交审核时间`,
  key1002513: `查看SKU`,
  key1002514: `查看中心仓库存`,
  key1002515: `查看供应商库存`,
  key1002516: `1688找同款`,
  key1002517: `请输入供应商SKU,支持多个按行分隔`,
  key1002518: `商品类目`,
  key1002519: `销售状态`,
  key1002520: `最后上架时间`,
  key1002521: `中心仓分配库存`,
  key1002522: `供应商分配库存`,
  key1002523: `商品类型`,
  key1002524: `以SPU维度导出`,
  key1002525: `(报表中每个SPU一行)`,
  key1002526: `以SKU维度导出`,
  key1002527: `(报表中每个SKU一行)`,
  key1002528: `最小值`,
  key1002529: `最大值`,
  key1002530: `供应商仓库存`,
  key1002531: `商品上架时间`,
  key1002532: `库存最小值不可大于最大值，请修改条件后再查询。`,
  key1002533: `1. 选择平台`,
  key1002534: `2. 选择站点`,
  key1002535: `4. 选择Product Type`,
  key1002536: `4. 选择Family Type`,
  key1002537: `5. 商品规格映射`,
  key1002538: `6. 商品属性映射`,
  key1002539: `自定义值`,
  key1002540: `平台属性变更，原值：`,
  key1002541: `选择站点`,
  key1002542: `类目不能为空`,
  key1002543: `商品规格映射`,
  key1002544: `属性:`,
  key1002545: `已映射:`,
  key1002546: `待映射:`,
  key1002547: `属性值:`,
  key1002548: `属性值已映射:`,
  key1002549: `属性值待映射:`,
  key1002550: `自动匹配`,
  key1002551: `正在匹配中，请稍后...`,
  key1002552: `自动匹配完成，已匹配属性：`,
  key1002553: `已匹配属性值：`,
  key1002554: `查看更多可选值`,
  key1002555: `无数据`,
  key1002556: `商品属性映射`,
  key1002557: `属性值类型`,
  key1002558: `添加平台`,
  key1002559: `平台不能为空`,
  key1002560: `站点不能为空`,
  key1002561: `自定义`,
  key1002562: `平台属性`,
  key1002563: `平台属性值类型`,
  key1002564: `编辑平台`,
  key1002565: `查看平台`,
  key1002566: `（已映射）`,
  key1002567: `Product Type不能为空`,
  key1002568: `FamiltType不能为空`,
  key1002569: `开始匹配`,
  key1002570: `正在匹配中...`,
  key1002571: `匹配数据获取完成`,
  key1002572: `数据匹配中`,
  key1002573: `接口请求错误，可能导致数据匹配不全`,
  key1002574: `名称数据匹配`,
  key1002575: `平台映射表`,
  key1002576: `电商平台：`,
  key1002577: `站点名称：`,
  key1002578: `分类名称：`,
  key1002579: `自定义属性值`,
  key1002580: `正在搜索`,
  key1002581: `输入文字进行搜索`,
  key1002582: `平台标签属性`,
  key1002583: `映射属性`,
  key1002584: `listing关键词的生成规则`,
  key1002585: `随机`,
  key1002586: `不选`,
  key1002587: `切换Product Type会清空列表数据`,
  key1002588: `请先选择Product Type`,
  key1002589: `平台属性不能重复`,
  key1002590: `未查询到平台类目`,
  key1002591: `价格区间(CNY)：`,
  key1002592: `综合评分：`,
  key1002593: `24小时揽收率：`,
  key1002594: `48小时揽收率：`,
  key1002595: `搜索关键词：`,
  key1002596: ` 起`,
  key1002597: `起批`,
  key1002598: `分`,
  key1002599: `近30天成交：`,
  key1002600: `不限`,
  key1002601: `5星`,
  key1002602: `4.5星-5.0星`,
  key1002603: `4星-4.5星`,
  key1002604: `4星以下`,
  key1002605: `认证工厂`,
  key1002606: `当日发货`,
  key1002607: `24小时发货`,
  key1002608: `48小时发货`,
  key1002609: `7天无理由`,
  key1002610: `一件代发`,
  key1002611: `支持包邮`,
  key1002612: `按价格`,
  key1002613: `按月销量`,
  key1002614: `价格开始区间不能大于价格结束区间`,
  key1002615: `中文名称不能为空！`,
  key1002616: `尺码表模板名称`,
  key1002617: `新增部位`,
  key1002618: `新增单位`,
  key1002619: `同一列部位的中文名称不能重复！`,
  key1002620: `部位1`,
  key1002621: `英文名称`,
  key1002622: ` 荷兰语名称`,
  key1002623: `同一列的部位多语种名称必须填写完整或全为空！`,
  key1002624: `单位名称不能为空！`,
  key1002625: `新增模板成功！`,
  key1002626: `更新模板成功！`,
  key1002627: `上移`,
  key1002628: `下移`,
  key1002629: `不能选择重复的单位值！`,
  key1002630: `属性池管理`,
  key1002631: `请输入属性中文名称`,
  key1002632: `商品规格中只能有一个是尺码属性！`,
  key1002633: `请先选择属性池数据！`,
  key1002634: `输入关键词搜索`,
  key1002635: `分类ID：`,
  key1002636: `父级类目已配置不展示，此类目不展示于商城。`,
  key1002637: `报关编码：`,
  key1002638: `中文报关名称：`,
  key1002639: `英文报关名称：`,
  key1002640: `尺码表模板：`,
  key1002641: `新增子分类`,
  key1002642: `导入映射`,
  key1002643: `分类信息`,
  key1002644: `商城展示配置：`,
  key1002645: `展示`,
  key1002646: `不展示`,
  key1002647: `平台信息`,
  key1002648: `报关信息`,
  key1002649: `从属性池选择`,
  key1002650: `您确认`,
  key1002651: `这个平台吗？`,
  key1002652: `商品总数：`,
  key1002653: `您确认删除这条内容吗？`,
  key1002654: `报关编码不能为空`,
  key1002655: `中文报关名称不能为空`,
  key1002656: `英文报关名称不能为空`,
  key1002657: `尺码表模板不能为空`,
  key1002658: `平台名称`,
  key1002659: `站点`,
  key1002660: `商品分类`,
  key1002661: `修改`,
  key1002662: `是否将其他物品属性listing关键词的生成规则更新为当前生成规则`,
  key1002663: `同步`,
  key1002664: `主属性设置`,
  key1002665: `主属性`,
  key1002666: `设为主属性`,
  key1002667: `只能同级拖动`,
  key1002668: `请先设置商品规格的主属性！`,
  key1002669: `是否要删除当前分类？`,
  key1002670: `服装`,
  key1002671: `不能包含->特殊字符`,
  key1002672: `文件格式有误, 仅支持上传xls、xlsx格式文件`,
  key1002673: `请输入打印数量`,
  key1002674: `打印面单`,
  key1002675: `供应商名称/供应商商户号`,
  key1002676: `批量驳回`,
  key1002677: `供应商货号/SPU`,
  key1002678: `中文品名`,
  key1002679: `供应商商户号 / 名称`,
  key1002680: `审核状态`,
  key1002681: `请勾选需要批量审核的数据!`,
  key1002682: `请先勾选需要批量驳回的数据！`,
  key1002683: `账单打款确认`,
  key1002684: `打款密码：`,
  key1002685: `请输入打款密码`,
  key1002686: `是否已核对账单无误，确认打款后款项将直接通过支付宝转账至供应商账户中，请谨慎操作。`,
  key1002687: `确认打款`,
  key1002688: `账单时间：`,
  key1002689: `客户商户号：`,
  key1002690: `请输入客户商户号`,
  key1002691: `导出客户账单`,
  key1002692: `年`,
  key1002693: `订单金额`,
  key1002694: `订单币种`,
  key1002695: `账单号`,
  key1002696: `账单周期`,
  key1002697: `客户商户号`,
  key1002698: `月累计充值金额`,
  key1002699: `支付宝充值金额`,
  key1002700: `手工充值金额`,
  key1002701: `累计消费金额`,
  key1002702: `账单生成时间`,
  key1002703: `账单信息`,
  key1002704: `所属商户`,
  key1002705: `所属账单`,
  key1002706: `充值流水号`,
  key1002707: `第三方交易号`,
  key1002708: `充值金额`,
  key1002709: `付款方式`,
  key1002710: `充值成功时间`,
  key1002711: `消费记录`,
  key1002712: `第三方单号`,
  key1002713: `货品总数`,
  key1002714: `物流重量(g)`,
  key1002715: `商品金额`,
  key1002716: `物流费`,
  key1002717: `订单完成时间`,
  key1002718: `账单维度`,
  key1002719: `明细维度`,
  key1002720: `物流费用为供应商应付项，货品金额及补贴金额为应收项，计算时请注意`,
  key1002721: `费用明细`,
  key1002722: `审核确认`,
  key1002723: `线下已打款`,
  key1002724: `货品结算金额`,
  key1002725: `货品金额小计`,
  key1002726: `供应商账单号：`,
  key1002727: `供应商名称：`,
  key1002728: `供应商商户号：`,
  key1002729: `账单生成时间：`,
  key1002730: `账单结算状态：`,
  key1002731: `账单状态更新时间：`,
  key1002732: `账单金额：`,
  key1002733: `货品金额（应付）：`,
  key1002734: `物流费用（应收）：`,
  key1002735: `补贴金额（应付）：`,
  key1002736: `账单周期：`,
  key1002737: `物流费用`,
  key1002738: `账单审核确认`,
  key1002739: `是否已审核账单无误，审核确认后将变为可打款状态。`,
  key1002740: `账单线下已打款确认`,
  key1002741: `请确认是否已进行线下打款。`,
  key1002742: `确认线下已打款`,
  key1002743: `费用类型`,
  key1002744: `货品数量`,
  key1002745: `SPU近30天累计销量`,
  key1002746: `提单编号`,
  key1002747: `结束装箱时间`,
  key1002748: `揽收时间`,
  key1002749: `云卖网发货时间`,
  key1002750: `应付物流费：`,
  key1002751: `应付其他费用：`,
  key1002752: `物流实重(g)：`,
  key1002753: `未对账订单数量：`,
  key1002754: `物流商币种：`,
  key1002755: `账单明细`,
  key1002756: `仅看亏损订单`,
  key1002757: `仅看未对账订单`,
  key1002758: `盈亏(当前筛选条件)：`,
  key1002759: `账单编号：`,
  key1002760: `账单更新时间：`,
  key1002761: `客户实付物流费：`,
  key1002762: `其他应付费用：`,
  key1002763: `总费用：`,
  key1002764: `单据号`,
  key1002765: `单据状态`,
  key1002766: `系统计算物流重量(g)`,
  key1002767: `运单号`,
  key1002768: `物流实重(g)`,
  key1002769: `客户实付物流费`,
  key1002770: `应付物流费`,
  key1002771: `未对账`,
  key1002772: `应付其他费用`,
  key1002773: `总费用`,
  key1002774: `盈亏`,
  key1002775: `包裹出库时间`,
  key1002776: `对账数据来源`,
  key1002777: `导入账单`,
  key1002778: `应付物流费不能为空`,
  key1002779: `备注不能为空`,
  key1002780: `账单确认结算`,
  key1002781: `正在操作账单结算，结算后将无法再对账单进行修改。请确认是否结算？`,
  key1002782: `结算成功!`,
  key1002783: `修改币种成功！`,
  key1002784: `分销商商户号：`,
  key1002785: `充值时间：`,
  key1002786: `充值状态：`,
  key1002787: `充值流水号：`,
  key1002788: `请输入充值流水号,支持多个按行分隔`,
  key1002789: `导出充值记录`,
  key1002790: `充值`,
  key1002791: `充值中`,
  key1002792: `充值成功`,
  key1002793: `充值失败`,
  key1002794: `第三方凭证号`,
  key1002795: `第三方凭证`,
  key1002796: `充值前余额`,
  key1002797: `充值后余额`,
  key1002798: `初始化额度`,
  key1002799: `剩余额度`,
  key1002800: `充值状态`,
  key1002801: `订单处理费：`,
  key1002802: `订单耗材费：`,
  key1002803: `附加服务费：`,
  key1002804: `退货处理费：`,
  key1002805: `费用名称：`,
  key1002806: `费用金额：`,
  key1002807: `+添加费用`,
  key1002808: `月处理订单数：`,
  key1002809: `总金额：`,
  key1002810: `客户实付金额：`,
  key1002811: `其他费用：`,
  key1002812: `费用名称`,
  key1002813: `金额`,
  key1002814: `关联单据号`,
  key1002815: `订单内货品数量`,
  key1002816: `订单处理费`,
  key1002817: `订单耗材费`,
  key1002818: `附加服务费`,
  key1002819: `退货处理费`,
  key1002820: `合计`,
  key1002821: `客户实付金额`,
  key1002822: `费用产生时间`,
  key1002823: `修改费用`,
  key1002824: `单据费用`,
  key1002825: `其他费用`,
  key1002826: `订单处理费不能为空`,
  key1002827: `费用金额不能为空`,
  key1002828: `费用名称不能为空`,
  key1002829: `添加费用`,
  key1002830: `添加费用成功`,
  key1002831: `修改费用成功`,
  key1002832: `添加备注成功`,
  key1002833: `充值币种：`,
  key1002834: `若充值币种与分销商币种不一致时，系统将按汇率转为分销商币种进行充值。`,
  key1002835: `充值面额：`,
  key1002836: `付款方式：`,
  key1002837: `支付流水号：`,
  key1002838: `支付流水图片：`,
  key1002839: `上传附件`,
  key1002840: `分销商货币为`,
  key1002841: `，实际到账`,
  key1002842: `系统汇率：1`,
  key1002843: `现金`,
  key1002844: `分销商商户号不能为空`,
  key1002845: `付款方式不能为空`,
  key1002846: `支付流水号不能为空`,
  key1002847: `充值币种不能为空`,
  key1002848: `自定义的充值面额不能为空！`,
  key1002849: `请上传支付流水图片！`,
  key1002850: `充值成功！`,
  key1002851: `最多支持上传五个附件！`,
  key1002852: `请输入分销商商户号`,
  key1002853: `（分销商币种）`,
  key1002854: `账单状态：`,
  key1002855: `可搜索账单号/供应商名称`,
  key1002856: `导出供应商账单`,
  key1002857: `批量打款`,
  key1002858: `批量确认线下打款`,
  key1002859: `供应商账单号`,
  key1002860: `请输入供应商账单号，支持多个逗号或换行分隔`,
  key1002861: `账单金额`,
  key1002862: `账单币种`,
  key1002863: `订单数量`,
  key1002864: `账单结算状态`,
  key1002865: `账单状态更新时间`,
  key1002866: `供应商确认时间`,
  key1002867: `审核时间`,
  key1002868: `打款时间`,
  key1002869: `打款人`,
  key1002870: `打款方式`,
  key1002871: `收款账户信息`,
  key1002872: `收款方式`,
  key1002873: `开户人姓名`,
  key1002874: `开户行`,
  key1002875: `账户`,
  key1002876: `身份证号`,
  key1002877: `账单明细信息`,
  key1002878: `供货价(CNY)`,
  key1002879: `货品金额小计(CNY)`,
  key1002880: `产生费用时间`,
  key1002881: `面单类型`,
  key1002882: `订单创建时间`,
  key1002883: `中心仓发货时间`,
  key1002884: `是否中心仓备货`,
  key1002885: `总账单`,
  key1002886: `仅收款账户为支付宝的才能批量打款！`,
  key1002887: `请先选择账单！`,
  key1002888: `支付宝订单号`,
  key1002889: `线下支付`,
  key1002890: `补贴规则：`,
  key1002891: `请选择补贴规则`,
  key1002892: `物流`,
  key1002893: `物流方式`,
  key1002894: `补贴规则`,
  key1002895: `提单重量`,
  key1002896: `提货单内货品数量`,
  key1002897: `准时发货率`,
  key1002898: `入库时间`,
  key1002899: `分拣时间`,
  key1002900: `最后更新人`,
  key1002901: `请输入提单号,支持多个逗号或换行分隔`,
  key1002902: `请输入运单号,支持多个逗号或换行分隔`,
  key1002903: `请输入供应商商户号,支持多个逗号或换行分隔`,
  key1002904: `仅待审核的订单可批量审核！`,
  key1002905: `请先勾选需要审核的数据！`,
  key1002906: `上门揽收`,
  key1002907: `说明`,
  key1002908: `已审核`,
  key1002909: `提单内货品数量`,
  key1002910: `重新审核`,
  key1002911: `补贴说明`,
  key1002912: `总计：`,
  key1002913: `物流-快递`,
  key1002914: `物流-上门揽收`,
  key1002915: `.截止`,
  key1002916: `前的`,
  key1002917: `单`,
  key1002918: `按提单个数补贴`,
  key1002919: `按提单重量补贴`,
  key1002920: `无补贴`,
  key1002921: `发货方式`,
  key1002922: `订单发货率`,
  key1002923: `补贴对象`,
  key1002924: `补贴类型`,
  key1002925: `补贴条件`,
  key1002926: `使用`,
  key1002927: `实际数据`,
  key1002928: `补贴计算`,
  key1002929: `1.单个提单补贴：`,
  key1002930: `1.0-1公斤补贴：`,
  key1002931: `2.续重每公斤补贴：`,
  key1002932: `3.单个提单补贴上限：`,
  key1002933: `符合子规则：`,
  key1002934: `符合规则：`,
  key1002935: `补贴手动调整为：`,
  key1002936: `导出仓储账单`,
  key1002937: `更新账单`,
  key1002938: `结算状态`,
  key1002939: `结算中`,
  key1002940: `已结算`,
  key1002941: `总金额(CNY)`,
  key1002942: `订单处理量`,
  key1002943: `仓库租金`,
  key1002944: `账单更新时间`,
  key1002945: `月订单处理量(CNY)`,
  key1002946: `订单耗材费用(CNY)`,
  key1002947: `附加费用(CNY)`,
  key1002948: `退货处理费用(CNY)`,
  key1002949: `仓库租金(CNY)`,
  key1002950: `订单耗材费用`,
  key1002951: `附加服务费用`,
  key1002952: `退货处理费用`,
  key1002953: `合计总金额`,
  key1002954: `仓储费用重新计算中，请稍候刷新列表查看！`,
  key1002955: `请选勾选需要更新的数据！`,
  key1002956: `规则计算补贴：`,
  key1002957: `调整后补贴：`,
  key1002958: `请输入调整后补贴金额`,
  key1002959: `调整说明：`,
  key1002960: `请输入调整说明`,
  key1002961: `调整后补贴金额不能为空`,
  key1002962: `调整说明不能为空`,
  key1002963: `费用流水状态：`,
  key1002964: `费用类型：`,
  key1002965: `费用产生时间：`,
  key1002966: `分销商`,
  key1002967: `单据编号`,
  key1002968: `账单所属月份`,
  key1002969: `费用流水状态`,
  key1002970: `已生成账单`,
  key1002971: `未生成账单`,
  key1002972: `导出物流账单`,
  key1002973: `导入物流商账单`,
  key1002974: `导入物流账单`,
  key1002975: `1.已结算的账单数据导入后不再进行更新，将显示为失败;`,
  key1002976: `2.支持扩展名:`,
  key1002977: `3.当导入数据存在异常时，数据导入失败;`,
  key1002978: `账号`,
  key1002979: `月包裹数`,
  key1002980: `账单匹配数量`,
  key1002981: `请选择补贴审核结果：`,
  key1002982: `审核不通过原因：`,
  key1002983: `审核原因不能为空`,
  key1002984: `删除打款密码`,
  key1002985: `请输入打款密码：`,
  key1002986: `新打款密码：`,
  key1002987: `请再次输入新密码：`,
  key1002988: `请再次输入打款密码`,
  key1002989: `请再次输入密码：`,
  key1002990: `账单打款密码`,
  key1002991: `删除密码`,
  key1002992: `删除打款密码后,账单线上打款确认时将不再需要输入密码,请谨慎操作。`,
  key1002993: `未设置`,
  key1002994: `设置打款密码`,
  key1002995: `打款密码不能为空`,
  key1002996: `更换成功!`,
  key1002997: `保存成功!`,
  key1002998: `两次输入的密码不一致`,
  key1002999: `已设置`,
  key1003000: `修改打款密码`,
  key1003001: `消费记录（订单仅计算已取消及已出库部分）`,
  key1003002: `月累计充值：`,
  key1003003: `月支付宝累计充值：`,
  key1003004: `月手工累计充值：`,
  key1003005: `月累计消费：`,
  key1003006: `第三方交易凭证`,
  key1003007: `多个`,
  key1003008: `SKU请换行分隔`,
  key1003009: `提单SKU详情`,
  key1003010: `当前提单内SKU如下图所示，共`,
  key1003011: `种，合计`,
  key1003012: `件。`,
  key1003013: `货品上架`,
  key1003014: `待上架数量`,
  key1003015: `上架数量`,
  key1003016: `仓库名称不能为空!`,
  key1003017: `库区名称不能为空!`,
  key1003018: `库位名称不能为空!`,
  key1003019: `上架数量不能为空!`,
  key1003020: `上架数量必须是大于0的整数`,
  key1003021: `上架数量不能大于待上架数量!`,
  key1003022: `上架数量必须大于0`,
  key1003023: `扫描或输入供应商SKU`,
  key1003024: `批量打印`,
  key1003025: `正在贴单作业...`,
  key1003026: `结束贴单作业`,
  key1003027: `应贴数量`,
  key1003028: `扫描成功！`,
  key1003029: `请输入正确的供应商SKU！`,
  key1003030: `供应商SKU不能为空!`,
  key1003031: `请先勾选要打印的数据！`,
  key1003032: `打印数量不能为空!`,
  key1003033: `常规分拣`,
  key1003034: `备货分拣`,
  key1003035: `扫描：`,
  key1003036: `可扫描出库单号、运单号、物流商单号、容器编号`,
  key1003037: `称重：`,
  key1003038: `请保持系统重量单位和电子秤重量单位一致`,
  key1003039: `实际称重(`,
  key1003040: `称重`,
  key1003041: `物流渠道：`,
  key1003042: `结束容器分拣`,
  key1003043: `结袋`,
  key1003044: `打印预约交货面单提示`,
  key1003045: `速卖通线上发货需打印预交面单，请结袋后在物流交接单中打印预交面单。`,
  key1003046: `暂不结袋`,
  key1003047: `直接结袋`,
  key1003048: `预约并结袋`,
  key1003049: `打印揽收面单提示`,
  key1003050: `速卖通全托管JIT订单需打印揽收面单，若未打印将无法交付速卖通揽收。`,
  key1003051: `打印并结袋`,
  key1003052: `移除`,
  key1003053: `移除包裹提示`,
  key1003054: `正在操作移除包裹，移除后可重新扫描装箱`,
  key1003055: `还需`,
  key1003056: `完成`,
  key1003057: `移除成功`,
  key1003058: `克`,
  key1003059: `千克`,
  key1003060: `称重单位为：`,
  key1003061: `请输入出库单号或运单号！`,
  key1003062: `称重必须是大于0的整数！`,
  key1003063: `称重不能为空！`,
  key1003064: `获取面单地址失败！`,
  key1003065: `你还有包裹未完成分拣，你是否结束分拣？`,
  key1003066: `物流分拣异常提示`,
  key1003067: `强制结束`,
  key1003068: `交接人：`,
  key1003069: `请输入交接人`,
  key1003070: `标记出库`,
  key1003071: `交接单号`,
  key1003072: `包裹数量`,
  key1003073: `交货方式`,
  key1003074: `快递至揽收点`,
  key1003075: `物流下发方式`,
  key1003076: `系统下发`,
  key1003077: `手动下发`,
  key1003078: `国内物流单号`,
  key1003079: `交接时间`,
  key1003080: `交接人`,
  key1003081: `继续装箱`,
  key1003082: `打印预交面单`,
  key1003083: `打印快递面单`,
  key1003084: `获取快递面单地址失败`,
  key1003085: `预约揽收`,
  key1003086: `打印揽收面单`,
  key1003087: `请输入交接单号,支持多个逗号或换行分隔`,
  key1003088: `请输入出库单号,支持多个逗号或换行分隔`,
  key1003089: `请先勾选要标记出库的数据！`,
  key1003090: `获取预交面单地址失败`,
  key1003091: `预约成功！`,
  key1003092: `获取揽收面单地址失败`,
  key1003093: `请扫描或录入提单号/快递单号`,
  key1003094: `箱/袋内出库单数量`,
  key1003095: `入库成功！`,
  key1003096: `请输入提单号！`,
  key1003097: `上架作业：`,
  key1003098: `扫描或输入容器编号`,
  key1003099: `支持多个容器编号，用逗号或换行分隔`,
  key1003100: `导出上架数据`,
  key1003101: `容器状态：`,
  key1003102: `容器状态`,
  key1003103: `商品数量`,
  key1003104: `作业开始时间`,
  key1003105: `继续上架`,
  key1003106: `实际上架数量`,
  key1003107: `作业结束时间`,
  key1003108: `作业时长`,
  key1003109: `按容器维度导出`,
  key1003110: `按SKU维度导出`,
  key1003111: `容器内SKU数量`,
  key1003112: `上架库位`,
  key1003113: `提货单号不能为空！`,
  key1003114: `商品上架作业提醒`,
  key1003115: `你有商品上架作业未完成，是否继续作业？`,
  key1003116: `贴单作业：`,
  key1003117: `导出贴单数据`,
  key1003118: `贴单作业`,
  key1003119: `继续贴单`,
  key1003120: `已贴单`,
  key1003121: `容器编号不能为空！`,
  key1003122: `商品贴单作业提醒`,
  key1003123: `你有商品贴单作业未完成，是否继续作业？`,
  key1003124: `分拣异常：`,
  key1003125: `揽收时间：`,
  key1003126: `导出提单列表`,
  key1003127: `批量标记已揽收`,
  key1003128: `缺失包裹`,
  key1003129: `物流轨迹`,
  key1003130: `应收货品数量`,
  key1003131: `应收部分实收数量`,
  key1003132: `供应商多发数量`,
  key1003133: `异常状态`,
  key1003134: `数量异常，请点击查看`,
  key1003135: `发货地址`,
  key1003136: `标记已揽收`,
  key1003137: `作废`,
  key1003138: `请输入SKU,支持多个逗号或换行分隔`,
  key1003139: `提货单号`,
  key1003140: `供应商商户名`,
  key1003141: `快递单号`,
  key1003142: `运输方式`,
  key1003143: `按提货单维度导出`,
  key1003144: `请先勾选要标记已揽收的数据！`,
  key1003145: `出库时间：`,
  key1003146: `明细信息`,
  key1003147: `(已取消)`,
  key1003148: `包裹实重（g）`,
  key1003149: `数据来源`,
  key1003150: `称重员`,
  key1003151: `移除成功！`,
  key1003152: `发货设置`,
  key1003153: `单位：`,
  key1003154: `截去：`,
  key1003155: `部分物流商面单扫描的运单号会带前缀或后缀，这样系统就无法识别运单号，勾选可截去相对应的前缀或后缀。`,
  key1003156: `开启(必填)`,
  key1003157: `开启(非必填)`,
  key1003158: `不开启`,
  key1003159: `前`,
  key1003160: `后`,
  key1003161: `请扫描TEMU标签号`,
  key1003162: `TEMU标签号`,
  key1003163: `是否已扫描`,
  key1003164: `重量（g）`,
  key1003165: `打印打包标签`,
  key1003166: `请扫描TEMU标签号！`,
  key1003167: `扫描成功`,
  key1003168: `请扫描所有TEMU小包后再进行结袋操作。`,
  key1003169: `未获取到面单地址！`,
  key1003170: `作废提单确认`,
  key1003171: `作废原因：`,
  key1003172: `请输入作废原因`,
  key1003173: `作废提单后，未发货订单将重新流转至供应商待发货列表中。`,
  key1003174: `确认作废`,
  key1003175: `作废成功！`,
  key1003176: `扫描记录`,
  key1003177: `撤销操作`,
  key1003178: `容器已结束不可撤销`,
  key1003179: `分拣容器：`,
  key1003180: `提单编号：`,
  key1003181: `分拣时间：`,
  key1003182: `供应商SKU：`,
  key1003183: `请先扫描提单号！`,
  key1003184: `撤销操作提示`,
  key1003185: `撤销后，对应容器中的SKU将移除，请谨慎操作。`,
  key1003186: `撤销`,
  key1003187: `提单中存在多发货品，`,
  key1003188: `将作为备货进行处理，并已自动创建相应备货单号，类型为多发补单`,
  key1003189: `提单号：`,
  key1003190: `提单申明数量及实际到仓数量`,
  key1003191: `销售订单：`,
  key1003192: `备货单：`,
  key1003193: `多发货品数量`,
  key1003194: `实收数量`,
  key1003195: `目的仓库`,
  key1003196: `已包装`,
  key1003197: `操作内容/留言`,
  key1003198: `请输入备注内容！`,
  key1003199: `分拣作业：`,
  key1003200: `扫描或输入提单号/快递单号`,
  key1003201: `提货单号：`,
  key1003202: `支持多个提货单号，用逗号或换行分隔`,
  key1003203: `导出分拣数据`,
  key1003204: `进入作业界面`,
  key1003205: `提货单状态：`,
  key1003206: `提货单状态`,
  key1003207: `提货单类型`,
  key1003208: `出库单数量`,
  key1003209: `分拣作业`,
  key1003210: `提货单货品数`,
  key1003211: `实际分拣数量`,
  key1003212: `sku数量`,
  key1003213: `入库操作时间`,
  key1003214: `分拣开始时间`,
  key1003215: `分拣结束时间`,
  key1003216: `分拣结束操作人`,
  key1003217: `入库分拣作业提醒`,
  key1003218: `你有入库分拣作业未完成，是否继续作业？`,
  key1003219: `上架异常提示`,
  key1003220: `上架数量不能超出待上架数量`,
  key1003221: `库区库位未填写`,
  key1003222: `部分上架`,
  key1003223: `结束上架`,
  key1003224: `SKU上架数量与待上架数量不一致，详情如下，请确认是否结束上架？`,
  key1003225: `继续作业`,
  key1003226: `应上架数量`,
  key1003227: `存在异常SKU，请检查后重试`,
  key1003228: `上架数据未填写完整`,
  key1003229: `扫描或输入`,
  key1003230: `SKU/供应商SKU/物流面单`,
  key1003231: `扫描或输入提货单号`,
  key1003232: `请扫描或输入容器编号`,
  key1003233: `正在分拣作业...`,
  key1003234: `结束分拣作业`,
  key1003235: `结束本单分拣`,
  key1003236: `逐件扫描`,
  key1003237: `超出应发数量是否提示`,
  key1003238: `启用语音播报`,
  key1003239: `需贴单`,
  key1003240: `请添加容器`,
  key1003241: `容器完拣`,
  key1003242: `找到多种类型面单，请正确选择！`,
  key1003243: `暂不处理`,
  key1003244: `绑定容器`,
  key1003245: `结束分拣`,
  key1003246: `已在`,
  key1003247: `容器中存在相同SKU，请结束`,
  key1003248: `容器后重新扫描提单`,
  key1003249: `SKU/供应商SKU/物流面单不能不为空！`,
  key1003250: `应收数量`,
  key1003251: `分拣数量`,
  key1003252: `还需分拣`,
  key1003253: `已扫数量`,
  key1003254: `请扫描商品`,
  key1003255: `已分拣数量`,
  key1003256: `未分拣`,
  key1003257: `提货单号不能为空!`,
  key1003258: `提货单中没有此商品，请仔细确认！`,
  key1003259: `此包裹已被分拣，请勿重复扫描`,
  key1003260: `容器完拣提示`,
  key1003261: `此容器拣货数量已达上限，系统将自动将容器完拣，请绑定新容器继续作业。`,
  key1003262: `此容器包装数量已达上限，系统将自动将容器完拣，请绑定新容器继续作业。`,
  key1003263: `将同时结束提货单与容器分拣，你确定要结束？`,
  key1003264: `绑定成功！`,
  key1003265: `容器颜色重复提示`,
  key1003266: `此颜色容器已存在，请选择不同颜色的容器。`,
  key1003267: `设置容器颜色提示`,
  key1003268: `此容器无颜色，请选择具有颜色属性的容器。`,
  key1003269: `请先扫描提单号!`,
  key1003270: `容器编号不能为空!`,
  key1003271: `请选择面单类型！`,
  key1003272: `SKU超出应收提示`,
  key1003273: `正在进行作业的SKU已超出应收数量，分拣完成后若存在超出应收数量的SKU，系统将自动进行补单并向供应商发送提示，无需人工操作。`,
  key1003274: `此SKU需进行上架，暂未绑定贴单+上架容器，请绑定贴单+上架容器：`,
  key1003275: `此SKU需进行上架，暂未绑定上架容器，请绑定上架容器：`,
  key1003276: `SKU数量异常提示`,
  key1003277: `以下SKU数量与应发数量有差异，结束分拣后实际多收的SKU系统将自动进行补单并向供应商发送提示。`,
  key1003278: `国内快递选择`,
  key1003279: `下单方式：`,
  key1003280: `国内快递：`,
  key1003281: `此物流渠道需发快递至揽收点，结袋后请将此渠道下的所有包裹再次打包，最外层贴国内快递面单！`,
  key1003282: `系统下单`,
  key1003283: `线下下单`,
  key1003284: `请选择国内快递`,
  key1003285: `请输入取消`,
  key1003286: `订单原因，此原因分销商可见。`,
  key1003287: `取消订单`,
  key1003288: `订单取消原因：`,
  key1003289: `确认取消`,
  key1003290: `订单原因`,
  key1003291: `取消成功!`,
  key1003292: `供应商订单总金额：`,
  key1003293: `分销商订单总金额：`,
  key1003294: `分销商应付金额：`,
  key1003295: `供应商应收金额：`,
  key1003296: `保存并下单`,
  key1003297: `的供应商订单总金额和分销商订单总金额不能为空`,
  key1003298: `保存并下单成功`,
  key1003299: `备货单状态：`,
  key1003300: `备货单号：`,
  key1003301: `请输入备货单号,支持多个按行分隔`,
  key1003302: `下单时间：`,
  key1003303: `订单信息`,
  key1003304: `分销商：`,
  key1003305: `查看日志`,
  key1003306: `待调价`,
  key1003307: `待供应商接单`,
  key1003308: `待分销商付款`,
  key1003309: `部分到货`,
  key1003310: `已完结`,
  key1003311: `订单金额：`,
  key1003312: `订单原金额：`,
  key1003313: `调价后金额：`,
  key1003314: `已取消金额：`,
  key1003315: `已退款金额：`,
  key1003316: `订单时间`,
  key1003317: `付款时间：`,
  key1003318: `完结时间：`,
  key1003319: `供应商子单信息`,
  key1003320: `待重发`,
  key1003321: `已完成`,
  key1003322: `单号：`,
  key1003323: `子单金额`,
  key1003324: `金额：`,
  key1003325: `调价：`,
  key1003326: `结算：`,
  key1003327: `子单时间`,
  key1003328: `发货时间：`,
  key1003329: `入库时间：`,
  key1003330: `收货数量`,
  key1003331: `取消数量`,
  key1003332: `退款数量`,
  key1003333: `待入库`,
  key1003334: `请输入SPU，支持多个逗号或换行分隔`,
  key1003335: `请输入SKU，支持多个逗号或换行分隔`,
  key1003336: `请输入分销商商户号，支持多个逗号或换行分隔`,
  key1003337: `请输入供应商商户号，支持多个逗号或换行分隔`,
  key1003338: `下单确认`,
  key1003339: `正在操作下单。请确认是否下单？`,
  key1003340: `确认下单`,
  key1003341: `下单成功！`,
  key1003342: `原单价：`,
  key1003343: `调价后：`,
  key1003344: `单价：`,
  key1003345: `缺货数量：`,
  key1003346: `补货中`,
  key1003347: `预计到货时间：`,
  key1003348: `部分停售，请展开SKU查看`,
  key1003349: `缺货，请展开SKU查看`,
  key1003350: `主信息`,
  key1003351: `收货人信息`,
  key1003352: `物流商信息`,
  key1003353: `订单详情：`,
  key1003354: `订单SKU`,
  key1003355: `打包信息`,
  key1003356: `已发货（`,
  key1003357: `行号`,
  key1003358: `供应商订单号`,
  key1003359: `缺货状态`,
  key1003360: `缺货中，请查看详情`,
  key1003361: `订单状态更新时间`,
  key1003362: `是否作废`,
  key1003363: `未作废`,
  key1003364: `详情`,
  key1003365: `中文申报名`,
  key1003366: `英文申报名`,
  key1003367: `申报价值`,
  key1003368: `申报重量`,
  key1003369: `申报数量`,
  key1003370: `海关编码`,
  key1003371: `销售链接`,
  key1003372: `申报信息`,
  key1003373: `日志内容描述`,
  key1003374: `面单类型：`,
  key1003375: `分销商订单号：`,
  key1003376: `发货仓库：`,
  key1003377: `收货人名称：`,
  key1003378: `国家：`,
  key1003379: `买家识别号：`,
  key1003380: `详细地址1：`,
  key1003381: `详细地址2：`,
  key1003382: `物流商单号：`,
  key1003383: `邮寄方式：`,
  key1003384: `运单号1：`,
  key1003385: `运单号2：`,
  key1003386: `物流商称重（g）：`,
  key1003387: `物流商运费：`,
  key1003388: `Temu标签号`,
  key1003389: `SKU*数量`,
  key1003390: `包裹重量(g)`,
  key1003391: `暂无打包标签`,
  key1003392: `已配货`,
  key1003393: `已备货`,
  key1003394: `新增行请填写完整`,
  key1003395: `请先添加申报信息！`,
  key1003396: `当前小包未进行物流分拣，无法打印箱唛`,
  key1003397: `sku信息`,
  key1003398: `出库单类型：`,
  key1003399: `到仓时间：`,
  key1003400: `所有SKU`,
  key1003401: `到仓异常数量SKU`,
  key1003402: `发起备货数量`,
  key1003403: `实际到仓数量`,
  key1003404: `修改物流方式`,
  key1003405: `选择修改后仓库：`,
  key1003406: `选择修改后物流方式：`,
  key1003407: `账号：`,
  key1003408: `修改失败数据`,
  key1003409: `物流动态参数`,
  key1003410: `确认修改`,
  key1003411: `原物流商`,
  key1003412: `原物流方式`,
  key1003413: `目的地`,
  key1003414: `原因`,
  key1003415: `未选择数据`,
  key1003416: `请选择物流渠道`,
  key1003417: `账号不能为空`,
  key1003418: `SKU，支持多个逗号或换行分隔`,
  key1003419: `订单状态：`,
  key1003420: `订单标签：`,
  key1003421: `下发状态：`,
  key1003422: `物流方式：`,
  key1003423: `订单类型：`,
  key1003424: `来源：`,
  key1003425: `履约方：`,
  key1003426: `异常状态：`,
  key1003427: `来源为自有渠道的数据，才能修改物流方式！`,
  key1003428: `下发物流`,
  key1003429: `订单号，支持多个逗号或换行分隔`,
  key1003430: `状态更新时间：`,
  key1003431: `订单创建时间：`,
  key1003432: `订单发货时间：`,
  key1003433: `订单取消时间：`,
  key1003434: `个供应商子单`,
  key1003435: `物品数量：`,
  key1003436: `订单SKU信息`,
  key1003437: `订单发货时间`,
  key1003438: `发货时效`,
  key1003439: `请输入分销商订单号，支持多个逗号或换行分隔`,
  key1003440: `请输入供应商订单号，支持多个逗号或换行分隔`,
  key1003441: `请输入运单号，支持多个逗号或换行分隔`,
  key1003442: `请输入Temu标签号，支持多个逗号或换行分隔`,
  key1003443: `物流三方单号`,
  key1003444: `请输入物流三方单号，支持多个逗号或换行分隔`,
  key1003445: `（待执行发货）`,
  key1003446: `待下发`,
  key1003447: `下发中`,
  key1003448: `下发成功`,
  key1003449: `下发失败`,
  key1003450: `国内快递费：`,
  key1003451: `货品金额：`,
  key1003452: `物流费：`,
  key1003453: `处理费：`,
  key1003454: `需通过快递发至揽收点的包裹将加收国内快递费。`,
  key1003455: `清关费：`,
  key1003456: `更新承运商`,
  key1003457: `添加承运商`,
  key1003458: `物流三方单号：`,
  key1003459: `国内快递单号：`,
  key1003460: `发货信息`,
  key1003461: `客选物流：`,
  key1003462: `部分补货中`,
  key1003463: `部分已停售`,
  key1003464: `查看子单`,
  key1003465: `货品信息`,
  key1003466: `通知分销商`,
  key1003467: `允许取消`,
  key1003468: `自有渠道`,
  key1003469: `三方渠道`,
  key1003470: `商品费用(CNY)`,
  key1003471: `总费用(CNY)`,
  key1003472: `处理费(CNY)`,
  key1003473: `物流费(CNY)`,
  key1003474: `物流名称`,
  key1003475: `物流商单号`,
  key1003476: `运单号1`,
  key1003477: `运单号2`,
  key1003478: `供应商出库时间`,
  key1003479: `提单揽收时间`,
  key1003480: `订单取消时间`,
  key1003481: `订单取消原因`,
  key1003482: `订单类型`,
  key1003483: `订单标签`,
  key1003484: `平台订单信息`,
  key1003485: `平台站点`,
  key1003486: `店铺名`,
  key1003487: `平台订单号`,
  key1003488: `平台订单金额`,
  key1003489: `平台订单状态`,
  key1003490: `平台订单SKU信息`,
  key1003491: `下单时间`,
  key1003492: `付款时间`,
  key1003493: `订单维度`,
  key1003494: `SKU维度`,
  key1003495: `(列)`,
  key1003496: `(行)`,
  key1003497: `按行展示时，通用信息将进行合并，统计时请注意。`,
  key1003498: `状态更新时间`,
  key1003499: `按更新时间`,
  key1003500: `供应商仓直发`,
  key1003501: `分销集货仓发`,
  key1003502: `分销集货仓发(未贴标)`,
  key1003503: `单次最多查询1000条，请调整后重试。`,
  key1003504: `更新成功！`,
  key1003505: `请先勾选要下发物流的数据！`,
  key1003506: `请先勾选要修改的数据！`,
  key1003507: `请先勾选要添加标签的数据！`,
  key1003508: `删除标签确认`,
  key1003509: `正在操作删除订单标签。请确认是否删除?`,
  key1003510: `确认删除`,
  key1003511: `已装箱状态允许取消订单确认`,
  key1003512: `取消处理费：`,
  key1003513: `此订单已装箱，请务必确认现场是否未发出，取消后需及时联系相关人员将包裹进行取出。`,
  key1003514: `允许取消订单`,
  key1003515: `取消处理费不能为空`,
  key1003516: `供应商订单号：`,
  key1003517: `出库单状态：`,
  key1003518: `货品数量：`,
  key1003519: `缺货信息`,
  key1003520: `请选择备货单状态`,
  key1003521: `供应商出库单状态：`,
  key1003522: `请选择出库单状态`,
  key1003523: `供应商出库单类型：`,
  key1003524: `请选择供应商出库单类型`,
  key1003525: `导出订单`,
  key1003526: `已收货`,
  key1003527: `Sku,支持多个逗号或换行分隔`,
  key1003528: `备货单号`,
  key1003529: `请输入备货单号,支持多个逗号或换行分隔`,
  key1003530: `备货单状态`,
  key1003531: `供应商出库单状态`,
  key1003532: `供应商出库单类型`,
  key1003533: `发货时间`,
  key1003534: `取消时间`,
  key1003535: `请选择订单状态`,
  key1003536: `结算状态：`,
  key1003537: `请选择结算状态`,
  key1003538: `超时时间：`,
  key1003539: `，仓库实收数量不足，请至发货记录中查看详情`,
  key1003540: `已作废，需重新发货。`,
  key1003541: `剩余发货时间`,
  key1003542: `已超时时间`,
  key1003543: `条码：`,
  key1003544: `订单数量：`,
  key1003545: `到仓数量：`,
  key1003546: `超时时间`,
  key1003547: `完结时间`,
  key1003548: `完结时间指此订单流程已全部结束时间节点，订单将已此节点时间计入对应账期。`,
  key1003549: `标记到货`,
  key1003550: `更新标记`,
  key1003551: `超时未发`,
  key1003552: `请输入订单号，支持多个逗号或换行分隔`,
  key1003553: `请输入供应商SKU，支持多个逗号或换行分隔`,
  key1003554: `请输入供应商名称，支持多个逗号或换行分隔`,
  key1003555: `供应商商户编号`,
  key1003556: `订单总金额`,
  key1003557: `子单类型`,
  key1003558: `未入账`,
  key1003559: `超时时间开始时长不能大于结束时长`,
  key1003560: `获取标记缺货详情数据失败！`,
  key1003561: `已超时`,
  key1003562: `秒`,
  key1003563: `添加备注：`,
  key1003564: `修改运单号`,
  key1003565: `请输入运单号`,
  key1003566: `请输入物流商单号`,
  key1003567: `请选择物流方式`,
  key1003568: `运单号不能为空`,
  key1003569: `SKU，用逗号或换行分隔`,
  key1003570: `SPU，用逗号或换行分隔`,
  key1003571: `多个供应商商户号，用逗号或换行分隔`,
  key1003572: `库位：`,
  key1003573: `请输入库位代码`,
  key1003574: `货主：`,
  key1003575: `请输入商户号`,
  key1003576: `总库存：`,
  key1003577: `导出库存余量`,
  key1003578: `导出库存明细`,
  key1003579: `导出库存余量数据`,
  key1003580: `导出库存明细数据`,
  key1003581: `1.导入库存后将覆盖原来库存，请谨慎导入;`,
  key1003582: `商品导入`,
  key1003583: `按SKU`,
  key1003584: `规格属性值`,
  key1003585: `货主角色`,
  key1003586: `货主商户号`,
  key1003587: `按产品查询`,
  key1003588: `按产品/库位查询`,
  key1003589: `可用数量必须是大于等于0的正整数`,
  key1003590: `可用库存开始区间不能大于可用库存结束区间`,
  key1003591: `明细`,
  key1003592: `库存详情`,
  key1003593: `显示库存为0的数据`,
  key1003594: `常规包装`,
  key1003595: `备货包装`,
  key1003596: `包装人：`,
  key1003597: `请输入拣货单号，支持多个按行分隔`,
  key1003598: `导出多品分拣`,
  key1003599: `扫描拣货单单号/容器编号：`,
  key1003600: `你有包装中作业未完成，拣货单号：`,
  key1003601: `，是否继续作业?`,
  key1003602: `拣货单号`,
  key1003603: `拣货单状态`,
  key1003604: `出库单数`,
  key1003605: `拣货/分拣完成时间`,
  key1003606: `拣货/分拣人`,
  key1003607: `包装人`,
  key1003608: `开始作业`,
  key1003609: `作业操作人`,
  key1003610: `开始作业时间`,
  key1003611: `结束作业时间`,
  key1003612: `常规包装作业提醒`,
  key1003613: `备货包装作业提醒`,
  key1003614: `处理中....`,
  key1003615: `请输入拣货单号！`,
  key1003616: `单品拣货单打印时货品列表输入顺序：`,
  key1003617: `多品拣货单打印时货品列表输入顺序：`,
  key1003618: `集货超时：`,
  key1003619: `分拣时打印地址面单：`,
  key1003620: `拣货单设置`,
  key1003621: `集货超时设置`,
  key1003622: `包装作业设置`,
  key1003623: `常规分拣架设置(仅在多品多件包裹自动分拣功能开启时可用)`,
  key1003624: `启用左侧分拣货架`,
  key1003625: `货架矩阵`,
  key1003626: `行`,
  key1003627: `列`,
  key1003628: `启用中间分拣货架`,
  key1003629: `启用右侧分拣货架`,
  key1003630: `备货分拣架设置(仅在多品多件包裹自动分拣功能开启时可用)`,
  key1003631: `保存见货出单设置`,
  key1003632: `仅按货品库位字符升序`,
  key1003633: `仅按货品库位降序`,
  key1003634: `请至少选择一个常规拣货架！`,
  key1003635: `请至少选择一个备货拣货架！`,
  key1003636: `分拣人：`,
  key1003637: `你有分拣中作业未完成，拣货单号:`,
  key1003638: `分拣人`,
  key1003639: `扫描/录入拣货单单号开始多品分拣：`,
  key1003640: `常规分拣作业提醒`,
  key1003641: `备货分拣作业提醒`,
  key1003642: `包裹重量不能为空`,
  key1003643: `小包结袋`,
  key1003644: `包裹重量(g)：`,
  key1003645: `当前小包内SKU如下图所示，共`,
  key1003646: `件，确认数量后请先进行称重，再进行结袋操作。`,
  key1003647: `打印标签并结袋`,
  key1003648: `待盘点`,
  key1003649: `盘点中`,
  key1003650: `盘点单:`,
  key1003651: `盘点仓库：`,
  key1003652: `拣货提醒：`,
  key1003653: `盘点单状态：`,
  key1003654: `动态盘点：`,
  key1003655: `盲盘：`,
  key1003656: `盘点人员：`,
  key1003657: `库存数`,
  key1003658: `盘点数`,
  key1003659: `差异数`,
  key1003660: `成功生成`,
  key1003661: `张拣货单`,
  key1003662: `拣货单单号`,
  key1003663: `拣货单生成规则：`,
  key1003664: `去拣货单列表`,
  key1003665: `每张备货拣货单最大包裹数不能为空！`,
  key1003666: `每张常规拣货单最大包裹数不能为空！`,
  key1003667: `拣货单`,
  key1003668: `拣货单号：{pickingGoodsNo} 已经分拣完成，点击【结束本次分拣】结束本次作业`,
  key1003669: `您扫描的货品{sku}，当前拣货单中的包裹均不需要或需求数量已经满足。`,
  key1003670: `当前分拣作业拣货单：`,
  key1003671: `结束本次分拣`,
  key1003672: `SKU/货品身份码`,
  key1003673: `全部分拣完成`,
  key1003674: `货品异常警告`,
  key1003675: `可能的原因：`,
  key1003676: `1.您拣货的时候错误拣出该货品或该货品拣货过多。`,
  key1003677: `2.该货品条码粘贴错误。`,
  key1003678: `请将该货品交由异常处理部门处理。`,
  key1003679: `继续扫描其他货品（回车）`,
  key1003680: `拣货单信息：`,
  key1003681: `出库单信息：`,
  key1003682: `将该包裹重置为未开始分拣`,
  key1003683: `将该包裹从当前拣货单中移除等待生成新拣货单`,
  key1003684: `打印Temu中间标签`,
  key1003685: `见货货单`,
  key1003686: `分拣单`,
  key1003687: `Temu发货订单`,
  key1003688: `sku/名称/规格`,
  key1003689: `总货品数量`,
  key1003690: `已分拣货品数量`,
  key1003691: `包裹`,
  key1003692: `拣货单下出库单均已换单`,
  key1003693: `请扫描正确的SKU/货品身份码`,
  key1003694: `没有可用拣货篮`,
  key1003695: `多品备货分拣—结束分拣`,
  key1003696: `拣货单取消提示`,
  key1003697: `拣货单内已无出库单，拣货单已取消，点击按钮后将自动结束本次作业。`,
  key1003698: `打印失败`,
  key1003699: `打印标签成功！`,
  key1003700: `分拣打印完成`,
  key1003701: `应发数量`,
  key1003702: `容器货品数量`,
  key1003703: `待分拣数量`,
  key1003704: `打印标签`,
  key1003705: `当前货品无标签地址`,
  key1003706: `打印Temu中间标签成功！`,
  key1003707: `获取Temu中间标签地址失败！`,
  key1003708: `未获取到Temu中间标签面单地址`,
  key1003709: `拣货复核-结束作业`,
  key1003710: `您即将结束拣货单 {pickingGoodsNo} 的拣货复核`,
  key1003711: `共 {scannedCount} 人参与本次拣货复核`,
  key1003712: `包装总时间 {scannedTime} 分钟`,
  key1003713: `总计(人.时) {userScannedTime} 分钟`,
  key1003714: `您即将结束拣货单 {pickingGoodsNo} 的拣货复核，但系统检测到以下异常`,
  key1003715: `以下包裹货品已扫描但包裹正在打印中`,
  key1003716: `保存为Excel`,
  key1003717: `通常因打印机信息返回异常导致，需要人工确认地址标签是否已经正常打印。`,
  key1003718: `如果您标记为已打印，强制结束拣货复核时将认为这些包裹已经正常包装，包裹无法再生成新的拣货单。`,
  key1003719: `如果您标记为未打印，强制结束拣货复核时将认为这些包裹未打印包装，系统将把这些包裹从拣货单中移除，您可以重新生成新的拣货单。`,
  key1003720: `以下包裹尚未打印或包裹打印前被作废`,
  key1003721: `强制结束拣货复核时未打印包裹将从拣货单中移除，再次生成新的拣货单。`,
  key1003722: `拣货单打印之后，地址标签打印之前，包裹被作废，将会导致拣货的实物货品剩余。`,
  key1003723: `正常情况下，本项货品汇总应与您手中的货品实物一致。`,
  key1003724: `以下包裹打印之后被作废`,
  key1003725: `地址标签打印后，包裹被作废，会导致包裹被正常包装，建议立即拦截，并在[回收包裹]中进行确认。`,
  key1003726: `结束作业`,
  key1003727: `返回继续包装`,
  key1003728: `已扫描数量`,
  key1003729: `未拣数量`,
  key1003730: `已完成包装包裹总数`,
  key1003731: `已完成包装货品总数`,
  key1003732: `统计项目`,
  key1003733: `拣货单数量`,
  key1003734: `实际完成数量`,
  key1003735: `作业人`,
  key1003736: `包裹总数`,
  key1003737: `作业时长(分钟)`,
  key1003738: `包裹速度(每分钟)`,
  key1003739: `货品速度(每分钟)`,
  key1003740: `收件人`,
  key1003741: `扫描时间`,
  key1003742: `已打`,
  key1003743: `未打`,
  key1003744: `货品小计`,
  key1003745: `扫描货品`,
  key1003746: `获取包裹信息`,
  key1003747: `校对货品数量`,
  key1003748: `发送至打印机`,
  key1003749: `标记已打印`,
  key1003750: `SKU异常`,
  key1003751: `您扫描或输入的SKU【 {warnSku} 】在当前拣货单中未找到`,
  key1003752: `未匹配到指定`,
  key1003753: `当前拣货复核拣货单：`,
  key1003754: `当前操作员：`,
  key1003755: `退出当前作业`,
  key1003756: `自动匹配包裹：`,
  key1003757: `清空已扫描货品并重新扫描`,
  key1003758: `拣货单的出库单`,
  key1003759: `您需要先处理当前未打印的包裹`,
  key1003760: `您提交的扫描的结果，在当前拣货单中未能找到包裹信息，可能原因：`,
  key1003761: `*当前包裹拣货货品存在错误`,
  key1003762: `*您扫描的过程中存在遗漏或者重复。`,
  key1003763: `拣货单不存在或状态已改变,请刷新后重试`,
  key1003764: `拣货复核扫描的出库单不存在`,
  key1003765: `拣货复核扫描出库单已作废`,
  key1003766: `SKU未匹配合适出库单`,
  key1003767: `拣货复核已结束`,
  key1003768: `存在未完成包装的出库单`,
  key1003769: `等待提交后获取包裹信息`,
  key1003770: `物流商/邮寄方式`,
  key1003771: `SKU及数量`,
  key1003772: `打印货品标签`,
  key1003773: `获取速卖通全托管货品条码失败！`,
  key1003774: `名称 / 规格`,
  key1003775: `校对`,
  key1003776: `当前SKU对应订单为速卖通全托管JIT订单，需打印对应货品条码。请注意纸张大小需为60mm*30mm。`,
  key1003777: `打印成功`,
  key1003778: `打印Temu条码`,
  key1003779: `当前出库单：`,
  key1003780: `已装货品数量：`,
  key1003781: `整单完结`,
  key1003782: `分拣容器及对应出库单`,
  key1003783: `名称/规格`,
  key1003784: `已扫描数量不能为空！`,
  key1003785: `已扫 / 未扫`,
  key1003786: `已扫`,
  key1003787: `未扫`,
  key1003788: `打印Temu标签`,
  key1003789: `（部分包装）`,
  key1003790: `（已包装）`,
  key1003791: `（已完结）`,
  key1003792: `超出拣货单数量，请检查实际货品数量!`,
  key1003793: `SKU非此订单所需货品，请确认货品所属订单！`,
  key1003794: `SKU不能为空!`,
  key1003795: `存在未完结出库单，请标记完结后再结束作业。`,
  key1003796: `所有货品均已扫描，请结束作业`,
  key1003797: `超出拣货单数量，请检查实际货品数量`,
  key1003798: `此出库单已标记完结，请勿重复标记！`,
  key1003799: `整单编辑完结后不可进行变更，请确认无误后再进行标记。`,
  key1003800: `标记完结`,
  key1003801: `完结异常提示`,
  key1003802: `当前出库单末进行包装作业，标记完结后状态将流转至待生成拣货单中，请确认无误后再进行标记。`,
  key1003803: `请结束小包后再标记整单完结。`,
  key1003804: `您即将结束拣货单 {pickingGoodsNo} 的分拣作业，但系统检测到以下异常`,
  key1003805: `以下包裹未完成所有货品的分拣`,
  key1003806: `1.拣货货品小于拣货单要求数量。`,
  key1003807: `2.之前的分拣过程在某个拣货框中放入了过多的货品。`,
  key1003808: `3.等待分拣区尚有剩余货品未扫描。`,
  key1003809: `拣货与分拣过程均确保数量准确的情况下，待拣部分货品应该与等待分拣区的剩余货品数量一致。`,
  key1003810: `如果您觉得错误过多，无法追溯，您可以将所有拣货框中的货品全部放回等待分拣区，`,
  key1003811: `并`,
  key1003812: `撤销本次分拣`,
  key1003813: `拣货单可以再次开启分拣作业。`,
  key1003814: `返回继续分拣`,
  key1003815: `拣货框`,
  key1003816: `未分配`,
  key1003817: `货品（已拣/待拣）`,
  key1003818: `异常原因`,
  key1003819: `未开始分拣`,
  key1003820: `分拣未完成`,
  key1003821: `已拣`,
  key1003822: `待拣`,
  key1003823: `货品数量异常提示`,
  key1003824: `本次作业中存在以下SKU仍未扫描，请再次核对货品数量，若存在丢货情况请及时与管理人员联系。`,
  key1003825: `返回作业`,
  key1003826: `应扫数量`,
  key1003827: `实扫数量`,
  key1003828: `打印货品条码`,
  key1003829: `应发货品数量`,
  key1003830: `拣货框货品数量`,
  key1003831: `获取打印面单地址失败！`,
  key1003832: `重置成功！`,
  key1003833: `多品常规分拣—结束分拣`,
  key1003834: `获取货品条码失败！`,
  key1003835: `(Temu样品订单)`,
  key1003836: `(Temu货品订单)`,
  key1003837: `不存在拣货单明细`,
  key1003838: `打印SKU`,
  key1003839: `删除选中`,
  key1003840: `不自动关闭`,
  key1003841: `请下载通途打印控件`,
  key1003842: `条形码未生成成功，请重新点击打印。`,
  key1003843: `展示更多`,
  key1003844: `超出输入限制长度`,
  key1003845: `添加备注成功！`,
  key1003846: `添加备注失败！`,
  key1003847: `新增失败`,
  key1003848: `SKU，支持多个按行分隔`,
  key1003849: `导出出库明细`,
  key1003850: `订单号，支持多个按行分隔`,
  key1003851: `请输入分销商商户号，支持多个按行分隔`,
  key1003852: `请输入分销商订单号，支持多个按行分隔`,
  key1003853: `请输入运单号，支持多个按行分隔`,
  key1003854: `出库人`,
  key1003855: `生成拣货单人`,
  key1003856: `物流分拣人`,
  key1003857: `发货数量（件）`,
  key1003858: `分配库存时间`,
  key1003859: `生成拣货单时间`,
  key1003860: `完成拣货时间`,
  key1003861: `完成分拣时间`,
  key1003862: `完成包装时间`,
  key1003863: `完成物流分拣时间`,
  key1003864: `出库时间`,
  key1003865: `物流商运单号`,
  key1003866: `发货数量`,
  key1003867: `操作人员`,
  key1003868: `活动状态：`,
  key1003869: `活动名称：`,
  key1003870: `请输入活动名称关键字`,
  key1003871: `新增活动`,
  key1003872: `未开始`,
  key1003873: `进行中`,
  key1003874: `已结束`,
  key1003875: `活动图片`,
  key1003876: `活动名称`,
  key1003877: `活动状态`,
  key1003878: `活动开始时间`,
  key1003879: `活动结束时间`,
  key1003880: `长期有效`,
  key1003881: `立即开始`,
  key1003882: `立即开始活动确认`,
  key1003883: `确认后将活动将立即开始并展示在商城端，请确认是否开始？`,
  key1003884: `立即结束`,
  key1003885: `立即结束活动确认`,
  key1003886: `确认后将活动将立即结束，请确认是否结束？`,
  key1003887: `请输入入口名称`,
  key1003888: `图片：`,
  key1003889: `点击跳转：`,
  key1003890: `更换`,
  key1003891: `效果图`,
  key1003892: `金刚区`,
  key1003893: `图片上传`,
  key1003894: `(建议尺寸：60*60)`,
  key1003895: `未填写`,
  key1003896: `效果预览`,
  key1003897: `入口`,
  key1003898: `金刚区图片为空，请上传后重试。`,
  key1003899: `名称不能为空！`,
  key1003900: `请先上传`,
  key1003901: `的图片！`,
  key1003902: `的点击跳转地址不能为空！`,
  key1003903: `图片成功`,
  key1003904: `图片失败`,
  key1003905: `点击跳转`,
  key1003906: `跳转目标类型：`,
  key1003907: `跳转目标：`,
  key1003908: `活动`,
  key1003909: `指定链接`,
  key1003910: `不跳转`,
  key1003911: `跳转目标类型不能为空`,
  key1003912: `指定链接地址必须是https://或者http://开头的链接！`,
  key1003913: `跳转目标不能为空!`,
  key1003914: `请输入站点名称`,
  key1003915: `站点域名：`,
  key1003916: `请输入站点域名`,
  key1003917: `站点说明：`,
  key1003918: `请输入站点说明`,
  key1003919: `站点名称`,
  key1003920: `站点域名`,
  key1003921: `站点说明`,
  key1003922: `查看站点信息`,
  key1003923: `编辑站点信息`,
  key1003924: `banner图：`,
  key1003925: `首页轮播图`,
  key1003926: `顶部广告位`,
  key1003927: `侧边广告位`,
  key1003928: `首页-个人信息下方广告位`,
  key1003929: `更改启用状态提示`,
  key1003930: `此操作将更改该广告位在商城端是否可见，请确认是否继续更改。`,
  key1003931: `(建议尺寸：70*228)`,
  key1003932: `广告图为空，请上传后重试。`,
  key1003933: `点击跳转地址不能为空！`,
  key1003934: `(建议高度：80px)`,
  key1003935: `banner图为空，请上传后重试。`,
  key1003936: `(建议尺寸：228*70)`,
  key1003937: `统一定价(`,
  key1003938: `是否新增为首页tab：`,
  key1003939: `是否展示供应商信息：`,
  key1003940: `活动开始时间：`,
  key1003941: `选择活动开始时间`,
  key1003942: `活动结束时间：`,
  key1003943: `选择活动结束时间`,
  key1003944: `是否统一设置价格：`,
  key1003945: `配置匹配规则`,
  key1003946: `匹配规则：`,
  key1003947: `上新天数：`,
  key1003948: `移除商品确认`,
  key1003949: `活动信息`,
  key1003950: `(建议尺寸：1200*160)`,
  key1003951: `活动定价`,
  key1003952: `活动商品`,
  key1003953: `指定商品`,
  key1003954: `规则匹配`,
  key1003955: `配置规则`,
  key1003956: `批量移除`,
  key1003957: `规则说明：`,
  key1003958: `1.系统将自动匹配满足一定上新天数内的商品，如：填写7天，则系统将按审核通过时间匹配与今天相差7天内的商品添加至活动中。`,
  key1003959: `移除后商品将不再参加此活动，价格可能发生改变，系统将提醒已订阅的分销商。`,
  key1003960: `移除商品`,
  key1003961: `活动开始时间不能为空`,
  key1003962: `按上新天数匹配`,
  key1003963: `匹配规则不能为空`,
  key1003964: `上新天数不能为空`,
  key1003965: `标语：`,
  key1003966: `商品活动`,
  key1003967: `活动结束时间不能为空`,
  key1003968: `请先勾选参与活动的商品！`,
  key1003969: `请先配置匹配规则！`,
  key1003970: `统一定价不能为空！`,
  key1003971: `活动结束时间不能小于活动开始时间！`,
  key1003972: `费用配置`,
  key1003973: `发货仓：`,
  key1003974: `单件处理费：`,
  key1003975: `续件处理费：`,
  key1003976: `仓库处理费 = `,
  key1003977: `（件数-1）`,
  key1003978: `发货仓不能为空`,
  key1003979: `单件处理费不能为空`,
  key1003980: `续件处理费不能为空`,
  key1003981: `(建议尺寸：644*324)`,
  key1003982: `删除轮播项`,
  key1003983: `+添加轮播项`,
  key1003984: `轮播项`,
  key1003985: `轮播图为空，请上传后重试。`,
  key1003986: `轮播项1`,
  key1003987: `创建补贴规则`,
  key1003988: `补贴规则名称`,
  key1003989: `规则状态`,
  key1003990: `添加子规则`,
  key1003991: `运费补贴`,
  key1003992: `添加费用配置`,
  key1003993: `订单续件`,
  key1003994: `件`,
  key1003995: `发货仓`,
  key1003996: `费用规则`,
  key1003997: `补贴对象：`,
  key1003998: `补贴类型：`,
  key1003999: `选择时间`,
  key1004000: `多个商户号请用英文逗号，或换行分隔`,
  key1004001: `条件`,
  key1004002: `此名称客户可见，请合理命名`,
  key1004003: `补贴条件：`,
  key1004004: `添加条件`,
  key1004005: `补贴金额：`,
  key1004006: `子规则补贴将与主规则叠加，请合理配置`,
  key1004007: `单个提单补贴：`,
  key1004008: `0-1公斤补贴：`,
  key1004009: `续重每公斤补贴：`,
  key1004010: `单个提单补贴上限：`,
  key1004011: `补贴对象不能为空`,
  key1004012: `补贴类型不能为空`,
  key1004013: `创建子规则`,
  key1004014: `编辑补贴规则`,
  key1004015: `编辑子规则`,
  key1004016: `补贴规则未完善，请完整填写后重试。`,
  key1004017: `提单内货品数量必须是大于等于0整数`,
  key1004018: `订单发货率必须是大于等于0`,
  key1004019: `补贴金额未完善，请完整填写后重试。`,
  key1004020: `输入商户号`,
  key1004021: `服务器选择`,
  key1004022: `搜索：`,
  key1004023: `云卖刊登`,
  key1004024: `入驻成功`,
  key1004025: `更多角色`,
  key1004026: `可输入员工姓名、邮箱、手机号`,
  key1004027: `账号状态：`,
  key1004028: `管理员工角色`,
  key1004029: `添加新员工`,
  key1004030: `按姓名`,
  key1004031: `按邮箱`,
  key1004032: `最后登录时间`,
  key1004033: `是否要删除当前角色？`,
  key1004034: `角色名称：`,
  key1004035: `请输入角色名称`,
  key1004036: `组装加工 - `,
  key1004037: `拣货 - `,
  key1004038: `模块`,
  key1004039: `添加新角色`,
  key1004040: `角色名称不能为空`,
  key1004041: `编辑角色`,
  key1004042: `基本资料`,
  key1004043: `所属角色`,
  key1004044: `数据权限`,
  key1004045: `仓库权限`,
  key1004046: `仓库所有数据权限`,
  key1004047: `拓客数据权限`,
  key1004048: `所有数据权限`,
  key1004049: `新增员工`,
  key1004050: `邮箱不能为空`,
  key1004051: `查看员工`,
  key1004052: `编辑员工`,
  key1004053: `规则名：`,
  key1004054: `实际发货物流方式：`,
  key1004055: `帐号：`,
  key1004056: `选择指定物流`,
  key1004057: `选择符合条件物流`,
  key1004058: `已停用，请修改后重新保存`,
  key1004059: `物流相关设置`,
  key1004060: `时效：小于`,
  key1004061: `无`,
  key1004062: `有`,
  key1004063: `请输入规则名`,
  key1004064: `请添加物流方式`,
  key1004065: `时效和运单号请至少选择一个`,
  key1004066: `实际发货物流方式不能为空`,
  key1004067: `账户不能为空`,
  key1004068: `新增物流`,
  key1004069: `选择物流：`,
  key1004070: `请选择自定义物流类型：`,
  key1004071: `自定义物流商代码：`,
  key1004072: `自定义物流商名称：`,
  key1004073: `API对接`,
  key1004074: `类型不能为空`,
  key1004075: `自定义物流商代码不能为空`,
  key1004076: `自定义物流商名称不能为空`,
  key1004077: `无需数据对接`,
  key1004078: `Excel对接`,
  key1004079: `输入名称搜索`,
  key1004080: `物流渠道名称/代码：`,
  key1004081: `未启用物流方式`,
  key1004082: `新建自定义物流`,
  key1004083: `物流方案代码`,
  key1004084: `接收方指定提交代码`,
  key1004085: `物流时效`,
  key1004086: `发件地址`,
  key1004087: `揽收地址`,
  key1004088: `退件地址`,
  key1004089: `请勾选要启用的物流方式`,
  key1004090: `关联物流`,
  key1004091: `物流渠道名称：`,
  key1004092: `添加关联`,
  key1004093: `物流仅支持绑定一条渠道！`,
  key1004094: `(天)`,
  key1004095: `确定要取消关联？`,
  key1004096: `取消关联`,
  key1004097: `请先添加关联物流！`,
  key1004098: `添加成功!`,
  key1004099: `请先选择完整的物流方式!`,
  key1004100: `编辑自定义物流`,
  key1004101: `交运方式不能为空`,
  key1004102: `揽收时间不能为空`,
  key1004103: `交货方式：`,
  key1004104: `国内快递费用：`,
  key1004105: `物流服务商(Carrier)：`,
  key1004106: `发货国家：`,
  key1004107: `揽收方式：`,
  key1004108: `交运方式：`,
  key1004109: `面单模板`,
  key1004110: `'面单尺寸'`,
  key1004111: `发货说明：`,
  key1004112: `全球可达(运费相同)`,
  key1004113: `全球可达(运费不同)`,
  key1004114: `非全球可达`,
  key1004115: ` 运费折扣： `,
  key1004116: ` 通折： `,
  key1004117: `渠道基础信息`,
  key1004118: `物流商类型：`,
  key1004119: `无对接`,
  key1004120: `物流方案代码：`,
  key1004121: `参考时效：`,
  key1004122: `保险服务：`,
  key1004123: `尺寸限制(cm)：`,
  key1004124: `适用重量`,
  key1004125: `周长限制(cm)：`,
  key1004126: `最长边限制(cm)：`,
  key1004127: `体积限制(cm³)：`,
  key1004128: `追踪信息查询网址：`,
  key1004129: `揽收点地址信息`,
  key1004130: `物流商可选设置(默认设置)`,
  key1004131: `派送范围及运费`,
  key1004132: `派送范围`,
  key1004133: `计费模型`,
  key1004134: `从其他渠道引用`,
  key1004135: `批量导入`,
  key1004136: `新增分区`,
  key1004137: `运费币种：`,
  key1004138: `运费折扣`,
  key1004139: `通折`,
  key1004140: `请注意，无折扣时，请输入1，八折时，请输入0.8`,
  key1004141: `适用仓库`,
  key1004142: `全部自营仓库`,
  key1004143: `平台标发货设置`,
  key1004144: `不填运单号`,
  key1004145: `（默认）`,
  key1004146: `添加特例国家`,
  key1004147: `面单打印设置`,
  key1004148: `打印配货清单`,
  key1004149: `标准报价`,
  key1004150: `操作费（元/票）`,
  key1004151: `首重（g）`,
  key1004152: `首重运费`,
  key1004153: `续重单位重量（g）`,
  key1004154: `续重单价（元）`,
  key1004155: `特例国家/地区不能为空`,
  key1004156: `物流服务商(Carrier)不能为空`,
  key1004157: `分区`,
  key1004158: `默认分区`,
  key1004159: `通达国家`,
  key1004160: `所有国家`,
  key1004161: `计费方式`,
  key1004162: `计重方式`,
  key1004163: `计费公式`,
  key1004164: `手动同步`,
  key1004165: `下发物流商成功后同步`,
  key1004166: `获取运单号成功后同步`,
  key1004167: `出库成功后同步`,
  key1004168: `折扣价`,
  key1004169: `寄件人地址`,
  key1004170: `揽收人地址`,
  key1004171: `退件人地址`,
  key1004172: `买家自送`,
  key1004173: `国内快递费用不能为空`,
  key1004174: `物流渠道代码不能为空`,
  key1004175: `默认分区外计费模型国家不能为空`,
  key1004176: `分段计费表格参数不匹配或不完整`,
  key1004177: `编辑物流渠道`,
  key1004178: `查看物流渠道`,
  key1004179: `新增物流渠道`,
  key1004180: `首重`,
  key1004181: `元`,
  key1004182: `续重`,
  key1004183: `不能添加相同名称的物流商服务！`,
  key1004184: `规则指定订单来源`,
  key1004185: `规则指定订单目的国家/地区包括`,
  key1004186: `规则指定发货指定方式`,
  key1004187: `适用范围`,
  key1004188: `适用平台：`,
  key1004189: `适用店铺：`,
  key1004190: `适用包裹金额($)：`,
  key1004191: `买家支付的运费范围($)：`,
  key1004192: `包裹包含SKU：`,
  key1004193: `支持多个SKU，请用逗号或空格分开`,
  key1004194: `商品总数量范围：`,
  key1004195: `运费最低`,
  key1004196: `时效最快`,
  key1004197: `上网时间最短`,
  key1004198: `妥投率最高`,
  key1004199: `智能物流匹配逻辑说明`,
  key1004200: `匹配物流渠道基础属性`,
  key1004201: `必要流程：找出可发物流商`,
  key1004202: `订单匹配物流渠道基础属性中的：`,
  key1004203: `1.尺寸限制`,
  key1004204: `2.重量限制`,
  key1004205: `3.接收类型限制`,
  key1004206: `4.配送范围限制`,
  key1004207: `匹配结果=1结束，匹配结果>1进行下一步`,
  key1004208: `5.发货仓库支持的物流渠道`,
  key1004209: `匹配物流规则`,
  key1004210: `上一步匹配成功的结果集跑物流匹配规则，通过规则引擎匹配，从上到下匹配`,
  key1004211: `匹配物流适用范围`,
  key1004212: `可选流程：适用范围`,
  key1004213: `用户可自定义物流渠道的适用范围，匹配所有渠道的适用范围`,
  key1004214: `运费/时效优先级`,
  key1004215: `必要流程：选择运费最低或时效最快`,
  key1004216: `计算所有符合条件物流渠道的运费/时效，根据设置的优先级选择运费最低或时效最快的`,
  key1004217: `物流规则`,
  key1004218: `新增物流规则`,
  key1004219: `已选发货指定方式：`,
  key1004220: `物流渠道适用范围`,
  key1004221: `更多仓库`,
  key1004222: `拖拽`,
  key1004223: `规格名称`,
  key1004224: `最后修改人`,
  key1004225: `物流商/物流渠道`,
  key1004226: `平台/账号`,
  key1004227: `商品标签`,
  key1004228: `运费范围`,
  key1004229: `包含sku`,
  key1004230: `数量范围`,
  key1004231: `所有`,
  key1004232: `新建规则`,
  key1004233: `物流名称：`,
  key1004234: `关联物流渠道：`,
  key1004235: `关联物流渠道`,
  key1004236: `关联`,
  key1004237: `分销商物流`,
  key1004238: `供应商物流`,
  key1004239: `适用范围：`,
  key1004240: `适用重量 (g)：`,
  key1004241: `体积限制(cm)：`,
  key1004242: `省州：`,
  key1004243: `街道：`,
  key1004244: `旺旺：`,
  key1004245: `揽件时间：`,
  key1004246: `代理人`,
  key1004247: `国内`,
  key1004248: `国外`,
  key1004249: `方案基础信息`,
  key1004250: `重量单位：`,
  key1004251: `速卖通线上发货设置`,
  key1004252: `eDIS物流设置`,
  key1004253: `Logistics设置`,
  key1004254: `选择发件地址：`,
  key1004255: `选择揽收地址：`,
  key1004256: `交运地址：`,
  key1004257: `选择退件地址：`,
  key1004258: `交运方式与揽件时间`,
  key1004259: `卖家自送`,
  key1004260: `清关费用配置`,
  key1004261: `是否收取清关费用：`,
  key1004262: `收取`,
  key1004263: `不收取`,
  key1004264: `比例配置：申报价值在`,
  key1004265: `至150EUR时，按如下税率计算后`,
  key1004266: `超出150欧的包裹将下发物流失败。`,
  key1004267: `续重单价`,
  key1004268: `税率`,
  key1004269: `税率必须是大于等于0且最多允许2位小数`,
  key1004270: `默认分区外计费模型国家不能为空!`,
  key1004271: `必填项不能为空!`,
  key1004272: `内容不能为空`,
  key1004273: `物流商名称：`,
  key1004274: `是否可用：`,
  key1004275: `适用平台不能为空`,
  key1004276: `线下物流`,
  key1004277: `线上物流`,
  key1004278: `物流商名称`,
  key1004279: `分销商是否可用`,
  key1004280: `物流账户`,
  key1004281: `适用平台`,
  key1004282: `对接类型`,
  key1004283: `编辑账号`,
  key1004284: `修改适用范围`,
  key1004285: `分销商是否可用：`,
  key1004286: `账号名称不能为空`,
  key1004287: `个人姓名/公司名称不能为空！`,
  key1004288: `地址信息不能为空！`,
  key1004289: `账号名称：`,
  key1004290: `授权状态：`,
  key1004291: `个人姓名/公司名称：`,
  key1004292: `联系电话：`,
  key1004293: `地址信息：`,
  key1004294: `重新授权`,
  key1004295: `去授权`,
  key1004296: `已授权`,
  key1004297: `未授权`,
  key1004298: `EU代理人`,
  key1004299: `获取申请授权回调地址失败！`,
  key1004300: `使用的IOSS：`,
  key1004301: `条件类型：`,
  key1004302: `平台：`,
  key1004303: `店铺：`,
  key1004304: `此名称仅用于更好区分不同IOSS`,
  key1004305: `按店铺`,
  key1004306: `按平台`,
  key1004307: `请选择使用的IOSS`,
  key1004308: `请选择店铺`,
  key1004309: `添加规则`,
  key1004310: `添加IOSS`,
  key1004311: `申报说明：`,
  key1004312: `YMS将按照产品在YMS中的销售价格，即对应YMS订单的商品价格进行申报。`,
  key1004313: `查看税率`,
  key1004314: `当平台订单中存在IOSS号时，YMS将优先使用平台返回的IOSS编号，若无法匹配到IOSS号时系统将拦截此订单。`,
  key1004315: `手工订单`,
  key1004316: `IOSS管理`,
  key1004317: `IOSS规则配置`,
  key1004318: `IOSS名称`,
  key1004319: `IOSS编号`,
  key1004320: `使用IOSS名称`,
  key1004321: `使用IOSS编号`,
  key1004322: `适用平台/店铺`,
  key1004323: `适用店铺`,
  key1004324: `启用IOSS确认`,
  key1004325: `停用IOSS确认`,
  key1004326: `启用IOSS后，相关规则将可被匹配，请确认是否启用IOSS？`,
  key1004327: `停用IOSS后，使用此IOSS编号的规则将同时停用，请确认是否停用IOSS?`,
  key1004328: `启用IOSS规则`,
  key1004329: `停用IOSS规则`,
  key1004330: `请确认是否启用规则？`,
  key1004331: `请确认是否停用规则？`,
  key1004332: `原价`,
  key1004333: `调整后价格`,
  key1004334: `费率`,
  key1004335: `验证身份`,
  key1004336: `设置新邮箱`,
  key1004337: `设置成功`,
  key1004338: `验证码：`,
  key1004339: `手机验证码：`,
  key1004340: `请输入手机验证码`,
  key1004341: `新邮箱：`,
  key1004342: `邮箱验证码：`,
  key1004343: `请输入邮箱验证码`,
  key1004344: `下一步`,
  key1004345: `提`,
  key1004346: `交`,
  key1004347: `图片验证码不能为空`,
  key1004348: `手机号格式不正确!`,
  key1004349: `手机号和图片验证码不能为空！`,
  key1004350: `邮箱格式不正确!`,
  key1004351: `邮箱不能为空！`,
  key1004352: `验证码已发送！`,
  key1004353: `手机号不能为空`,
  key1004354: `邮箱格式不正确`,
  key1004355: `手机验证`,
  key1004356: `邮箱验证`,
  key1004357: `登录密码`,
  key1004358: `定期更换密码有助于账户安全`,
  key1004359: `上新日期：`,
  key1004360: `上新SPU：`,
  key1004361: `全部标记已读`,
  key1004362: `标记已读`,
  key1004363: `仅看未读`,
  key1004364: `订单异常通知`,
  key1004365: `停售通知`,
  key1004366: `调价通知`,
  key1004367: `店铺上新通知`,
  key1004368: `自动规则通知`,
  key1004369: `请先勾选要标记的数据！`,
  key1004370: `分销商名称：`,
  key1004371: `币种：`,
  key1004372: `修改币种`,
  key1004373: `修改手机`,
  key1004374: `电话：`,
  key1004375: `修改邮箱`,
  key1004376: `IOSS名称：`,
  key1004377: `IOSS编号：`,
  key1004378: `IOSS名称不能为空`,
  key1004379: `编辑IOSS`,
  key1004380: `IOSS编号不能为空`,
  key1004381: `请输入IM+10位数字`,
  key1004382: `原密码：`,
  key1004383: `新密码：`,
  key1004384: `再次确认新密码：`,
  key1004385: `原密码不能为空`,
  key1004386: `新密码不能为空`,
  key1004387: `更新成功!`,
  key1004388: `新密码与确认新密码不一致`,
  key1004389: `设置新手机`,
  key1004390: `新手机号：`,
  key1004391: `请输入新手机号`,
  key1004392: `邮箱和图片验证码不能为空！`,
  key1004393: `手机号码格式不正确!`,
  key1004394: `手机号不能为空！`,
  key1004395: `最小库存`,
  key1004396: `最大库存`,
  key1004397: `¥最低价`,
  key1004398: `¥最高价`,
  key1004399: `价格：`,
  key1004400: `一键刊登`,
  key1004401: `下载资料`,
  key1004402: `综合`,
  key1004403: `销量`,
  key1004404: `上新时间`,
  key1004405: `总库存`,
  key1004406: `请勾选需要取消收藏的商品`,
  key1004407: `取消收藏成功!`,
  key1004408: `请勾选需要收藏的商品`,
  key1004409: `商品收藏成功`,
  key1004410: `请勾选需要下载资料的商品`,
  key1004411: `YMS订单`,
  key1004412: `平台订单`,
  key1004413: `备货单`,
  key1004414: `订单规则`,
  key1004415: `每隔`,
  key1004416: `分钟一次`,
  key1004417: `可用数量=`,
  key1004418: `订单超过`,
  key1004419: `小时未装箱时自动取消`,
  key1004420: `库存同步规则`,
  key1004421: `商品状态同步规则`,
  key1004422: `店铺`,
  key1004423: `刊登模板`,
  key1004424: `同步时间`,
  key1004425: `启动`,
  key1004426: `库存同步`,
  key1004427: `商品状态同步`,
  key1004428: `价格同步`,
  key1004429: `供应商仓库`,
  key1004430: `商品停售时库存自动调整为0`,
  key1004431: `商品停售时自动同步状态至上架工具`,
  key1004432: `删除后将不可恢复，你确定要删除？`,
  key1004433: `请选择适用平台`,
  key1004434: `此规则适用于商品价格发生变动时自动通过`,
  key1004435: `调整价格`,
  key1004436: `请选择适用店铺`,
  key1004437: `添加价格同步规则`,
  key1004438: `修改价格同步规则`,
  key1004439: `价格同步规则`,
  key1004440: `可能会因为库存数量基数较小，浮动后使库存为0，也可能因为库存基数较大，浮动后与浮动前库存没有变化，请谨慎设置浮动比例。`,
  key1004441: `公式计算结果四舍五入，比如：计算结果为9.5，实际取值为10。`,
  key1004442: `向下浮动：`,
  key1004443: `同步时间：`,
  key1004444: `请选择仓库`,
  key1004445: `添加库存规则`,
  key1004446: `编辑库存规则`,
  key1004447: `个平台`,
  key1004448: `订单超时不能为空`,
  key1004449: `订单同步规则`,
  key1004450: `此规则适用于商品停售时库存自动调0及与上架工具状态同步。`,
  key1004451: `当任一规则中勾选此项后，商品停售状态将同步至上架工具中。`,
  key1004452: `添加商品状态同步规则`,
  key1004453: `修改商品状态同步规则`,
  key1004454: `变动类型`,
  key1004455: `采购到货`,
  key1004456: `分配库存`,
  key1004457: `库存盘点`,
  key1004458: `变动时间`,
  key1004459: `Temu店铺：`,
  key1004460: `上传状态`,
  key1004461: `已上传`,
  key1004462: `未上传`,
  key1004463: `重新上传`,
  key1004464: `上传文件格式有误，请上传条码PDF文件或PNG图片`,
  key1004465: `刊登店铺`,
  key1004466: `刊登状态：`,
  key1004467: `刊登平台：`,
  key1004468: `已刊登`,
  key1004469: `未刊登`,
  key1004470: `刊登数量（item）`,
  key1004471: `刊登状态`,
  key1004472: `收藏状态：`,
  key1004473: `收藏时间：`,
  key1004474: `售卖资料：`,
  key1004475: `库存：`,
  key1004476: `请输入整数`,
  key1004477: `导出商品资料`,
  key1004478: `生成售卖资料提示`,
  key1004479: `批量生成售卖资料`,
  key1004480: `批量导出商品资料`,
  key1004481: `忽略已生成`,
  key1004482: `生成`,
  key1004483: `个SKU待上传\n`,
  key1004484: `勾选的`,
  key1004485: `条商品中有`,
  key1004486: `条已生成售卖记录，请选择是否重新生成?`,
  key1004487: `库存（件）`,
  key1004488: `享`,
  key1004489: `折`,
  key1004490: `减`,
  key1004491: `件以上`,
  key1004492: `折扣价：`,
  key1004493: `优惠：`,
  key1004494: `下一阶段优惠：`,
  key1004495: `近30天内销量`,
  key1004496: `上架时间`,
  key1004497: `收藏时间`,
  key1004498: `售卖资料`,
  key1004499: `生成售卖时间`,
  key1004500: `生成售卖资料`,
  key1004501: `取消收藏`,
  key1004502: `重新刊登`,
  key1004503: `商品SPU`,
  key1004504: `请输入商品SPU,支持多个按行分隔`,
  key1004505: `商品SKU`,
  key1004506: `请输入商品SKU,支持多个按行分隔`,
  key1004507: `请输入商品标题,支持多个按行分隔`,
  key1004508: `请输入供应商名称,支持多个按行分隔`,
  key1004509: `请输入供应商编号,支持多个按行分隔`,
  key1004510: `商品基本信息`,
  key1004511: `商品基本信息表`,
  key1004512: `商品图片`,
  key1004513: `轮播图`,
  key1004514: `属性图`,
  key1004515: `未生成`,
  key1004516: `已生成`,
  key1004517: `超出单次下载数量`,
  key1004518: `一次最多可下载10个商品，请重新选择后下载。`,
  key1004519: `Temu标签`,
  key1004520: `样品条码：`,
  key1004521: `商品条码：`,
  key1004522: `店铺：未上传\n`,
  key1004523: `店铺：已上传\n`,
  key1004524: `请输入商品SPU，支持多个按行分隔`,
  key1004525: `商品标题：`,
  key1004526: `发起生成时间：`,
  key1004527: `发起人：`,
  key1004528: `剩余`,
  key1004529: `条预计`,
  key1004530: `分钟后生成完毕`,
  key1004531: `批量停止生成售卖资料`,
  key1004532: `生成中`,
  key1004533: `生成成功`,
  key1004534: `生成失败`,
  key1004535: `生成状态`,
  key1004536: `待生成`,
  key1004537: `发起生成售卖时间`,
  key1004538: `失败原因`,
  key1004539: `发起人`,
  key1004540: `停止生成`,
  key1004541: `只有待生成的状态可以停止生成售卖资料`,
  key1004542: `请先勾选要停止生成的数据！`,
  key1004543: `停止生成售卖资料确认`,
  key1004544: `正在操作停止生成售卖资料，确认后将取消所有未生成的任务，如需重新生成需重新在收藏的商品中进行操作，请确认是否停止生成？`,
  key1004545: `库存优先级配置`,
  key1004546: `使用库存优先级`,
  key1004547: `是否等待在途库存`,
  key1004548: `优先使用本人货权的库存`,
  key1004549: `优先使用其他在YMS仓的库存`,
  key1004550: `等待`,
  key1004551: `不等待`,
  key1004552: `SKU库存区间：`,
  key1004553: `导出库存`,
  key1004554: `7日：`,
  key1004555: `14日：`,
  key1004556: `最近销量`,
  key1004557: `我的可用库存`,
  key1004558: `我的在途库存`,
  key1004559: `平均货品成本`,
  key1004560: `SKU库存开始区间不能大于SKU库存结束区间`,
  key1004561: `选择时长`,
  key1004562: `店铺时长`,
  key1004563: `价格CNY`,
  key1004564: `1个月`,
  key1004565: `3个月`,
  key1004566: `6个月`,
  key1004567: `12个月`,
  key1004568: `开通店铺`,
  key1004569: `续费确认`,
  key1004570: `确认续费`,
  key1004571: `正在充值中...`,
  key1004572: `开通成功`,
  key1004573: `续费成功`,
  key1004574: `开通失败`,
  key1004575: `续费失败`,
  key1004576: `请输入代号、账户名`,
  key1004577: `未添加店铺时，如果需要eDIS发货，请先在eDIS绑定eBayID后再添加店铺。`,
  key1004578: `已添加店铺时，如果需要eDIS发货，请在eDIS绑定eBayID后再重新授权店铺。`,
  key1004579: `授权状态`,
  key1004580: `前往授权`,
  key1004581: `首次授权时间`,
  key1004582: `授权更新时间`,
  key1004583: `通途状态`,
  key1004584: `到期时间`,
  key1004585: `永久有效`,
  key1004586: `未开通`,
  key1004587: `已过期`,
  key1004588: `开通`,
  key1004589: `续费`,
  key1004590: `授权成功！`,
  key1004591: `添加账号成功！`,
  key1004592: `角色描述：`,
  key1004593: `请输入角色描述`,
  key1004594: `权限分配`,
  key1004595: `新增角色`,
  key1004596: `查看角色`,
  key1004597: `添加角色`,
  key1004598: `角色名称`,
  key1004599: `角色描述`,
  key1004600: `用户数量（已启用）`,
  key1004601: `角色下已关联用户时，不能删除角色。`,
  key1004602: `确认要删除该角色？`,
  key1004603: `删除该角色后，将不可恢复，你确认要删除？`,
  key1004604: `删除角色成功！`,
  key1004605: `查看用户`,
  key1004606: `账号类型`,
  key1004607: `主账号`,
  key1004608: `子账号`,
  key1004609: `账号角色`,
  key1004610: `请输入邮箱地址`,
  key1004611: `生成密码`,
  key1004612: `店铺权限`,
  key1004613: `添加用户`,
  key1004614: `编辑用户`,
  key1004615: `新增成功，上架工具用户角色授权更新失败！`,
  key1004616: `请输入手机号码、邮箱或姓名`,
  key1004617: `修改密码`,
  key1004618: `修改密码后，原密码将不可使用，请谨慎修改！`,
  key1004619: `请输入供应商名称`,
  key1004620: `粉丝数：`,
  key1004621: `进入店铺`,
  key1004622: `是否确定取消关注？`,
  key1004623: `货品费用退费`,
  key1004624: `包裹处理退费`,
  key1004625: `物流退费`,
  key1004626: `国内快递退费`,
  key1004627: `货品费用`,
  key1004628: `活动说明`,
  key1004629: `您当前可用余额为`,
  key1004630: `继续支付`,
  key1004631: `支付成功，后台入账中`,
  key1004632: `,第三方凭证号:`,
  key1004633: `导出费用明细`,
  key1004634: `扣款`,
  key1004635: `退款`,
  key1004636: `平台订单币种`,
  key1004637: `商品数量(件)`,
  key1004638: `物流重量`,
  key1004639: `sku销售价`,
  key1004640: `sku优惠价`,
  key1004641: `请输入平台订单号`,
  key1004642: `余额充值`,
  key1004643: `充值金额：`,
  key1004644: `支付方式：`,
  key1004645: `其他金额`,
  key1004646: `当前汇率：1`,
  key1004647: `账户货币为`,
  key1004648: `充值金额不能超过100000000`,
  key1004649: `充值金额不能小于等于0`,
  key1004650: `（已取消）`,
  key1004651: `备货至 `,
  key1004652: `取消金额：`,
  key1004653: `退款金额：`,
  key1004654: `订单商品`,
  key1004655: `商品单价`,
  key1004656: `实付单价`,
  key1004657: `下单数量`,
  key1004658: `到仓数量`,
  key1004659: `部分货品未到仓，未到仓部分已退款。`,
  key1004660: `用户取消货品数量`,
  key1004661: `商品总重量(g)`,
  key1004662: `货品总值`,
  key1004663: `已付款`,
  key1004664: `供应商已发货`,
  key1004665: `待付款`,
  key1004666: `待备货`,
  key1004667: `未完结`,
  key1004668: `请选择Temu店铺`,
  key1004669: `请填写Temu订单号`,
  key1004670: `你还未添加商品`,
  key1004671: `处理费=3+(商品数量-1)*0.5`,
  key1004672: `应付总额会在提交订单时进行预扣，请确保账户有足够的余额。`,
  key1004673: `收起地址`,
  key1004674: `更多地址`,
  key1004675: `发货订单`,
  key1004676: `寄样订单`,
  key1004677: `删除商品`,
  key1004678: `请注意：`,
  key1004679: `1.寄样订单，若一个SPU下需发多个SKU，请务必将SKU置于一个订单内进行下单。`,
  key1004680: `2.发货订单，请务必按照TEMU后台备货单进行下单，不可将多张备货单置于同一YMS订单下。`,
  key1004681: `商品数量：`,
  key1004682: `商品总重量(g)：`,
  key1004683: `商品总金额：`,
  key1004684: `收货地址`,
  key1004685: `设为默认地址`,
  key1004686: `新增收货地址`,
  key1004687: `应付总额：`,
  key1004688: `数量（件）`,
  key1004689: `小计`,
  key1004690: `Temu店铺不能为空`,
  key1004691: `Temu订单号不能为空`,
  key1004692: `请先勾选要移除的商品！`,
  key1004693: `编辑收货地址`,
  key1004694: `是否要删除当前收货地址？`,
  key1004695: `取消提示`,
  key1004696: `取消后将不会保存已填写的内容，你确定要取消？`,
  key1004697: `继续填写`,
  key1004698: `订单信息填写不完整，请填写完订单信息再提交!`,
  key1004699: `账户余额不足`,
  key1004700: `你的账户余额不足，请充值足够的金额后，再提交订单！`,
  key1004701: `去充值`,
  key1004702: `配送方式`,
  key1004703: `运费：`,
  key1004704: `修改配送方式`,
  key1004705: `选择配送方式`,
  key1004706: `使用IOSS：`,
  key1004707: `前往配置`,
  key1004708: `物流代缴时将产生额外费用，YMS将代为收取。`,
  key1004709: `本单将额外收取`,
  key1004710: `请先选择物流`,
  key1004711: `仓库存`,
  key1004712: `当前的收货地址不支持配送！`,
  key1004713: `请选择使用的IOSS！`,
  key1004714: `请输入IOSS编号！`,
  key1004715: `请输入IM+10位数字！`,
  key1004716: `分销商余额不足`,
  key1004717: `录入IOSS`,
  key1004718: `匹配物流：`,
  key1004719: `更换物流后，清关费用将重新计算，原先收取的费用将退回本单将收取`,
  key1004720: `清关费用`,
  key1004721: `匹配物流`,
  key1004722: `指定仓库物流方式`,
  key1004723: `修改物流地址`,
  key1004724: `买家修改地址，修改前后信息如下，请确认是否修改阿里国际订单地址？`,
  key1004725: `原地址：`,
  key1004726: `收件人姓名`,
  key1004727: `联系方式`,
  key1004728: `新地址：`,
  key1004729: `失败原因：`,
  key1004730: `地址更新失败提示`,
  key1004731: `标记已处理`,
  key1004732: `重试`,
  key1004733: `物流渠道名称`,
  key1004734: `请先勾选要配送方式的数据！`,
  key1004735: `不在重量区间之内！`,
  key1004736: `订单原因，此原因仅`,
  key1004737: `可见。`,
  key1004738: `取消范围：`,
  key1004739: `取消商品：`,
  key1004740: `请注意，部分取消后此订单将被作废，将会生成新的YMS订单!`,
  key1004741: `当前`,
  key1004742: `订单中暂无商品`,
  key1004743: `取消订单后，订单不再发货，已装箱订单取消需收取手续费，取消后本单将收取`,
  key1004744: `费用，确定取消订单?`,
  key1004745: `请选择取消范围`,
  key1004746: `整单取消`,
  key1004747: `取消部分商品`,
  key1004748: `取消部分商品时，取消数量不能全部为空！`,
  key1004749: `取消部分商品时，取消总数量不能大于等于订单总数量！`,
  key1004750: `确认取消订单`,
  key1004751: `订单轨迹`,
  key1004752: `揽收中，正送往ymsWarehouseName仓`,
  key1004753: ` 订单轨迹`,
  key1004754: `订单已确认，待商家拣货`,
  key1004755: `订单已拣货完成，待商家复核`,
  key1004756: `订单已复核完成，待商家打包`,
  key1004757: `快递运输中，正送往ymsWarehouseName,快递单号：expressageNo`,
  key1004758: `ymsWarehouseName已收货，待仓库处理`,
  key1004759: `ymsWarehouseName配货中`,
  key1004760: `货品拣货完成，待仓库包装`,
  key1004761: `货品包装完成，待仓库装箱`,
  key1004762: `仓库已发货，已通知shippingMethodName物流取件`,
  key1004763: `订单已出库，需要尾程物流轨迹，请自行到物流官方网站或第三方平台查询`,
  key1004764: `订单已取消`,
  key1004765: `当前配送至：`,
  key1004766: `请输入省/州`,
  key1004767: `请输入城市`,
  key1004768: `请输入区县`,
  key1004769: `详细地址：`,
  key1004770: `请输入详细地址信息，如：道路、门牌号、小区、楼栋号、单元等信息`,
  key1004771: `请输入邮政编码`,
  key1004772: `收货人姓名：`,
  key1004773: `长度不超过25个字符`,
  key1004774: `请输入固定电话号码`,
  key1004775: `邮箱地址：`,
  key1004776: `设置为默认收货地址`,
  key1004777: `省/州不能为空`,
  key1004778: `收货人姓名不能为空`,
  key1004779: `添加成功！`,
  key1004780: `取消原因：`,
  key1004781: `取消类型：`,
  key1004782: `请选择平台订单取消原因，此原因买家可见。`,
  key1004783: `取消平台订单时，系统将自动调用平台接口取消订单,并同时给买家退款!`,
  key1004784: `已装箱订单取消需收取手续费，取消后本单将收取`,
  key1004785: `费用`,
  key1004786: `请选择取消类型`,
  key1004787: `请选择取消原因`,
  key1004788: `取消平台订单`,
  key1004789: `平台订单交易号`,
  key1004790: `取消部分商品时，取消商品不能全部勾选！`,
  key1004791: `请先勾选需要取消的数据！`,
  key1004792: `当前平台订单中暂无商品`,
  key1004793: `买家提供地址存在问题`,
  key1004794: `应买家要求取消订单`,
  key1004795: `买家未付款`,
  key1004796: `商品缺货或存在其他履约问题`,
  key1004797: `买家不再想要该商品`,
  key1004798: `买家买错商品`,
  key1004799: `买卖双方对此商品存在争议`,
  key1004800: `商品缺货`,
  key1004801: `仅剩次品`,
  key1004802: `仲裁取消`,
  key1004803: `其他原因`,
  key1004804: `买家未领取订单`,
  key1004805: `无法送达该地区`,
  key1004806: `订单被运输途中丢失`,
  key1004807: `客户要求取消`,
  key1004808: `定价错误`,
  key1004809: `欺诈交易，取消订单`,
  key1004810: `买家地址不可用`,
  key1004811: `无法送达`,
  key1004812: `物品已损坏`,
  key1004813: `物品退回发件人`,
  key1004814: `付款`,
  key1004815: `由于此订单由阿里国际多商家履约，已自动拆分为不同订单，需按订单进行支付。`,
  key1004816: `付款状态`,
  key1004817: `去付款`,
  key1004818: `更新状态`,
  key1004819: `付款确认`,
  key1004820: `是否付款成功？`,
  key1004821: `状态未更新提示`,
  key1004822: `获取到阿里国际订单信息状态仍为未付款，请稍后重试或手动标记为已成功。`,
  key1004823: `手动标记`,
  key1004824: `更换订单商品`,
  key1004825: `确认更换`,
  key1004826: `请选择需更换的商品及数量`,
  key1004827: `请注意，部分取消后此订单将被作废，将会生成新的YMS订单！`,
  key1004828: `需更换数量`,
  key1004829: `需小于订单数量`,
  key1004830: `原订单内SKU信息`,
  key1004831: `替换SKU信息`,
  key1004832: `选择商品`,
  key1004833: `替换商品为当前商品`,
  key1004834: `更换商品数量需小于订单数量`,
  key1004835: `未选择更换订单商品`,
  key1004836: `未输入选择商品的替换数量`,
  key1004837: `请选择替换商品`,
  key1004838: `提交中`,
  key1004839: `修改收货人信息`,
  key1004840: `买家姓名：`,
  key1004841: `地址1：`,
  key1004842: `地址2：`,
  key1004843: `州/省：`,
  key1004844: `电话号码：`,
  key1004845: `当前所选目的国家需要录入IOSS相关信息`,
  key1004846: `请注意，修改地址信息后此订单将作废并生成新的订单!`,
  key1004847: `校验中...`,
  key1004848: `地址变更提示`,
  key1004849: `当前物流方式不支持此地区，更换地址后物流方式将被置空，请在待处理状态中重新匹配物流。请确认是否变更地址?`,
  key1004850: `确定变更`,
  key1004851: `修改中...`,
  key1004852: `订单详情`,
  key1004853: `计费重量(`,
  key1004854: `已处理`,
  key1004855: `预计 `,
  key1004856: ` 到货`,
  key1004857: `此时间为供应商仓预计到货时间，距尾程包裹发出时间仍需2~3天。`,
  key1004858: `出库单编号`,
  key1004859: `收货人名称`,
  key1004860: `买家识别号`,
  key1004861: `详细地址1`,
  key1004862: `详细地址2`,
  key1004863: `邮寄方式`,
  key1004864: `物流商称重（g）`,
  key1004865: `物流商运费`,
  key1004866: `待物流揽收`,
  key1004867: `供应商备货中`,
  key1004868: `物流已揽收`,
  key1004869: `平台订单自动创建`,
  key1004870: `取消数量不能为空`,
  key1004871: `由于价格调整待供应商接单暂无法付款`,
  key1004872: `商品已停售`,
  key1004873: `部分取消成功！`,
  key1004874: `添加订单`,
  key1004875: `订单商品信息`,
  key1004876: `订单号,支持多个按行分隔`,
  key1004877: `订单编号：`,
  key1004878: `第三方单号：`,
  key1004879: `国内快递费单号：`,
  key1004880: `需通过快递发至揽收点的包裹将加收国内快递费。具体费用可在自有物流模块中点击对应渠道查看。`,
  key1004881: `目的地及买家信息`,
  key1004882: `目的地：`,
  key1004883: `买家ID：`,
  key1004884: `商品费：`,
  key1004885: `清关费用：`,
  key1004886: `部分缺货，商品已停售`,
  key1004887: `全部 `,
  key1004888: `下发物流失败`,
  key1004889: `请输入第三方单号,支持多个按行分隔`,
  key1004890: `请输入运单号,支持多个按行分隔`,
  key1004891: `待处理`,
  key1004892: `所属平台：`,
  key1004893: `分销订单状态：`,
  key1004894: `所属店铺：`,
  key1004895: `是否有退款：`,
  key1004896: `平台订单异常状态：`,
  key1004897: `分销订单异常状态：`,
  key1004898: `执行发货状态：`,
  key1004899: `平台订单号：`,
  key1004900: `请输入平台订单号，支持多个逗号或换行分隔`,
  key1004901: `可输入平台订单号、分销订单号、包裹号、买家ID、 买家姓名、SKU、平台产品编码`,
  key1004902: `导出平台订单`,
  key1004903: `批量标记非`,
  key1004904: `执行发货`,
  key1004905: `批量接单`,
  key1004906: `标发货`,
  key1004907: `标发货设置`,
  key1004908: `非`,
  key1004909: `执行发货后，`,
  key1004910: `将自动流转Temu订单状态，勾选的订单将作为一个物流包裹发出。`,
  key1004911: `正在确认通过`,
  key1004912: `履约速卖通全托管JIT订单，由于JIT订单限制较多，若出现平台处罚，`,
  key1004913: `将按照售后条款进行处理，无额外赔付，请确认是否接单？`,
  key1004914: ` 平台订单信息`,
  key1004915: `平台订单商品信息`,
  key1004916: `是否标发货：`,
  key1004917: `下单数量：`,
  key1004918: `买家备注：`,
  key1004919: `平台订单异常`,
  key1004920: `分销订单信息`,
  key1004921: `阿里国际站订单`,
  key1004922: `发货物流：`,
  key1004923: `分销订单商品信息`,
  key1004924: `分销订单状态`,
  key1004925: `分销订单金额`,
  key1004926: `分销订单异常`,
  key1004927: `缺货，商品已停售`,
  key1004928: `匹配商品`,
  key1004929: `处理订单`,
  key1004930: `确认等待`,
  key1004931: `接单`,
  key1004932: `修改物流`,
  key1004933: `集货中`,
  key1004934: `待执行发货`,
  key1004935: `已执行发货`,
  key1004936: `已标发货`,
  key1004937: `平台订单已取消`,
  key1004938: `待匹配商品`,
  key1004939: `匹配物流失败`,
  key1004940: `所属平台`,
  key1004941: `店铺名称`,
  key1004942: `平台订单标发货状态`,
  key1004943: `订单原始SKU*数量`,
  key1004944: `平台订单是否有退款`,
  key1004945: `平台订单异常状态`,
  key1004946: `买家ID`,
  key1004947: `买家姓名`,
  key1004948: `买家备注`,
  key1004949: `分销订单号`,
  key1004950: `分销订单SKU信息`,
  key1004951: `分销订单创建时间`,
  key1004952: `分销订单发货时间`,
  key1004953: `分销订单发货时效`,
  key1004954: `待接单`,
  key1004955: `待匹配物流`,
  key1004956: `标发货失败`,
  key1004957: `待入录IOSS`,
  key1004958: `客户更换地址`,
  key1004959: `客户标记取消`,
  key1004960: `请先勾选要标发货的数据！`,
  key1004961: `标记发货中，请稍后刷新页面查看。`,
  key1004962: `请先勾选要下单的数据！`,
  key1004963: `部分下单异常`,
  key1004964: `不同店铺的订单不可同时执行发货！`,
  key1004965: `仅待执行发货的订单可执行发货，请重新选择！`,
  key1004966: `执行发货成功!`,
  key1004967: `手工订单和平台订单不可同时执行发货，请分批操作！`,
  key1004968: `样品订单与发货订单不可同时执行发货，请分批操作！`,
  key1004969: `请先勾选要执行发货的数据！`,
  key1004970: `确认等待订单`,
  key1004971: `正在操作确认等待订单，若订单未按时间到达，仍会进行二次提醒，请确认是否等待订单到货？`,
  key1004972: `手动标记发货`,
  key1004973: `系统向平台标记发货失败，请前往平台后台进行标记发货，在平台后台标记发货后可在此处点击确认已标记发货按钮将此订单异常状态消除。`,
  key1004974: `确认已标记发货`,
  key1004975: `只有待接单的订单才能进行接单！`,
  key1004976: `请先勾选需要接单的数据！`,
  key1004977: `确认接单`,
  key1004978: `接单成功！`,
  key1004979: `部分接单异常`,
  key1004980: `样品订单`,
  key1004981: `货品订单`,
  key1004982: `常规订单`,
  key1004983: `平台订单已取消，请自行前往阿里国际中取消订单，取消后请手动标记已处理。`,
  key1004984: `可搜索商品名称、`,
  key1004985: `关键词搜索：`,
  key1004986: `导出备货单`,
  key1004987: `备货单信息`,
  key1004988: `采购状态：`,
  key1004989: `备货仓：`,
  key1004990: `采购商品信息`,
  key1004991: `上架数量：`,
  key1004992: `取消数量：`,
  key1004993: `退款数量：`,
  key1004994: `货品总值：`,
  key1004995: `时间信息`,
  key1004996: `取消时间：`,
  key1004997: `部分停售`,
  key1004998: `取消交易`,
  key1004999: `缺货-补货中`,
  key1005000: `缺货-已停售`,
  key1005001: `采购状态`,
  key1005002: `备货仓`,
  key1005003: `已取消金额`,
  key1005004: `已退款金额`,
  key1005005: `SKU单价`,
  key1005006: `SKU实付单价`,
  key1005007: `采购单付款确认`,
  key1005008: `正在操作采购单付款。请确认是否付款？`,
  key1005009: `确认付款`,
  key1005010: `取消交易提示`,
  key1005011: `正在操作取消备货单，请确认是否取消？`,
  key1005012: `取消交易成功！`,
  key1005013: `条码缺失提示`,
  key1005014: `以下商品无`,
  key1005015: `信息，请在`,
  key1005016: `我的商品`,
  key1005017: `中上传后重试。`,
  key1005018: `商品条码`,
  key1005019: `样品条码`,
  key1005020: `到仓详情`,
  key1005021: `匹配SKU成功`,
  key1005022: `匹配SKU成功(人工匹配)`,
  key1005023: `不匹配`,
  key1005024: `阿里国际单号：`,
  key1005025: `分销单号：`,
  key1005026: `展开`,
  key1005027: `原始SKU：`,
  key1005028: `物流服务：`,
  key1005029: `价格及数量`,
  key1005030: `已解码SKU：`,
  key1005031: `匹配SKU失败`,
  key1005032: `手动匹配`,
  key1005033: `重新匹配`,
  key1005034: `SKU已停售，请取消或更换商品`,
  key1005035: `商品为空，无法关联`,
  key1005036: `总费用=商品+处理费+物流费`,
  key1005037: `处理费=3+（商品数量-1）*0.5`,
  key1005038: `总费用(`,
  key1005039: `商品费(`,
  key1005040: `处理费(`,
  key1005041: `物流费(`,
  key1005042: `国内快递费(`,
  key1005043: `清关费用(`,
  key1005044: `阿里国际订单信息`,
  key1005045: `收货地址信息`,
  key1005046: `平台订单地址信息`,
  key1005047: `暂无更多数据...`,
  key1005048: `国内快递单号`,
  key1005049: `下载订单`,
  key1005050: `已标记发货`,
  key1005051: `待标记发货`,
  key1005052: `订单已整单取消`,
  key1005053: `订单已部分取消`,
  key1005054: `阿里国际商家已发货`,
  key1005055: `，预计到货时间：`,
  key1005056: `，已停售`,
  key1005057: `备货仓库`,
  key1005058: `YMS仓库：`,
  key1005059: `下单后自动付款`,
  key1005060: `请选择备货仓库!`,
  key1005061: `配置方式：`,
  key1005062: `全局统一配置`,
  key1005063: `按平台配置`,
  key1005064: `可选择以下任一YMS订单节点作为标发货节点：`,
  key1005065: `物流：`,
  key1005066: `此信息需与买手确认，未填写时将不在包裹上额外粘贴收货信息`,
  key1005067: `收货人：`,
  key1005068: `请填写收货人`,
  key1005069: `收货组：`,
  key1005070: `请填写收货组`,
  key1005071: `运费将附加在执行发货的首个订单中`,
  key1005072: `第一步：选择物流`,
  key1005073: `第二步：填写收件信息`,
  key1005074: `物流不能为空`,
  key1005075: `请填写物流单号`,
  key1005076: `第一步：上传打包标签`,
  key1005077: `第二步：填写物流单号`,
  key1005078: `包裹编号`,
  key1005079: `包裹重量（g）`,
  key1005080: `包裹货品总数`,
  key1005081: `上传打包标签`,
  key1005082: `无需上传标签`,
  key1005083: `请先上传打包标签!`,
  key1005084: `物流单号不能为空!`,
  key1005085: `派送范围：`,
  key1005086: `帐号名称不能为空`,
  key1005087: `账户授权`,
  key1005088: `帐号名称：`,
  key1005089: `关联店铺`,
  key1005090: `平台店铺：`,
  key1005091: `未启用`,
  key1005092: `正在启用`,
  key1005093: `账户，请确认后操作。`,
  key1005094: `停用该`,
  key1005095: `账户后，相关联的店铺将无法下发物流，请确认是否停用账户？`,
  key1005096: `物流渠道代码`,
  key1005097: `物流类型`,
  key1005098: `停用物流提示`,
  key1005099: `停用该物流后，将无法适用该物流进行下发，请确认是否停用？`,
  key1005100: `启用物流提示`,
  key1005101: `正在启用物流，请确认后操作。`,
  key1005102: `启用渠道提示`,
  key1005103: `正在启用物流渠道，请确认后操作。`,
  key1005104: `启用账户提示`,
  key1005105: `停用渠道提示`,
  key1005106: `停用该物流渠道后，将无法适用该物流渠道进行下发，请确认是否停用？`,
  key1005107: `停用账户提示`,
  key1005108: `帐号`,
  key1005109: `请您按照以下要求上传营业执照，如您没有按要求上传，您的申请可能会被拒绝`,
  key1005110: `请确保营业执照图片字体清晰，边角完整，无任何无关水印，不可故意遮盖二维码或信息。若拍摄模糊建议上传扫描件。`,
  key1005111: `请您先自行在国家企业信用信息公示系统查询您填写的统一社会信用代码/注册号，确保能查询到您的主体信息，且登记状态为“存续（在营、开业、在册）”。`,
  key1005112: `若公司名称或法定代表人等信息发生过变更，请上传最新核准日期的营业执照图片。`,
  key1005113: `示例图`,
  key1005114: `请您按照以下要求上传，不符合以下规范将会被审核驳回`,
  key1005115: `请确保您所上传法定代表人身份证上的姓名与营业执照上的法定代表人姓名一致。`,
  key1005116: `请确认法定代表人身份证字体清晰无反光、边角完整，无任何无关水印及故意遮盖。`,
  key1005117: `YMS平台隐私政策`,
  key1005118: `请填写营业执照上公司全称`,
  key1005119: `请选择[jpg、jpeg、png]`,
  key1005120: `公司名称不能为空`,
  key1005121: `法人身份证国徽面`,
  key1005122: `法人身份证人像面`,
  key1005123: `请先上传法人身份证国徽面`,
  key1005124: `请先上传法人身份证人像面`,
  key1005125: `法定代表人身份证照规范`,
  key1005126: `营业执照规范`,
  key1005127: `图形验证码：`,
  key1005128: `请输入短信验证码`,
  key1005129: `设置密码：`,
  key1005130: `8~26位（建议使用两种或两种以上字符组合）`,
  key1005131: `确认密码：`,
  key1005132: `请再次输入密码`,
  key1005133: `此手机号已注册分销商，继续则视为以此账户入驻供应商`,
  key1005134: `我已阅读并同意`,
  key1005135: `密码长度必须为8~26位`,
  key1005136: `请先填写手机号和图形验证码！`,
  key1005137: `账号注册成功`,
  key1005138: `你的账号已注册成功，请继续完善入驻信息，完成入驻！`,
  key1005139: `请先勾选我已阅读并同意`,
  key1005140: `设置密码不能为空`,
  key1005141: `设置密码与确认密码不一致`,
  key1005142: `填写账号信息`,
  key1005143: `填写企业信息`,
  key1005144: `入驻资料审核`,
  key1005145: `注册成功`,
  key1005146: `已有账号？`,
  key1005147: `立即登录`,
  key1005148: `提交成功`,
  key1005149: `你的申请材料已提交，通常我们会在3个工作日`,
  key1005150: `内完成审核，请耐心等待，如你有任何疑问，请`,
  key1005151: `联系在线客服！`,
  key1005152: `入驻信息审核中，请耐心等待~`,
  key1005153: `审核通过后，即可正常使用供应商后台，如你有`,
  key1005154: `任何疑问，请联系在线客服！`,
  key1005155: `你的申请，审核未通过`,
  key1005156: `请核对并修改以下信息后，再重新提交。`,
  key1005157: `重新修改`,
  key1005158: `一切就绪`,
  key1005159: `你的申请，已经审核通过！`,
  key1005160: `去发布商品`,
  key1005161: `正在退出`,
  key1005162: `账号登录`,
  key1005163: `手机号码/邮箱/通途账号`,
  key1005164: `密码`,
  key1005165: `验证码`,
  key1005166: `短信登录`,
  key1005167: `短信验证码`,
  key1005168: `立即授权`,
  key1005169: `找回密码`,
  key1005170: `手机号码/邮箱/通途账号不能为空`,
  key1005171: `短信验证码不能为空`,
  key1005172: `手机号/邮箱格式不正确`,
  key1005173: `手机号注册`,
  key1005174: `邮箱注册`,
  key1005175: `邮箱（可不填）`,
  key1005176: `通途登录`,
  key1005177: `欢迎注册`,
  key1005178: `注册`,
  key1005179: `已有账号，请登录`,
  key1005180: `通途账号登录`,
  key1005181: `如果您已有通途账号，可直接使用通途账号登录。`,
  key1005182: `登录将为您开通YMS商城分销权限。`,
  key1005183: `注册成功！`,
  key1005184: `设置密码`,
  key1005185: `请输入邮箱 / 已验证手机号`,
  key1005186: `去登录`,
  key1005187: `手机号/邮箱不能为空`,
  key1005188: `确认密码不能为空`,
  key1005189: `提 交`,
  key1005190: `首页`,
  key1005191: `供应商入驻指南`,
  key1005192: `立即入驻`,
  key1005193: `欢迎加入YMS分销平台`,
  key1005194: `YMS是中国领先的跨境服装一站式分销平台，创始团队成员深耕跨境行业十多年，有着丰富且优质的跨境电商供应链资源，`,
  key1005195: `可提供集货、仓储、物流、分销等一体化服务，可帮助源头工厂、品牌代理商、工贸一体企业等类型供应商拓展海外销路，`,
  key1005196: `秉承着“合作共赢、共同发展”的经营理念，现诚邀优质跨境电商货源供应商加入，助力您的海外市场。`,
  key1005197: `我们的优势`,
  key1005198: `招商品类`,
  key1005199: `供应商资质要求`,
  key1005200: `企业资料`,
  key1005201: `入驻材料`,
  key1005202: `材料要求`,
  key1005203: `三证合一的企业营业执照；`,
  key1005204: `确保申请入驻的企业不在《经营异常名录》中，且所销售的`,
  key1005205: `商品在其经营范围内；`,
  key1005206: `距离有效期截止时间应大于3个月。`,
  key1005207: `身份证`,
  key1005208: `法人身份证；`,
  key1005209: `距离有效期截止时间应大于1个月。`,
  key1005210: `支持银行卡或支付宝。`,
  key1005211: `产品要求`,
  key1005212: `产品资质`,
  key1005213: `商品合法合规`,
  key1005214: `承诺提供的商品合法合规，符合销售目的地的法律法规、质`,
  key1005215: `量标准及YMS上架规范；`,
  key1005216: `专利产品，需提供销售区域专利证书；`,
  key1005217: `有品牌的供应商，需提供品牌授权书；`,
  key1005218: `具备稳定的供货渠道，愿意遵循平台规则。`,
  key1005219: `真诚长远合作`,
  key1005220: `保证商品价格全网最低；`,
  key1005221: `认同YMS发展理念，抱有真诚长远的合作态度。`,
  key1005222: `订单履约`,
  key1005223: `保证商品在发货期内到达YMS仓库。`,
  key1005224: `入驻流程`,
  key1005225: `即刻入驻YMS供应商，即享快速代发新模式`,
  key1005226: `YMS诚邀你加入我们，成为我们的优质伙伴，赶快点击入驻！`,
  key1005227: `专`,
  key1005228: `专注跨境电商`,
  key1005229: `10余年专业跨境电商服装产品团队`,
  key1005230: `广`,
  key1005231: `覆盖主流电商平台`,
  key1005232: `分销店铺覆盖Amazon、eBay等主流电商平台`,
  key1005233: `集`,
  key1005234: `订单集中处理`,
  key1005235: `供应商只需发货至YMS集运仓库`,
  key1005236: `免`,
  key1005237: `免仓储服务费`,
  key1005238: `入驻即获得仓储免费服务`,
  key1005239: `快速代发`,
  key1005240: `一站式代发系统托管，节省仓储精力，控制人工成本`,
  key1005241: `女 装`,
  key1005242: `男 装`,
  key1005243: `女 童`,
  key1005244: `男 童`,
  key1005245: `婴 童`,
  key1005246: `产品调研`,
  key1005247: `YMS协助进行产品调研，确定产品符合市场需求，且具备价格竞争优势。`,
  key1005248: `注册账号`,
  key1005249: `按要求填写账号信息，注册企业账号。`,
  key1005250: `填写入驻资料`,
  key1005251: `提交营业执照、法定代表人身份证，填写店铺相关信息。`,
  key1005252: `入驻资料提交后，YMS将在3个工作日内完成审核。`,
  key1005253: `入驻完成`,
  key1005254: `审核通过后，供应商登录后台，发布商品开始销售。`,
  key1005255: `请描述你的问题，如：物流`,
  key1005256: `视频教程`,
  key1005257: `云卖供应链`,
  key1005258: `功能介绍`,
  key1005259: `云卖供应链功能介绍`,
  key1005260: `注册云卖供应链`,
  key1005261: `店铺授权`,
  key1005262: `自助服务`,
  key1005263: `定期更换密码有助于保障账户安全`,
  key1005264: `修改手机号码`,
  key1005265: `手机号涉及登录、认证等关键业务，保障账户安全`,
  key1005266: `邮箱涉及登录、消息通知等关键业务，并保障账户安全`,
  key1005267: `搜索“`,
  key1005268: `”的相关结果，共`,
  key1005269: `无匹配搜索结果`,
  key1005270: `帮助首页`,
  key1005271: `登录`,
  key1005272: `登录查看价格`,
  key1005273: `每日上新`,
  key1005274: `潮流女装`,
  key1005275: `时尚男装`,
  key1005276: `精品童装`,
  key1005277: `HOT 热销商品`,
  key1005278: `好货推荐`,
  key1005279: `YMS商城（www.yms.com）是深圳云卖供应链管理有限公司建立的一个专注跨境电商服装品类的供应链服务平台。平台集成源头工厂服装商城、通途Listing刊登平台、通途ERP平台和通途物流平台，为跨境电商卖家与源头工厂打通了一条高效的订单履约链路，国外销售终端的顾客订单直接由源头工厂一件代发。`,
  key1005280: `只需3步，让跨境生意更简单`,
  key1005281: `你只需专注于运营，其他的，我们来帮你解决`,
  key1005282: `选品`,
  key1005283: `免费注册账号，在YMS商城订阅商品`,
  key1005284: `刊登`,
  key1005285: `直连通途Listing刊登系统，一键快速上架到eBay、Amazon等多平台店铺`,
  key1005286: `店铺出单后，即可在YMS商城下单，支持一件代发`,
  key1005287: `YMS商城跨境电商，专注于服装分销`,
  key1005288: `现在你可以把成千上万的服装商品从YMS商城刊登到海外各大平台，从而赢得更多的销售机会`,
  key1005289: `海量商品选购`,
  key1005290: `40000+服装SKU，1000+每月上新`,
  key1005291: `0库存风险`,
  key1005292: `无需国货备货，0库存风险`,
  key1005293: `无资金压力`,
  key1005294: `先出单后付款，提升资金周转率`,
  key1005295: `你只需专注于商品销售，就算只卖出一件商品，我们也会贴心地帮你发货`,
  key1005296: `快速上架到多平台`,
  key1005297: `自有品牌标识，让客户更信赖`,
  key1005298: `可以通过包裹、包装标识自有品牌，让客户更信赖`,
  key1005299: `订单自动跟踪`,
  key1005300: `销售订单系统自动跟踪，无需复制和粘贴客户订单详细信息`,
  key1005301: `发货快，出货时效有保障`,
  key1005302: `开放API接口`,
  key1005303: `YMS商城提供开放接口，可实现商品管理、订单管理、仓储管理等诸多功能`,
  key1005304: `分销商业务`,
  key1005305: `1）服务内容：为跨境电商服装卖家的eBay、Walmart、速卖通、Wish、Shopify等平台订单提供源头工厂现货一件代发服务。`,
  key1005306: `2）服务流程：`,
  key1005307: `注册成为YMS商城分销商`,
  key1005308: `方案1：通过通途ERP账户（http:`,
  key1005309: `方案2：直接手机号注册开通。`,
  key1005310: `刊登销售`,
  key1005311: `在YMS商城选品收藏商品，通途Listing系统快速刊登到eBay、Walmart、速卖通等平台销售。`,
  key1005312: `订单发货`,
  key1005313: `通途ERP抓取平台订单，匹配分销商设定的国际物流方式，下发至YMS商城。订单即可履约一件代发给国外顾客。`,
  key1005314: `供应商业务`,
  key1005315: `1）服务内容：跨境电商服装品类的工厂入驻YMS商城开设直营店铺。`,
  key1005316: `服装工厂联系供应商招商经理注册进驻。`,
  key1005317: `经营店铺，规范上架商品，管理库存，操作订单发货。`,
  key1005318: `中心仓仓储业务`,
  key1005319: `1）服务内容：为YMS商城供应商提供仓储服务。`,
  key1005320: `YMS商城供应商开通云仓。`,
  key1005321: `中心仓备货和发货，供应商创建备货计划，贴YMS商城商品条码入仓，中心仓自动匹配订单发货。`,
  key1005322: `平台业务介绍`,
  key1005323: `联系地址：深圳市南山区学苑大道南山智园C1栋6楼`,
  key1005324: `联系电话：400-0600-405`,
  key1005325: `分销商在线服务QQ：2853913746`,
  key1005326: `达到上一销量后可查看`,
  key1005327: `地址：`,
  key1005328: `当前位置：`,
  key1005329: `上架时间：`,
  key1005330: `优惠`,
  key1005331: `30天内累计分销`,
  key1005332: `收藏`,
  key1005333: `一键刊登支持以下平台`,
  key1005334: `优质供应商`,
  key1005335: `价格：登录后可见`,
  key1005336: `商品详细信息`,
  key1005337: `物品参数`,
  key1005338: `供应商建议`,
  key1005339: `暂无权限查看，请联系客服开通权限。`,
  key1005340: `关注店铺`,
  key1005341: `包装尺寸(cm)`,
  key1005342: `美西仓库存`,
  key1005343: `取消收藏成功`,
  key1005344: `★ 已关注`,
  key1005345: `入门视频`,
  key1005346: `新用户入门操作`,
  key1005347: `新用户注册`,
  key1005348: `授权店铺`,
  key1005349: `一键刊登流程`,
  key1005350: `一键刊登成功前提：成功授权店铺`,
  key1005351: `设置店铺刊登模板`,
  key1005352: `一键刊登规则`,
  key1005353: `第一步：刊登模板设置`,
  key1005354: `刊登模板设置`,
  key1005355: `第二步：刊登规则设置`,
  key1005356: `刊登规则设置`,
  key1005357: `第三步：一键刊登`,
  key1005358: `公司档案`,
  key1005359: `图片类型`,
  key1005360: `一键刊登支持平台：`,
  key1005361: `是否收藏：`,
  key1005362: `联系人姓名不能为空`,
  key1005363: `结算币种：`,
  key1005364: `省/市/区：`,
  key1005365: `仓库配置`,
  key1005366: `开启仓储管理`,
  key1005367: `发货地址信息`,
  key1005368: `+添加发货地址`,
  key1005369: `您更改提交的商户工商信息，平台正在审核中......`,
  key1005370: `您更改提交的商户工商信息已被平台驳回！请查看驳回原因后重新提交审核。`,
  key1005371: `查看驳回原因>>>`,
  key1005372: `您更改提交的商户工商信息平台已审核通过！`,
  key1005373: `未开启仓储管理`,
  key1005374: `工商信息已经在审核中，暂不支持修改！`,
  key1005375: `确认后不保存已编辑信息`,
  key1005376: `是否关闭仓储功能？`,
  key1005377: `当前仓储功能关闭后，暂不支持重新开启，是否确认关闭？`,
  key1005378: `已开启仓储管理`,
  key1005379: `请输入开户人姓名`,
  key1005380: `请输入开户行`,
  key1005381: `请输入银行卡号`,
  key1005382: `请输入身份证号`,
  key1005383: `请输入支付宝登录号，支持邮箱和手机号格式`,
  key1005384: `请输入该支付宝账户的开户人姓名`,
  key1005385: `请输入该支付宝账户的开户人证件号`,
  key1005386: `绑定`,
  key1005387: `绑定收款账户`,
  key1005388: `（推荐）`,
  key1005389: `更换成功！`,
  key1005390: `请输入支付宝登录号`,
  key1005391: `(推荐)`,
  key1005392: `变更日志`,
  key1005393: `绑定账户`,
  key1005394: `更换账户`,
  key1005395: `更换绑定`,
  key1005396: `原记录`,
  key1005397: `变更后记录`,
  key1005398: `变更时间`,
  key1005399: `暂未添加`,
  key1005400: `添加收款账户`,
  key1005401: `暂未绑定`,
  key1005402: `更换收款账户`,
  key1005403: `修改失败`,
  key1005404: `操作失败`,
  key1005405: `角色`,
  key1005406: `请输入提单编号`,
  key1005407: `审核中,审核后将展示补贴金额。`,
  key1005408: `符合`,
  key1005409: `不满足现有补贴规则、如有疑问请联系您的`,
  key1005410: `对接人员`,
  key1005411: `收货时间`,
  key1005412: `应发部分实到数量`,
  key1005413: `多发数量`,
  key1005414: `发往仓库`,
  key1005415: `追加货品`,
  key1005416: `作废提单`,
  key1005417: `作废提单提示`,
  key1005418: `提单作废后，提单内所有订单将流转至待重发状态，出库单将流转至待拣货状态，请确认是否作废提单?`,
  key1005419: `正在装箱`,
  key1005420: `物流单号`,
  key1005421: `作废成功!`,
  key1005422: `修改备货数量`,
  key1005423: `修改后数量`,
  key1005424: `未输入时视为不修改`,
  key1005425: `请输入需修改的商品数量`,
  key1005426: `修改备货数量成功!`,
  key1005427: `发起备货`,
  key1005428: `批量发货`,
  key1005429: `已选订单`,
  key1005430: `筛选结果订单`,
  key1005431: `仅出库单类型为自有仓备货的备货单可打印`,
  key1005432: `条码！`,
  key1005433: `扫描SKU打印`,
  key1005434: `打印所选订单条码`,
  key1005435: `请先勾选需要标记发货的数据！`,
  key1005436: `当前筛选结果暂无数据，无法标记发货！`,
  key1005437: `当前订单中出库状态为待发货状态的订单，才可以执行发货！`,
  key1005438: `当前订单中备货状态为待发货状态的订单，才可以执行发货！`,
  key1005439: `发货台内当前无商品，无法打印配货清单。`,
  key1005440: `请先勾选需要打印的订单条码数据！`,
  key1005441: `当前筛选结果暂无数据！无法打印订单条码`,
  key1005442: `取消备货`,
  key1005443: `请确认是否取消备货？`,
  key1005444: `配货清单格式`,
  key1005445: `清单格式：`,
  key1005446: `纸质清单`,
  key1005447: `表格文件`,
  key1005448: `扫描换单`,
  key1005449: `请扫描/输入SKU`,
  key1005450: `请扫描/输入SKU：`,
  key1005451: `批量打印：`,
  key1005452: `关闭弹窗`,
  key1005453: `sku不能为空！`,
  key1005454: `打印数量不能为空！`,
  key1005455: `获取打印地址失败！`,
  key1005456: `目的仓库：`,
  key1005457: `结算金额：`,
  key1005458: `拒绝`,
  key1005459: `待客户付款`,
  key1005460: `请输入订单号,支持多个逗号或换行分隔`,
  key1005461: `请输入供应商SKU,支持多个逗号或换行分隔`,
  key1005462: `缺货订单需标记到货后再进行发货！`,
  key1005463: `当前订单中出库单为待发货状态的订单，才可以执行发货！`,
  key1005464: `当前筛选结果暂无数据，无法操作！`,
  key1005465: `请先勾选需要标记的数据！`,
  key1005466: `标记到货提示`,
  key1005467: `标记到货后订单将流转为待发货，请确认是否标记到货？`,
  key1005468: `仅可对缺货订单标记到货，请重新选择！`,
  key1005469: `仅可对非缺货订单标记缺货，请重新选择！`,
  key1005470: `接单确认`,
  key1005471: `拒绝接单确认`,
  key1005472: `正在操作接单，确认接单后将按照此订单金额进行结算，需等待客户付款，请确认是否接单？`,
  key1005473: `拒绝接单后，订单将可能被取消，请确认是否拒绝接单？`,
  key1005474: `拒绝接单`,
  key1005475: `接单成功`,
  key1005476: `拒绝接单成功`,
  key1005477: `发货提醒`,
  key1005478: `出库单类型不能为空！`,
  key1005479: `目的仓库不能为空！`,
  key1005480: `商品数量不能为空或为0`,
  key1005481: `数量必须是大于0的整数`,
  key1005482: `请先添加商品！`,
  key1005483: `扫描`,
  key1005484: `请扫描或输入扫描`,
  key1005485: `请输入提货单号`,
  key1005486: `完成追加`,
  key1005487: `已扫描出库单`,
  key1005488: `编码信息`,
  key1005489: `装箱提示`,
  key1005490: `你扫描的出库单号，与当前已装箱的出库单目的仓库不同，请扫描同一仓库的出库单号装箱。`,
  key1005491: `请输入sku`,
  key1005492: `打印数量不能为空`,
  key1005493: `商品管理`,
  key1005494: `发布新商品`,
  key1005495: `销售趋势图`,
  key1005496: `热销商品排行榜`,
  key1005497: `统计本月（自然月）店铺已完成`,
  key1005498: `仓已发出`,
  key1005499: `的订单总金额。`,
  key1005500: `统计本月（自然月）已完成`,
  key1005501: `的订单总数。`,
  key1005502: `上月销售额`,
  key1005503: `本月订单量（单）`,
  key1005504: `上月订单量`,
  key1005505: `昨日订单量`,
  key1005506: `今日订单量`,
  key1005507: `在售SPU总数`,
  key1005508: `统计在售SPU总数量，不包含、停售、暂存状态的SPU。`,
  key1005509: `在售SKU总数`,
  key1005510: `统计在售SKU总数量，不包含、停售、暂存状态的SKU。`,
  key1005511: `订阅商品数（SPU）`,
  key1005512: `统计分销商订阅商品的总数，同一个商品多个分销商订阅只计算1次。`,
  key1005513: `本月订阅数`,
  key1005514: `昨日订阅数`,
  key1005515: `今日订阅数`,
  key1005516: `统计分销商已上架到平台的SPU总数量，同一个SPU不同的Item只计算一次。`,
  key1005517: `统计分销商已发布刊登SPU的总数量，按Item数量统计。`,
  key1005518: `近30天发货时效（小时）`,
  key1005519: `统计近30天所有订单从待拣货到已出库（快递揽收）的平均耗时`,
  key1005520: `近7天发货时效`,
  key1005521: `近3天发货时效`,
  key1005522: `在售中`,
  key1005523: `已售罄`,
  key1005524: `已驳回`,
  key1005525: `近7天`,
  key1005526: `近30天`,
  key1005527: `销售额(CNY)： ¥ `,
  key1005528: `订单量(单)：`,
  key1005529: `中心仓`,
  key1005530: `库存明细日志`,
  key1005531: `账单`,
  key1005532: `提单`,
  key1005533: `上架/停售`,
  key1005534: `请输入中文标题`,
  key1005535: `审核状态：`,
  key1005536: `批量改价`,
  key1005537: `批量上架/停售`,
  key1005538: `导出商品`,
  key1005539: `获取1688商品`,
  key1005540: `采集1688商品`,
  key1005541: `打印条形码`,
  key1005542: `1.导入销售价后将覆盖原来价格，请谨慎导入;`,
  key1005543: `4.导入操作后，请稍后在“导入/导出任务-导入查看”中查看导入结果。`,
  key1005544: `1.按照SKU维度进行上架/停售，停售后分销商将无法继续下单;`,
  key1005545: `sku,支持多个按行分隔`,
  key1005546: `更新时间：`,
  key1005547: `请输入供应商sku,支持多个按行分隔`,
  key1005548: `修改价格`,
  key1005549: `导入商品`,
  key1005550: `中心仓名称`,
  key1005551: `中心仓分配数量`,
  key1005552: `中心仓可用数量`,
  key1005553: `自有仓分配数量`,
  key1005554: `自有仓可用数量`,
  key1005555: `供货价（元）`,
  key1005556: `，该商品正在被映射商品使用，不能删除`,
  key1005557: `，SKU不存在`,
  key1005558: `，该SKU有库存不允许删除`,
  key1005559: `，系统繁忙，请重新尝试`,
  key1005560: `选择分类：`,
  key1005561: `模板：`,
  key1005562: `第1步：下载模板`,
  key1005563: `第2步：上传资料`,
  key1005564: `导入数据发现相同SPU时：`,
  key1005565: `1.请按照模板中的导入说明文档进行商品信息上传。`,
  key1005566: `2.仅支持拓展名：.zip。`,
  key1005567: `3.当导入数据存在异常时，数据导入失败。`,
  key1005568: `5.暂无法对组装商品进行导入或覆盖。`,
  key1005569: `文件大小超出限制，最大为500M`,
  key1005570: `请先选择分类！`,
  key1005571: `下载模板成功！`,
  key1005572: `获取类型：`,
  key1005573: `选择店铺：`,
  key1005574: `商品链接：`,
  key1005575: `请输入1688商品链接，如:https://detail.1688.com/offer/673838787879.html支持多个按行分隔`,
  key1005576: `请选择1688商品创建时间`,
  key1005577: `获取`,
  key1005578: `将尽快补充映射。`,
  key1005579: `匹配映射失败，`,
  key1005580: `SKU重复`,
  key1005581: `时间范围不能为空`,
  key1005582: `店铺不能为空`,
  key1005583: `1688店铺未绑定`,
  key1005584: `暂未绑定1688店铺，请绑定后重试。`,
  key1005585: `获取商品失败`,
  key1005586: `获取商品部分失败`,
  key1005587: `复制失败SPU`,
  key1005588: `匹配映射失败，可点击`,
  key1005589: `获取商品成功`,
  key1005590: `查看商品`,
  key1005591: `获取商品成功，可在商品页面`,
  key1005592: `商品获取中，请稍等!`,
  key1005593: `商品链接不能为空!`,
  key1005594: `指定链接地址必须是https://或者http://开头的链接!`,
  key1005595: `1688类目：`,
  key1005596: `批量采集`,
  key1005597: `近90天成交：`,
  key1005598: `商品采集中，请稍等!`,
  key1005599: `品牌名称不能为空`,
  key1005600: `品牌名称：`,
  key1005601: `请输入品牌名称`,
  key1005602: `品牌描述：`,
  key1005603: `请输入品牌描述`,
  key1005604: `品牌状态：`,
  key1005605: `品牌网址：`,
  key1005606: `请输入品牌网址`,
  key1005607: `品牌LOGO：`,
  key1005608: `新增品牌`,
  key1005609: `单击或拖动此处上传文件`,
  key1005610: `按品牌状态`,
  key1005611: `按品牌名称`,
  key1005612: `品牌LOGO`,
  key1005613: `品牌名称`,
  key1005614: `品牌描述`,
  key1005615: `品牌网址`,
  key1005616: `品牌状态`,
  key1005617: `是否要删除当前品牌？`,
  key1005618: `编辑品牌`,
  key1005619: `价格不能为0或为空！`,
  key1005620: `等待收款`,
  key1005621: `商品原价`,
  key1005622: `货品金额（应收）：`,
  key1005623: `补贴金额（应收）：`,
  key1005624: `物流费用（应付）：`,
  key1005625: `请输入账单编号，支持多个逗号或换行分隔`,
  key1005626: `导出账单`,
  key1005627: `确认账单即表示已对账单内容、金额核对无误，请确认账单后操作，确认账单后`,
  key1005628: `后台将发起打款流程，资金将转入所绑定的收款账户。`,
  key1005629: `货品结算金额(CNY)`,
  key1005630: `确认账单`,
  key1005631: `收款账户未绑定`,
  key1005632: `当前账户未绑定收款账户，无法完成收款流程，请绑定后重试。`,
  key1005633: `去绑定`,
  key1005634: `账单编号`,
  key1005635: `中文描述：`,
  key1005636: `导出库存(自有仓库)`,
  key1005637: `(已删除)`,
  key1005638: `SKU、商品名称`,
  key1005639: `模糊搜索`,
  key1005640: `SKU属性`,
  key1005641: `库存数量`,
  key1005642: `冻结数量`,
  key1005643: `不良品数量`,
  key1005644: `请选择库区`,
  key1005645: `导出库存(`,
  key1005646: `中心仓)`,
  key1005647: `库区：`,
  key1005648: `自有仓库`,
  key1005649: `开启拣货复核异常`,
  key1005650: `您扫描或输入的拣货单【 {pickList} 】已完成`,
  key1005651: `您扫描或输入的拣货单【 {pickList} 】不存在或状态已改变,请刷新后重试`,
  key1005652: `您扫描或输入的拣货单【 {pickList} 】下不存在出库单`,
  key1005653: `您扫描或输入的拣货单【 {pickList} 】下不存在有效出库单`,
  key1005654: `您扫描或输入的拣货单【 {pickList} 】下存在普通打印出库单`,
  key1005655: `您扫描或输入的拣货单【 {pickList} 】未进行多品分拣`,
  key1005656: `您扫描或输入的拣货单【 {pickList} 】下存在出库单未下发物流商成功`,
  key1005657: `您扫描或输入的拣货单【 {pickList} 】拣货未完成`,
  key1005658: `您扫描或输入的拣货单【 {pickList} 】在系统中未找到，请确认拣货单是否已被删除`,
  key1005659: `扫描/录入拣货单单号开始拣货复核`,
  key1005660: `正在进行的拣货复核`,
  key1005661: `混合`,
  key1005662: `时长`,
  key1005663: `包裹进度`,
  key1005664: `货品进度`,
  key1005665: `小组成员`,
  key1005666: `单品单件`,
  key1005667: `单品多件`,
  key1005668: `存在正在进行的拣货复核，是否立即进入`,
  key1005669: `存在正在进行的拣货复核，是否立即跳转`,
  key1005670: `已上传物流商`,
  key1005671: `请先选择物流商`,
  key1005672: `出库单详情：`,
  key1005673: `出库单编号：`,
  key1005674: `参考编号：`,
  key1005675: `拣货状态：`,
  key1005676: `装箱状态：`,
  key1005677: `最后修改时间：`,
  key1005678: `最后修改人：`,
  key1005679: `通途估算重量：`,
  key1005680: `通途称重重量：`,
  key1005681: `通途估算运费：`,
  key1005682: `通途称重后运费：`,
  key1005683: `物流商称重：`,
  key1005684: `分配列表`,
  key1005685: `其他出库`,
  key1005686: `组装加工`,
  key1005687: `行状态`,
  key1005688: `分配异常原因`,
  key1005689: `产品sku`,
  key1005690: `填入计划调拨数量`,
  key1005691: `已发货数量`,
  key1005692: `身份码`,
  key1005693: `已分配数量`,
  key1005694: `未分配数量`,
  key1005695: `分配库位`,
  key1005696: `分配批次`,
  key1005697: `分配完成时间`,
  key1005698: `订单创建`,
  key1005699: `部分分配`,
  key1005700: `分配完成`,
  key1005701: `完全发货`,
  key1005702: `订单完成`,
  key1005703: `未装箱`,
  key1005704: `详情加载失败`,
  key1005705: `中心仓备货时进行包装作业：`,
  key1005706: `多品多件包裹自动分拣功能：`,
  key1005707: `拣货复核时进行包装：`,
  key1005708: `此设置对供应商备货流程无效`,
  key1005709: `是否要在拣货复核时对包裹称重？`,
  key1005710: `对包装作业中的已包装的包裹标记为已发货？`,
  key1005711: `开启`,
  key1005712: `分拣时打印地址面单`,
  key1005713: `不称重`,
  key1005714: `分拣架设置(仅在多品多件包裹自动分拣功能开启时可用)`,
  key1005715: `仅按货品SKU字符升序`,
  key1005716: `请至少选择一个拣货架`,
  key1005717: `可扫描出库单号、运单号、物流商单号`,
  key1005718: `有些物流商面单扫描的运单号会带前缀或后缀，这样系统就无法识别运单号，勾选可截去相对应的前缀或后缀`,
  key1005719: `尺寸：`,
  key1005720: `称重异常提醒`,
  key1005721: `直发`,
  key1005722: `扫描出库`,
  key1005723: `切换为批量出库`,
  key1005724: `量体积`,
  key1005725: `称重单位为:`,
  key1005726: `自动执行发货`,
  key1005727: `实际称重：`,
  key1005728: `截去`,
  key1005729: `已发`,
  key1005730: `批量标记发货`,
  key1005731: `清空全部`,
  key1005732: `需要称重`,
  key1005733: `需要量尺寸`,
  key1005734: `称重异常提醒设置`,
  key1005735: `当包裹中的商品为：`,
  key1005736: `且包裹称重与商品资料重量误差大于等于`,
  key1005737: `时系统将提示`,
  key1005738: `发货运费异常设置`,
  key1005739: `逐单扫描发货计算运费大于`,
  key1005740: `产品重量小计(g)：`,
  key1005741: `重量总计(g)：`,
  key1005742: `继续发货`,
  key1005743: `取消发货`,
  key1005744: `个包裹`,
  key1005745: `总重`,
  key1005746: `导出物流确认excel`,
  key1005747: `产品重量`,
  key1005748: `已扫描`,
  key1005749: `标发货成功`,
  key1005750: `此包裹已存在`,
  key1005751: `量尺寸`,
  key1005752: `包裹号不能为空`,
  key1005753: `无权限`,
  key1005754: `您当前筛选的包裹汇总信息：`,
  key1005755: `常用标签：`,
  key1005756: `订单数`,
  key1005757: `SKU品种数`,
  key1005758: `开启多品分拣作业异常`,
  key1005759: `您扫描或输入的拣货单【 {pickList} 】在系统中未找到，请确认拣货单是否已被删除或拣货单号输入错误。`,
  key1005760: `您扫描或输入的拣货单【 {pickList} 】不是多品多件拣货单，无法开启分拣作业。`,
  key1005761: `系统每个操作员同一时间只允许开启一个分拣作业，所以，很抱歉，你无法对拣货单【 {pickList} 】开启分拣作业。`,
  key1005762: `您扫描或输入的拣货单【 {pickList} 】正在进行分拣作业，每张拣货单同一时间只允许一位操作员进行分拣作业，无法再次开启。`,
  key1005763: `您扫描或输入的拣货单【 {pickList} 】已经分拣完成，您可以对该拣货单重新开启分拣作业。`,
  key1005764: `您扫描或输入的拣货单【 {pickList} 】正在进行拣货复核，无法开启分拣作业。`,
  key1005765: `您扫描或输入的拣货单【 {pickList} 】已经完成拣货复核，无法开启分拣作业。`,
  key1005766: `您扫描或输入的拣货单【 {pickList} 】下存在已进行普通打印出库单`,
  key1005767: `扫描/录入拣货单单号开始多品分拣`,
  key1005768: `正在进行的分拣作业`,
  key1005769: `拣货篮不能找到唯一没包装完成的拣货单`,
  key1005770: `拣货篮没有待完成的拣货单`,
  key1005771: `当前系统中存在由您开启的、尚未完成的分拣作业，`,
  key1005772: `分拣作业开始时间：`,
  key1005773: `我搞错了，我不需要重新分拣`,
  key1005774: `是的，我需要对这个拣货单进行重新分拣`,
  key1005775: `分拣状态`,
  key1005776: `正在分拣`,
  key1005777: `分拣完成`,
  key1005778: `操作员`,
  key1005779: `没有权限`,
  key1005780: `部分拣货`,
  key1005781: `等待分拣`,
  key1005782: `等待复核`,
  key1005783: `正在复核`,
  key1005784: `已复核`,
  key1005785: `普通打印`,
  key1005786: `见货出单`,
  key1005787: `拣货提醒`,
  key1005788: `请确认是否取消`,
  key1005789: `导出成功！`,
  key1005790: `拣货单生成时间：`,
  key1005791: `单品规则保存`,
  key1005792: `多品规则保存`,
  key1005793: `单品规则`,
  key1005794: `多品规则`,
  key1005795: `合并规则`,
  key1005796: `时间设置`,
  key1005797: `固定周期`,
  key1005798: `每过：`,
  key1005799: `小时生成拣货单`,
  key1005800: `每天定时`,
  key1005801: `每天：`,
  key1005802: `是否开启`,
  key1005803: `请填写时间`,
  key1005804: `正常拣货单`,
  key1005805: `自动拣货波次`,
  key1005806: `批量添加出库单`,
  key1005807: `输入多个请用逗号或者换行分隔`,
  key1005808: `导出提单数据`,
  key1005809: `新增箱/袋`,
  key1005810: `添加包裹`,
  key1005811: `是否要删除当前提单编号？`,
  key1005812: `请输入出库单!`,
  key1005813: `包裹不存在`,
  key1005814: `包裹不是出库状态`,
  key1005815: `该包裹已提单`,
  key1005816: `未拣货/打印出库单明细`,
  key1005817: `同一个出库单中未拣数量至少要有一个是大于0`,
  key1005818: `打印时间：`,
  key1005819: `请输入拣货单号`,
  key1005820: `请输入运单号，多个运单号可以英文逗号隔开`,
  key1005821: `请输入出库单号`,
  key1005822: `请输入订单号`,
  key1005823: `请输入买家姓名`,
  key1005824: `请输入买家ID`,
  key1005825: `可输入订单号、出库单号、买家ID、买家姓名、SKU`,
  key1005826: `多个库区，用逗号或换行分隔`,
  key1005827: `是否有运单号：`,
  key1005828: `是否有物流商号：`,
  key1005829: `是否有物流商重量：`,
  key1005830: `是否有物流商运费：`,
  key1005831: `之前`,
  key1005832: `以下SKU列表是所选包裹中所包含的SKU的汇总，请选择缺货的SKU，标记缺货后，系统将自动将包裹还原到缺货状态，并还原所选SKU的库存，同时建立所选SKU的盘点单，请进行盘点修正库存。`,
  key1005833: `按打印时间`,
  key1005834: `按付款时间`,
  key1005835: `按出库单号`,
  key1005836: `买家ID/姓名`,
  key1005837: `标记为已发货`,
  key1005838: `请选择包裹`,
  key1005839: `标出库`,
  key1005840: `标出库，系统将符合您选择的包裹标记为已出库。`,
  key1005841: `系统将符合您选择的包裹标记为已发货。`,
  key1005842: `标出库（所有结果集）`,
  key1005843: `所有结果集标出库，系统将符合您当前查询条件的所有包裹标记为已出库。`,
  key1005844: `请选择标记的订单`,
  key1005845: `个拣货篮：`,
  key1005846: `打印选中面单`,
  key1005847: `包材`,
  key1005848: `重打面单`,
  key1005849: `拣货复核`,
  key1005850: `没有正在扫描的拣货单`,
  key1005851: `当前勾选中的数据都已打印！`,
  key1005852: `箱/袋管理`,
  key1005853: `扫描装箱`,
  key1005854: `冻结分配`,
  key1005855: `解冻并分配`,
  key1005856: `入库单`,
  key1005857: `冻结单`,
  key1005858: `产品ID`,
  key1005859: `库位使用`,
  key1005860: `收货库位`,
  key1005861: `拣货库位`,
  key1005862: `请输入SKU`,
  key1005863: `来源商户：`,
  key1005864: `身份码：`,
  key1005865: `导出出库单数据`,
  key1005866: `作废出库单异常`,
  key1005867: `新增出库单`,
  key1005868: `请输入运单号，多个运单号可用英文逗号分隔`,
  key1005869: `请输入物流商号，多个物流商号可用英文逗号分隔`,
  key1005870: `按出库时间`,
  key1005871: `此出库单为其他出库单实际发货数量超出应发数量所产生的补单。`,
  key1005872: `来源渠道`,
  key1005873: `来源商户`,
  key1005874: `运单号/物流商单号`,
  key1005875: `物流商重量/物流商运费`,
  key1005876: `下载面单`,
  key1005877: `关联订单号`,
  key1005878: `买家名称`,
  key1005879: `地址`,
  key1005880: `邮编`,
  key1005881: `打印时间`,
  key1005882: `打印人`,
  key1005883: `实际重量`,
  key1005884: `物流商重量(g)`,
  key1005885: `组织`,
  key1005886: `物品SKU`,
  key1005887: `物品身份码`,
  key1005888: `多品分拣—结束分拣`,
  key1005889: `已出库数量`,
  key1005890: `货品(已拣/待拣)`,
  key1005891: `新增订单成功!`,
  key1005892: `扫描拣货单号/出库单号/运单号/`,
  key1005893: `面单：`,
  key1005894: `出库单为空，无法操作结束装箱！`,
  key1005895: `请先扫描提单编号！`,
  key1005896: `请输入提单编号！`,
  key1005897: `请输入拣货单号/出库单号/运单号！`,
  key1005898: `是否要删除当前出库单号？`,
  key1005899: `(作废)`,
  key1005900: `库区组`,
  key1005901: `拣货数量`,
  key1005902: `识别号：`,
  key1005903: `扫描SKU：`,
  key1005904: `可扫描SKU`,
  key1005905: `扫描身份码：`,
  key1005906: `可扫描身份码`,
  key1005907: `标记异常`,
  key1005908: `包装设置`,
  key1005909: `异常`,
  key1005910: `校对SKU`,
  key1005911: `已扫描包裹`,
  key1005912: `取消包装`,
  key1005913: `继续包装`,
  key1005914: `包材名称`,
  key1005915: `应包装数量`,
  key1005916: `克（g）`,
  key1005917: `千克（Kg）`,
  key1005918: `待包装数量`,
  key1005919: `产品重量(g)`,
  key1005920: `当前包裹中还在sku还未扫描完成！`,
  key1005921: `当前包裹中还有身份码未扫描完成！`,
  key1005922: `当前扫描的出库单号或运单号或物流商单号，已经扫描出库！`,
  key1005923: `识别号不能为空！`,
  key1005924: `当前扫描的数量不能大于应包装数量！`,
  key1005925: `当前扫描的sku不存在！`,
  key1005926: `SKU不能为空！`,
  key1005927: `称重不能为空且要大于0！`,
  key1005928: `请先扫描识别号！`,
  key1005929: `已扫描数量不能全等于待包装数量，不然无法标记异常！`,
  key1005930: `标记异常成功！`,
  key1005931: `已扫描包裹成功！`,
  key1005932: `重打数量`,
  key1005933: `已打印数量`,
  key1005934: `已扫描完成`,
  key1005935: `匹配失败`,
  key1005936: `多个订单号请用逗号分隔`,
  key1005937: `SKU/数量：`,
  key1005938: `品牌：`,
  key1005939: `发货提醒：`,
  key1005940: `拣货单生成设置`,
  key1005941: `品类`,
  key1005942: `拣货库区组`,
  key1005943: `拣货库区`,
  key1005944: `标签名不能为空`,
  key1005945: `选择导入文件：`,
  key1005946: `选择文件`,
  key1005947: `下载模板`,
  key1005948: `存在空的通途sku`,
  key1005949: `存在空的skuCode`,
  key1005950: `通途sku系统不存在`,
  key1005951: `skuCode 系统不存在`,
  key1005952: `头程成本必须是数字`,
  key1005953: `作废出库单`,
  key1005954: `勾选重置出库单，会重新生成一个出库单`,
  key1005955: `重置出库单`,
  key1005956: `条出库单被重置状态，成功执行后的记录，将回到【等待分配】状态,同时出库单会执行以下操作：`,
  key1005957: `1.出库单如果已经下发物流成功，会尝试调用物流商接口取消下单`,
  key1005958: `2.出库单如果已经分配库存成功，会归还已分配库存`,
  key1005959: `代号：`,
  key1005960: `请输入代号`,
  key1005961: `账号名：`,
  key1005962: `请输入授权的平台账号`,
  key1005963: `到期时间：`,
  key1005964: `账号名`,
  key1005965: `授权时间`,
  key1005966: `数据同步`,
  key1005967: `选择仓库`,
  key1005968: `选择多个仓库将同步所有仓库总库存。`,
  key1005969: `同步仓库`,
  key1005970: `授权`,
  key1005971: `新增商品`,
  key1005972: `请先勾选仓库！`,
  key1005973: `库存 =`,
  key1005974: `主仓实际库存`,
  key1005975: `订单占有数`,
  key1005976: `仓库待发数`,
  key1005977: `虚拟库存`,
  key1005978: `采购在途数`,
  key1005979: `销退仓库存`,
  key1005980: `进货仓库存`,
  key1005981: `次品库存`,
  key1005982: `库存 = `,
  key1005983: `规则项不能为空!`,
  key1005984: `您没有权限访问该资源,请与管理员联系`,
  key1005985: `系统繁忙，请重新尝试（接口报错了，请在控制台查看接口错误）`,
  key1005986: `账号已被停用`,
  key1005987: `供应商结算状态需为等待结算且`,
  key1005988: `出库状态需为已出库`,
  key1005989: `客户结算状态需为等待结算且`,
  key1005990: `确认支付账单失败，该账单已作废`,
  key1005991: `分类ID已存在`,
  key1005992: `父节点不存在`,
  key1005993: `分类名称不能包含->`,
  key1005994: `名称不能包含空格`,
  key1005995: `已到最大分类层级`,
  key1005996: `同级分类名已存在`,
  key1005997: `邮箱已经被占用`,
  key1005998: `手机号已经被占用`,
  key1005999: `包裹非已揽收状态`,
  key1006000: `查询不到相关出库单`,
  key1006001: `提单不为已分拣状态`,
  key1006002: `存在多个包裹`,
  key1006003: `提单状态需为已揽收或分拣中`,
  key1006004: `存在非分拣中状态`,
  key1006005: `出库单非已拣货状态`,
  key1006006: `交接单非已装箱状态`,
  key1006007: `已是客户`,
  key1006008: `图形验证码错误`,
  key1006009: `验证码错误`,
  key1006010: `邮箱未注册`,
  key1006011: `页面重复提交`,
  key1006012: `发送短信失败`,
  key1006013: `查询用户失败`,
  key1006014: `手机号为空`,
  key1006015: `密码错误`,
  key1006016: `短信验证码校验错误`,
  key1006017: `图形验证码失效`,
  key1006018: `超过错误次数,请输入图形验证码再重试`,
  key1006019: `重复提交`,
  key1006020: `非当前用户邮箱`,
  key1006021: `非当前用户手机`,
  key1006022: `登录失败,用户不存在`,
  key1006023: `请使用通途管理员账号登录注册分销账户`,
  key1006024: `通途帐号登录自动创建客户帐号成功,审核通过后再访问`,
  key1006025: `当前用户请审核通过之后登录`,
  key1006026: `商品不存在`,
  key1006027: `客户等级已绑定供应商等级`,
  key1006028: `供应商已设置该供应商等级`,
  key1006029: `查询的拣货单编号不存在`,
  key1006030: `品牌名已存在`,
  key1006031: `非草稿状态不能删除`,
  key1006032: `待审核不能修改中文名称,英文名称,标题,图片`,
  key1006033: `不能包含逗号`,
  key1006034: `存在相同属性SKU`,
  key1006035: `SPU或SKU存在重复`,
  key1006036: `变参商品SKU不能与SPU重复`,
  key1006037: `该商品正在被映射商品使用，不能删除`,
  key1006038: `包裹存在待完成的补拣单`,
  key1006039: `sku不存在`,
  key1006040: `异常包裹中存在正常拣货包裹`,
  key1006041: `包裹已作废`,
  key1006042: `标出库失败，此包裹已作废待回收`,
  key1006043: `完成包装失败，包装状态已变更!`,
  key1006044: `不是多品多件拣货单，无法开启分拣作业。`,
  key1006045: `系统每个操作员同一时间只允许开启一个分拣作业`,
  key1006046: `拣货单正在被别人分拣中`,
  key1006047: `已经分拣完成，您可以对该拣货单重新开启分拣作业。`,
  key1006048: `存在已进行普通打印出库单`,
  key1006049: `拣货单未打印，不能开始拣货复核`,
  key1006050: `拣货单已完成`,
  key1006051: `拣货单下不存在出库单`,
  key1006052: `拣货单下不存在有效出库单`,
  key1006053: `拣货单下存在商品备注`,
  key1006054: `拣货单下存在普通打印出库单`,
  key1006055: `拣货单未进行多品分拣`,
  key1006056: `拣货单下存在出库单未下发物流商成功`,
  key1006057: `拣货单拣货未完成`,
  key1006058: `分拣仅支持销售出库拣货单`,
  key1006059: `sku与upc不可重复`,
  key1006060: `upc已存在`,
  key1006061: `UPC存在重复`,
  key1006062: `存在重复SKU`,
  key1006063: `非销售出库单拣货单不支持拣货复核`,
  key1006064: `包裹已截留`,
  key1006065: `用户正在包装其他拣货单`,
  key1006066: `未找到可以分配的库存，无法分配`,
  key1006067: `包裹已出库`,
  key1006068: `包裹信息不存在`,
  key1006069: `箱号不能为空`,
  key1006070: `sku不能为空`,
  key1006071: `未找到对应出库单`,
  key1006072: `出库单号错误`,
  key1006073: `SKU不存在在`,
  key1006074: `拣货单不存在`,
  key1006075: `清空扫描失败，该包裹已经扫描完成`,
  key1006076: `装箱数量错误`,
  key1006077: `该拣货单下只有一个包裹，不能移除`,
  key1006078: `拣货单已完成拣货复核,无法重新分拣`,
  key1006079: `仓库正在配货中，请稍后再试`,
  key1006080: `包裹没有待补拣的货品`,
  key1006081: `入库单已关闭,不能进行收货!`,
  key1006082: `当前包裹不是已打印状态`,
  key1006083: `扫描的sku数量与应包装数量不一致`,
  key1006084: `未匹配合适出库单`,
  key1006085: `无效key`,
  key1006086: `扫描sku数量与身份码数量不一致`,
  key1006087: `当前提单状态不是装箱中不可以继续装箱`,
  key1006088: `结束分拣的拣货单分拣状态不是分拣中,不能结束分拣`,
  key1006089: `已拣货的拣货单不能再次标记为已拣货`,
  key1006090: `包裹状态已变更,操作异常`,
  key1006091: `提单号不存在`,
  key1006092: `提单非等待揽收/正在装箱状态,`,
  key1006093: `不能删除`,
  key1006094: `当前属性正在被引用，不可删除`,
  key1006095: `同名属性已存在`,
  key1006096: `请停售被删除的SKU后重试`,
  key1006097: `该SKU有库存不允许删除`,
  key1006098: `SKU已存在`,
  key1006099: `订单已作废`,
  key1006100: `存在非等待揽收状态`,
  key1006101: `提单揽收包裹数量不统一`,
  key1006102: `属性不合法`,
  key1006103: `颜色的中文名称已存在`,
  key1006104: `库区编码重复`,
  key1006105: `库区名称重复`,
  key1006106: `仓库名称重复`,
  key1006107: `仓库编码重复`,
  key1006108: `该`,
  key1006109: `订单已换单无法取消`,
  key1006110: `操作人已完成拣货，请勿重复提交`,
  key1006111: `已分拣完成处于带包装状态，请勿重复提交`,
  key1006112: `已经撤销成功，请勿重复提交`,
  key1006113: `库位编号重复`,
  key1006114: `拣货单正在分拣`,
  key1006115: `没有匹配到待包装`,
  key1006116: `当前拣货单状态为非待拣货/拣货中状态`,
  key1006117: `拣货单不是待包装或者包装中`,
  key1006118: `状态已变更,请刷新后重试!`,
  key1006119: `只有已换单的包裹才可以扫描`,
  key1006120: `分类下存在商品`,
  key1006121: `分类下存在子分类`,
  key1006122: `重量不能为空`,
  key1006123: `剩下一个sku时`,
  key1006124: `不可以删除`,
  key1006125: `获取分类信息异常`,
  key1006126: `非物流面单类型不允许标记出库`,
  key1006127: `面单异常`,
  key1006128: `不能映射被删除的属性`,
  key1006129: `存在映射属性，不允许删除`,
  key1006130: `提单已删除`,
  key1006131: `变更的类别下存在映射属性，请先进行映射属性删除`,
  key1006132: `当前充值的客户编号不存在`,
  key1006133: `同一平台下不能存在相同站点/无站点则不能存在相同分类`,
  key1006134: `货品没有图片信息，请及时上传`,
  key1006135: `货品没有颜色图片信息，请及时上传`,
  key1006136: `物流商不存在`,
  key1006137: `物流商账号名称已存在`,
  key1006138: `平台帐号已停用`,
  key1006139: `服务名称或者编号重复`,
  key1006140: `物流商代码已存在`,
  key1006141: `存在相同名称的自动规则`,
  key1006142: `货代物理关联重复`,
  key1006143: `店铺编号已存在`,
  key1006144: `店铺名称已存在`,
  key1006145: `店铺名称和编号已存在`,
  key1006146: `已授权店铺不能切换店铺`,
  key1006147: `订单状态异常`,
  key1006148: `没有满足`,
  key1006149: `物流特殊规则的`,
  key1006150: `物流范围规则的`,
  key1006151: `取消订单异常`,
  key1006152: `更新用户信息异常`,
  key1006153: `更新物流信息异常`,
  key1006154: `创建订单失败`,
  key1006155: `订单已下单`,
  key1006156: `包裹已经出库`,
  key1006157: `正在处理下发物流操作`,
  key1006158: `取消物流失败`,
  key1006159: `供应商系统作废子单失败`,
  key1006160: `查询指定货品信息为空`,
  key1006161: `手工下单失败`,
  key1006162: `生成发货信息失败`,
  key1006163: `物流渠道停用失败，已关联`,
  key1006164: `物流无法停用`,
  key1006165: `物流渠道代码不可重复`,
  key1006166: `该包裹不处于等待出库状态`,
  key1006167: `指定拣货单列表为空`,
  key1006168: `中心仓备货的出库单已经生成拣货单，不允许作废`,
  key1006169: `面单正在拣货复核中`,
  key1006170: `出库单不是`,
  key1006171: `中心仓备货的出库单`,
  key1006172: `存在已出库的供应商子单`,
  key1006173: `没有找到sku对应的货品信息`,
  key1006174: `中心仓备货拣货单正在被扫描`,
  key1006175: `同类型通知消息模板已存在`,
  key1006176: `商户未设置刊登地址`,
  key1006177: `角色名称已存在`,
  key1006178: `角色在使用中`,
  key1006179: `未设置默认客户等级`,
  key1006180: `出库单已标缺货`,
  key1006181: `供应商未进行直接发货设置，不允许直接发货`,
  key1006182: `当前容器入库状态不是待换单`,
  key1006183: `同名容器类型已存在`,
  key1006184: `当前容器类型已被绑定`,
  key1006185: `容器与提单不在同一个仓库`,
  key1006186: `分拣容器中不存在该`,
  key1006187: `容器不存在`,
  key1006188: `容器已被绑定`,
  key1006189: `容器状态异常或已变更`,
  key1006190: `容器为空，不可结束分拣`,
  key1006191: `提单分拣数量异常，存在未完成分拣的sku或运单`,
  key1006192: `当前容器入库状态不是换单中`,
  key1006193: `订单已被分拣`,
  key1006194: `提单正在分拣`,
  key1006195: `容器状态不存在`,
  key1006196: `提单状态需为已入库或分拣中`,
  key1006197: `存在分拣中提单`,
  key1006198: `该提单其他用户正在分拣中`,
  key1006199: `已绑定同类型容器`,
  key1006200: `请完成当前的容器贴单作业`,
  key1006201: `容器正在贴单`,
  key1006202: `请完成当前的容器上架作业`,
  key1006203: `容器正在上架`,
  key1006204: `存在处理中的容器`,
  key1006205: `分拣交接单中已存在该`,
  key1006206: `订单需要先走商品分拣`,
  key1006207: `订单已发货，不可被移除`,
  key1006208: `正在处理下发物流,请稍后再试`,
  key1006209: `当前尺码类型正在被使用`,
  key1006210: `模板已被使用，不允许删除`,
  key1006211: `部位中文名称已被使用，不允许修改`,
  key1006212: `尺码值已存在`,
  key1006213: `规格属性已被使用，不允许删除`,
  key1006214: `规格属性已被使用，不允许修改`,
  key1006215: `当前尺码值,正在被使用,不允许删除`,
  key1006216: `主属性的规格属性已被使用，不允许修改`,
  key1006217: `导出模板异常`,
  key1006218: `聚水潭商户未授权`,
  key1006219: `未添加聚水潭商户`,
  key1006220: `未找到已勾选同步库存并且已启用的聚水潭商户`,
  key1006221: `Sku值为空`,
  key1006222: `不是在售的spu不能停售`,
  key1006223: `上架数量与拣货数量不一致`,
  key1006224: `导出账单信息为空`,
  key1006225: `手机未注册`,
  key1006226: `SPU不能包含中文`,
  key1006227: `当前尺码属性模板正在被使用`,
  key1006228: `已存在相同名称的属性，请修改属性名称后再提交`,
  key1006229: `没有创建仓库，请先创建仓库`,
  key1006230: `该订单已取消，请在线下补货到中心仓`,
  key1006231: `已装箱的出库单不能作废`,
  key1006232: `存在多个主属性`,
  key1006233: `未满足重复刊登条件`,
  key1006234: `入驻商户不存在`,
  key1006235: `密码校验错误`,
  key1006236: `未查询到收款人信息`,
  key1006237: `拣货单不是待分拣或者分拣中`,
  key1006238: `拣货单已取消`,
  key1006239: `拣货单不处于包装中`,
  key1006240: `正在装箱的出库单不能作废`,
  key1006241: `活动结束时间不能小于活动开始时间`,
  key1006242: `该商品已经存在其他活动中`,
  key1006243: `未找到商品对应类型的容器，请绑定后重新扫描。`,
  key1006244: `暂未授权WishPost，请在自有物流管理中授权并关联店铺后重试`,
  key1006245: `订单对应店铺暂未关联WishPost账户，请在自有物流管理中关联后重试`,
  key1006246: `WishPost授权无效，请在自有物流管理中重新授权后重试`,
  key1006247: `店铺已关联其他账号`,
  key1006248: `关联店铺未启用`,
  key1006249: `同名问卷已存在`,
  key1006250: `当前问卷下同名问题已存在`,
  key1006251: `仓库已关联供应商`,
  key1006252: `扫描包裹与交接单发货仓库不相同`,
  key1006253: `1688平台未授权店铺`,
  key1006254: `链接不存在`,
  key1006255: `币种配置已存在`,
  key1006256: `此拣货单为常规单，请至常规包装模块进行作业`,
  key1006257: `此拣货单为备货拣货单，请至备货分拣进行作业`,
  key1006258: `客户暂未执行发货，请下轮作业重试`,
  key1006259: `未查到相关小包信息`,
  key1006260: `当前小包已经被分拣扫描`,
  key1006261: `存在未包装完成小包`,
  key1006262: `存在未被物流分拣小包,请分拣完成后再结袋`,
  key1006263: `修改订单同步状态为同步中失败`,
  key1006264: `物流交接单所在仓未授权,请申请对应仓库权限`,
  key1006265: `交接单不存在`,
  key1006266: `请先开通Temu虚拟物流`,
  key1006267: `平台订单已存在`,
  key1006268: `物流商单号已存在`,
  key1006269: `已充值币种不可修改`,
  key1006270: `创建商品后币种不可修改`,
  key1006271: `正在进行中的作业不是分拣中或包装中`,
  key1006272: `扫描的sku数量超出拣货单的应拣数量`,
  key1006273: `已存在商品使用此属性作为规格属性，不可删除`,
  key1006274: `还原库存失败`,
  key1006275: `账单已经生成，补贴审核失败`,
  key1006276: `物流仅支持绑定一条渠道`,
  key1006277: `上传包裹失败`,
  key1006278: `获取面单失败`,
  key1006279: `仓库地址不存在`,
  key1006280: `物流未关联基础物流`,
  key1006281: `物流商获取token失败`,
  key1006282: `不允许扫描temu出库单`,
  key1006283: `速卖通oaid不存在`,
  key1006284: `解密失败`,
  key1006285: `账单已经生成，补贴重新审核失败`,
  key1006286: `打印所选订单条码异常`,
  key1006287: `打印sku条码异常`,
  key1006288: `存在非缺货中的货品无法标记到货`,
  key1006289: `无此商品订单，请核对订单`,
  key1006290: `提单非等待揽收状态`,
  key1006291: `出库单已出库,不可进行其他操作`,
  key1006292: `当前订单中存在出库单为非待发货状态的订单`,
  key1006293: `此拣货单内只有一个订单，此拣货单无需分拣，请进行备货包装作业`,
  key1006294: `此拣货单为常规单，请至常规分拣进行作业`,
  key1006295: `此拣货单为备货单，请至备货分拣进行作业`,
  key1006296: `追加货品，同步运营提单失败`,
  key1006297: `当前订单中存在出库单为缺货状态的订单`,
  key1006298: `中间标签码不可为空`,
  key1006299: `无发货订单`,
  key1006300: `该拣货单存在在未整单完结的`,
  key1006301: `手工订单和平台订单不可同时执行发货`,
  key1006302: `货品订单和样品订单不可同时执行发货`,
  key1006303: `不同店铺的订单不可同时执行发货`,
  key1006304: `不同收货地址的订单不可同时执行发货`,
  key1006305: `该订单存在未结袋的小包`,
  key1006306: `订单已经完结,不可以再次扫描`,
  key1006307: `手动修改的扫描数必须是大于0的正整数`,
  key1006308: `所选出库单批次下出库单超出最大限制，请调整最大包裏数`,
  key1006309: `每张拣货单最大出库单数不能为空或小于1`,
  key1006310: `拣货单正在被其他用户作业，不可再次作业`,
  key1006311: `temu_s拣货单存在未打印`,
  key1006312: `提单与容器所属仓库不同,请检查后重试`,
  key1006313: `创建订单失败,客户余额不足`,
  key1006314: `非待匹配商品或匹配物流失败或下单失败状态，无法标记为非`,
  key1006315: `订单操作`,
  key1006316: `同系统同模块下已存在该标题的标题指引，请重新定义标题`,
  key1006317: `物流/基础物流不能同时传`,
  key1006318: `物流/基础物流必传其一`,
  key1006319: `基础物流trackingNumber,labelPath必传`,
  key1006320: `sku已停售`,
  key1006321: `商品发货仓库匹配到多个，请拆分平台订单后，再下单`,
  key1006322: `匹配仓库失败`,
  key1006323: `商品可用库存为空或不合法`,
  key1006324: `账号未绑定店铺，或者账号已过期`,
  key1006325: `同名客户标签已存在`,
  key1006326: `该客户分组名已存在`,
  key1006327: `该用户已开启其他拣货作业`,
  key1006328: `手机已绑定,不支持更新手机`,
  key1006329: `商品所属类目未映射平台`,
  key1006330: `获取供应商币种接口失败`,
  key1006331: `SPU存在重复，请修改`,
  key1006332: `查询三方平台订单失败`,
  key1006333: `支付宝支付订单失败`,
  key1006334: `新增充值记录失败`,
  key1006335: `获取分销商币种失败`,
  key1006336: `未查询到可操作订单`,
  key1006337: `回传发货状态失败`,
  key1006338: `店铺地址为空`,
  key1006339: `物流交接单内订单为空`,
  key1006340: `已换单后不允许多品分拣移除拣货单`,
  key1006341: `未申请预约单`,
  key1006342: `容器类型已被使用，请删除已使用此类型的容器后，再编辑此容器类型`,
  key1006343: `未找到物流追踪记录`,
  key1006344: `未匹配到有效物流渠道`,
  key1006345: `存在下单失败的`,
  key1006346: `订单,请完成后再更换商品`,
  key1006347: `客户等级名称已存在,请更换后重试`,
  key1006348: `已装箱后不允许修改买家收件地址`,
  key1006349: `邮箱注册方式邮箱不可为空`,
  key1006350: `邮箱和手机至少需要填写一个`,
  key1006351: `此物流渠道暂不可用，详情请咨询客服`,
  key1006352: `已选商品正在生成资料中`,
  key1006353: `生成售卖资料失败`,
  key1006354: `已存在供应商账号`,
  key1006355: `请重新登录`,
  key1006356: `已存在分销商账号，请重新登录`,
  key1006357: `暂未开通供应商账号权限，请联系主账号开通，如有疑问，请联系在线客服咨询！`,
  key1006358: `html类型面单只允许单个打印`,
  key1006359: `销售价小于或等于0`,
  key1006360: `标记缺货的时候，缺货数量必须大于1`,
  key1006361: `该账号为运营商系统账号，请更换账号再试`,
  key1006362: `此发货仓已有此类型费用配置，请检查后重试`,
  key1006363: `未设置仓库处理费`,
  key1006364: `固定计费未设置常规或多品订单处理费`,
  key1006365: `分层计费未设置分层价或多频订单处理费`,
  key1006366: `当前勾选的账单存在已被确认过的账单`,
  key1006367: `此规格属性已被使用，不可修改为普通属性`,
  key1006368: `存在相同模板名称的地址薄`,
  key1006369: `备注内容过长,添加失败`,
  key1006370: `上架工具权限授权更新失败`,
  key1006371: `备货单所在提单已装箱`,
  key1006372: `同平台配置已存在`,
  key1006373: `未配置指定平台`,
  key1006374: `未配置指定店铺`,
  key1006375: `未配置刊登模板`,
  key1006376: `店铺token失效`,
  key1006377: `未设置超时时间`,
  key1006378: `请先开通速卖通全托管物流`,
  key1006379: `IOSS编码格式不正确`,
  key1006380: `目的国税率设置已存在`,
  key1006381: `同名IOSS配置已存在`,
  key1006382: `其中有原料正在被组装商品使用，不能删除`,
  key1006383: `平台订单IOSS已录入`,
  key1006384: `物流代缴，清关费用不可为空`,
  key1006385: `此物流渠道已停用,请重新选择物流渠道`,
  key1006386: `存在已经生成售卖资料的数据`,
  key1006387: `分销商备货单不是待支付状态`,
  key1006388: `分销商取消备货单货品数量校验异常`,
  key1006389: `分销商备货单不是待调价状态`,
  key1006390: `分销商备货单不是供应商待接价状态`,
  key1006391: `分配备货单上架库存失败`,
  key1006392: `设置组装商品中包含已删除货品`,
  key1006393: `设置组装商品中原材料包含组装货品`,
  key1006394: `组装商品原材料不能和当前商品一致`,
  key1006395: `存在相同名称的导出模板`,
  key1006396: `未配置组装商品虚拟库位`,
  key1006397: `组装商品原料及成品出入库失败`,
  key1006398: `物流交接单非分拣中状态`,
  key1006399: `可操作单数量与选择不一致`,
  key1006400: `没有匹配买家邮政编码的`,
  key1006401: `可操作单数量于选择不一致`,
  key1006402: `保存申报信息失败`,
  key1006403: `不支持的库存操作`,
  key1006404: `库存操作失败`,
  key1006405: `调接口获取供应商数据失败`,
  key1006406: `当前提单非自有物流且处在等待揽收状态，不可修改物流`,
  key1006407: `货品分配数失败`,
  key1006408: `撤回`,
  key1006409: `调速卖通创建揽收接口失败`,
  key1006410: `打印揽收面单图片访问失败`,
  key1006411: `调速卖通全托管打印揽收面单接口失败`,
  key1006412: `速卖通预约交货接口调用API异常`,
  key1006413: `超出此物流尺寸限制，请更换物流后重试`,
  key1006414: `超出此物流重量限制，请更换物流后重试`,
  key1006415: `超出此物流配送范围，请更换物流后重试`,
  key1006416: `待接单状态无法匹配商品`,
  key1006417: `不同目的仓订单请分开发货`,
  key1006418: `由于货品数量过多，请在详情中进行单个打印`,
  key1006419: `商品属性未填写属性占比`,
  key1006420: `当前交接单内存在已取消的包裹，请移除已取消包裹后重试`,
  key1006421: `存在非已装箱的订单`,
  key1006422: `此商品已停售，`,
  key1006423: `仓库存数量小于订单货品数量，暂无法下单`,
  key1006424: `生成拣货单失败`,
  key1006425: `存在分销商未取消订单，请稍后再试`,
  key1006426: `当前物流不可达，请取消订单后重新下单`,
  key1006427: `暂未开通分销商账号权限，请联系主账号开通，如有疑问，请联系在线客服咨询！`,
  key1006428: `更换收货地址取消`,
  key1006429: `订单异常`,
  key1006430: `更换收货地址失败，订单已取消`,
  key1006431: `存在重复快递单号，此包裹请通过提单号进行扫描`,
  key1006432: `非等待揽收状态不可作废`,
  key1006433: `作废供应商提单失败`,
  key1006434: `站点名称已经被使用，请更改其他站点名`,
  key1006435: `仅支持已创建、备货中订单进行取消，请刷新数据查看`,
  key1006436: `未设置云卖物流规则`,
  key1006437: `回传样品发货信息失败`,
  key1006438: `获取大仓收货地址失败`,
  key1006439: `商品条码查询失败`,
  key1006440: `查询寄样单列表`,
  key1006441: `未匹配到发货方式`,
  key1006442: `物流代缴，清关费不可为空`,
  key1006443: `无有效的发货信息`,
  key1006444: `平台单不存在交易信息`,
  key1006445: `订单已发货`,
  key1006446: `出库单非已出库状态`,
  key1006447: `商品非待审核状态`,
  key1006448: `已关注该店铺`,
  key1006449: `商品分类信息不存在`,
  key1006450: `已取消收藏`,
  key1006451: `当前待上架提单不是已入库状态`,
  key1006452: `重置包裹状态失败`,
  key1006453: `未匹配到仓库，请联系客服处理`,
  key1006454: `容器非货品分拣完成状态`,
  key1006455: `已经处于完成上架状态，请勿重复操作`,
  key1006456: `该货品已经分拣完成`,
  key1006457: `未设置刊登规则`,
  key1006458: `未匹配到库区，请联系客服处理`,
  key1006459: `SKU与货位号不匹配，请检查后重试`,
  key1006460: `SKU已完拣`,
  key1006461: `账单不是付款状态`,
  key1006462: `必传其一`,
  key1006463: `供应商未设置归属仓库`,
  key1006464: `未完全分配ymsWarehouseLocation`,
  key1006465: `速卖通线上发货UserNick必传`,
  key1006466: `包裹所在仓未授权`,
  key1006467: `物流交接单仓库与包裹仓库不匹配`,
  key1006468: `物流交接单仓库与包裹物流不一致`,
  key1006469: `缺少包裹`,
  key1006470: `物流商编号已存在`,
  key1006471: `三方平台不存在`,
  key1006472: `获取店铺的发货,退货地址失败`,
  key1006473: `当前云卖订单未发货`,
  key1006474: `当前订单物流方式不是云卖物流`,
  key1006475: `速卖通打印面单接口失败`,
  key1006476: `单次最多可处理200个包裹，请移出后再结袋`,
  key1006477: `分销商备货单已经支付，不可再次支付`,
  key1006478: `分类不存在`,
  key1006479: `订单编号查询订单Id集合异常`,
  key1006480: `包裹信息保存失败`,
  key1006481: `获取物流商异常`,
  key1006482: `打印面单异常`,
  key1006483: `订单已经存在包裹`,
  key1006484: `物流商帐号不存在`,
  key1006485: `存在未回收包裹`,
  key1006486: `包裹订单数据异常`,
  key1006487: `获取订单异常`,
  key1006488: `更新订单打印时间失败`,
  key1006489: `发货系统包裹数据与订单系统数据不一致`,
  key1006490: `出库单下发物流未成功`,
  key1006491: `出库单标记打印失败,部分包裹已经进行其他打印方式`,
  key1006492: `包裹未交运`,
  key1006493: `无效的可用库存数请输入大于0可用库存数`,
  key1006494: `发货台内当前无商品，无法打印配货清单`,
  key1006495: `修改备货单货品数量失败`,
  key1006496: `当前提单非等待揽收状态，不可修改物流`,
  key1006497: `当前提单非自有物流，不可修改物流`,
  key1006498: `获取仓库异常`,
  key1006499: `更新包裹包装状态异常`,
  key1006500: `用户没有开启分拣时打印地址面单`,
  key1006501: `存在商品在使用该品牌，不能删除!`,
  key1006502: `提交存在相同属性`,
  key1006503: `变参商品空属性`,
  key1006504: `属性key不一致`,
  key1006505: `删除SKU异常`,
  key1006506: `上传图片路径为空`,
  key1006507: `普通商品spu与sku必须一致`,
  key1006508: `变参商品属性不能为空`,
  key1006509: `特性标签包含逗号`,
  key1006510: `非变参不能添加格外sku`,
  key1006511: `spu不能为空`,
  key1006512: `cnName不能为空`,
  key1006513: `分类ID不能为空`,
  key1006514: `productGoodsList不能为空`,
  key1006515: `sku未匹配`,
  key1006516: `请求参数为空`,
  key1006517: `取消分配失败`,
  key1006518: `取消分配失败出库单不存在`,
  key1006519: `取消分配失败已发货、已取消、已生成拣货单或已拣货的不可取消`,
  key1006520: `取消分配失败删除拣货单失败`,
  key1006521: `取消分配失败还原原库存数据`,
  key1006522: `(分配之前`,
  key1006523: `)失败`,
  key1006524: `取消分配失败修改库存产品明细数据失败`,
  key1006525: `(已分配数量`,
  key1006526: `取消分配失败产品分配记录删除失败`,
  key1006527: `操作出库单失败`,
  key1006528: `明细数据为空`,
  key1006529: `未找到出库单`,
  key1006530: `操作货品失败`,
  key1006531: `拣货状态不正确`,
  key1006532: `发货状态状态不正确`,
  key1006533: `物流地址为空，请填写物流地址`,
  key1006534: `地址不匹配，请联系在线客服咨询`,
  key1006535: `调用支付宝转账异常`,
  key1006536: `同步平台地址失败`,
  key1006537: `仅待审核客户可进行审核，请重新选择`,
  key1006538: `速卖通接口异常`,
  key1006539: `pms物流账号token为空`,
  key1006540: `包裹号为空`,
  key1006541: `商品链接为空`,
  key1006542: `物流取消异常`,
  key1006543: `获取ioss税率配置失败`,
  key1006544: `当前订单不是手工单`,
  key1006545: `未查询到客户编号所对应的联系人`,
  key1006546: `未查询到库存统计表中要更新的数据YmsInventoryWarehouse`,
  key1006547: `修改alibabagj收货地址失败`,
  key1006548: `确认订单付款报错，接口异常`,
  key1006549: `阿里国际平台单非手工取消`,
  key1006550: `发货重试`,
  key1006551: `未找到已匹配商品的分销商平台订单`,
  key1006552: `wms发货失败`,
  key1006553: `未找到符合订单条件的云卖物流`,
  key1006554: `wms取消失败`,
  key1006555: `仓库为空`,
  key1006556: `当前拣货单中包含状态为非`,
  key1006557: `待拣货/拣货中`,
  key1006558: `包裹下的ordershipingid已经存在`,
  key1006559: `获取发货设置异常`,
  key1006560: `出库单列表数量无效`,
  key1006561: `分配库存异常`,
  key1006562: `未出库的包裹`,
  key1006563: `更新账单和出库单状态失败`,
  key1006564: `同一平台下不能存在相同站点`,
  key1006565: `请确认商户号是否正确`,
  key1006566: `拣货单正在包装中`,
  key1006567: `拣货单不是待拣货或拣货中`,
  key1006568: `当前云卖面单正在拣货复核中`,
  key1006569: `商品状态错误`,
  key1006570: `拣货单状态异常`,
  key1006571: `获取包装设置异常`,
  key1006572: `未匹配到指定云卖订单`,
  key1006573: `未获取面单异常`,
  key1006574: `库区名称name重复`,
  key1006575: `库区库位不能都为null`,
  key1006576: `可用库存不能小于0`,
  key1006577: `生成云卖面单异常`,
  key1006578: `无效的仓储库存信息ID`,
  key1006579: `仅支持对待审核状态账单进行批量审核，请检查后重试`,
  key1006580: `仅支持对待打款状态账单进行批量审核，请检查后重试`,
  key1006581: `无此API权限，请联系客服开通`,
  key1006582: `备货单拆单失败`,
  key1006583: `订单同名标签已存在`,
  key1006584: `编辑商品`,
  key1006585: `尺码表模板`,
  key1006586: `新增/编辑尺码表模板`,
  key1006587: `待审核列表`,
  key1006588: `审核/编辑商品`,
  key1006589: `供应商订单详情`,
  key1006590: `分销商备货单`,
  key1006591: `供应商备货单`,
  key1006592: `供应商备货单详情`,
  key1006593: `入库管理`,
  key1006594: `提单管理`,
  key1006595: `提单管理详情`,
  key1006596: `查看出库单详情`,
  key1006597: `拣货单列表`,
  key1006598: `见货出单设置`,
  key1006599: `物流商交接单管理`,
  key1006600: `物流商交接单管理详情`,
  key1006601: `供应商管理`,
  key1006602: `供应商列表`,
  key1006603: `供应商审核`,
  key1006604: `供应商等级管理`,
  key1006605: `客户管理`,
  key1006606: `客户列表`,
  key1006607: `拓客链接`,
  key1006608: `供应商账单`,
  key1006609: `供应商账单详情`,
  key1006610: `客户账单详情`,
  key1006611: `物流账单详情`,
  key1006612: `仓储账单详情`,
  key1006613: `密码管理`,
  key1006614: `物流管理`,
  key1006615: `基础物流`,
  key1006616: `智能物流规则`,
  key1006617: `仓库管理`,
  key1006618: `库区管理`,
  key1006619: `库位管理`,
  key1006620: `消息模板`,
  key1006621: `容器管理`,
  key1006622: `容器类型设置`,
  key1006623: `地址库管理`,
  key1006624: `出入库流程配置`,
  key1006625: `IOSS税率设置`,
  key1006626: `申报规则`,
  key1006627: `自动分仓规则`,
  key1006628: `内容管理`,
  key1006629: `问卷调查`,
  key1006630: `编辑问卷`,
  key1006631: `操作指引`,
  key1006632: `数据中心`,
  key1006633: `营销管理`,
  key1006634: `站点管理`,
  key1006635: `活动管理`,
  key1006636: `新增/编辑活动`,
  key1006637: `广告管理`,
  key1006638: `分销商费用配置`,
  key1006639: `消息、邮箱查看`,
  key1006640: `员工管理`,
  key1006641: `任务中心`,
  key1006642: `导入任务`,
  key1006643: `导出任务`,
  key1006644: `订单中心`,
  key1006645: `订单列表`,
  key1006646: `备货单详情`,
  key1006647: `发货记录`,
  key1006648: `发货记录详情`,
  key1006649: `商品中心`,
  key1006650: `新增/编辑商品`,
  key1006651: `品牌管理`,
  key1006652: `第三方平台`,
  key1006653: `平台授权`,
  key1006654: `对账管理`,
  key1006655: `账户管理`,
  key1006656: `收款账户`,
  key1006657: `出库单详情`,
  key1006658: `提货单`,
  key1006659: `拣货作业`,
  key1006660: `出库作业`,
  key1006661: `出库作业设置`,
  key1006662: `商城`,
  key1006663: `分销市场`,
  key1006664: `收藏的商品`,
  key1006665: `生成售卖记录`,
  key1006666: `自动规则`,
  key1006667: `店铺管理`,
  key1006668: `收藏的供应商`,
  key1006669: `平台订单详情`,
  key1006670: `添加Temu订单`,
  key1006671: `权限管理`,
  key1006672: `角色管理`,
  key1006673: `用户管理`,
  key1006674: `价格变动详情`,
  key1006675: `账号管理`,
  key1006676: `安全信息`,
  key1006677: `商品、店铺列表`
}
