import Vue from 'vue';
import VueI18n from 'vue-i18n';
import local362b335c4290494d978cc0655e938dd4 from './lang/en-US';
import local4e5d8af6e3ae44ddbee8afb0a7d66b43 from './lang/ko-KR';
import local34d494db196040458163ba4f0d4ae918 from './lang/zh-CN';

Vue.use(VueI18n);
function handleSystemLanguage() {
  let language = '';
  let defaultLanguage = window.navigator.language;
  if (defaultLanguage) {
    if (['zh', 'zh-CN'].includes(defaultLanguage)) {
      language = 'zh-CN';
    } else if (['en', 'en-US'].includes(defaultLanguage)) {
      language = 'en-US';
    } else {
      language = 'zh-CN';
    }
    return language;
  }
}
let lang = localStorage.getItem('lang')　|| handleSystemLanguage() || 'zh-CN';
localStorage.setItem('lang', lang);
Vue.config.lang = lang;

var localeMap = {"en-US":"English","ko-KR":"한국어","zh-CN":"简体中文"}
const messages = {'en-US':{...local362b335c4290494d978cc0655e938dd4},'ko-KR':{...local4e5d8af6e3ae44ddbee8afb0a7d66b43},'zh-CN':{...local34d494db196040458163ba4f0d4ae918}};
export const i18n = new VueI18n({
				  locale: lang,
				  messages
				});
