
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';
// 多语种动态日志-- 目前只处理分销商系统涉及的日志
import {setConfigCharacters} from "@/utils/common";

export const dynamicLog = (obj, contentType, system) => {
  // 动态类型值
  let typeObj = {
    LF: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001473'),
    FT: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001474')
  }

  // 处理地址的动态日志数据问题
  function handleAddress(obj) {
    let newList = [];
    let addressLoglist = [
      {
        paramsKey: ['oldBuyerName', 'newBuyerName'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001294')} [ ${obj.oldBuyerName} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerName} ]`
      },
      {
        paramsKey: ['oldBuyerEmail', 'newBuyerEmail'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001295')} [ ${obj.oldBuyerEmail} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerEmail} ]`
      },
      {
        paramsKey: ['oldBuyerPhone', 'newBuyerPhone'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001296')} [ ${obj.oldBuyerPhone} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerPhone} ]`
      },
      {
        paramsKey: ['oldBuyerAccountId', 'newBuyerAccountId'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001297')} [ ${obj.oldBuyerAccountId} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerAccountId} ]`
      },
      {
        paramsKey: ['oldBuyerCountryCode', 'newBuyerCountryCode'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001298')} [ ${obj.oldBuyerCountryCode} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerCountryCode} ]`
      },
      {
        paramsKey: ['oldBuyerMobile', 'newBuyerMobile'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001299')} [ ${obj.oldBuyerMobile} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerMobile} ]`
      },
      {
        paramsKey: ['oldBuyerAddress1', 'newBuyerAddress1'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001300')} [ ${obj.oldBuyerAddress1} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerAddress1} ]`
      },
      {
        paramsKey: ['oldBuyerAddress2', 'newBuyerAddress2'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001301')} [ ${obj.oldBuyerAddress2} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerAddress2} ]`
      },
      {
        paramsKey: ['oldBuyerCity', 'newBuyerCity'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001302')}  [ ${obj.oldBuyerCity} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerCity} ]`
      },
      {
        paramsKey: ['oldBuyerState', 'newBuyerState'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001303')} [ ${obj.oldBuyerState} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerState} ]`
      },
      {
        paramsKey: ['oldBuyerPostalCode', 'newBuyerPostalCode'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001304')} [ ${obj.oldBuyerPostalCode} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerPostalCode} ]`
      },
      {
        paramsKey: ['oldBuyerPassportCode', 'newBuyerPassportCode'],
        value: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001305')} [ ${obj.oldBuyerPassportCode} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000429')} [ ${obj.newBuyerPassportCode} ]`
      },
    ];
    if (Object.keys(obj).length > 0) {
      for (let key in obj) {
        addressLoglist.map((item) => {
          if (item.paramsKey.includes(key)) {
            newList.push(item.value)
          }
        })
      }
    }
    newList.unshift(`${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001306')} ${obj.packageCode} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001307')}`);
    let text = [...new Set(newList)].join(';');
    return text;
  }

  // 处理有些动态日志不需要展示在分销商但是需要展示运营
  function handleDynamicLogs(obj, system) {
    let text = '';
    if (contentType === 40) {
      if (system === 'distributor') {
        text = `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001308')}${obj.currency} ${obj.amount}`;
      } else if (system === 'yms') {
        text = `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001309')}${obj.currency} ${obj.amount}`;
      }
    }
    if (contentType === 48) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001310')}${obj.totalPriceCurrency}  ${obj.totalPrice}
         ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001311')}${obj.totalPriceCurrency}  ${obj.distributorStockCost}
         ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001312')}${obj.supplierTotalPriceCurrency}  ${obj.supplierTotalPrice}`
      }
    }
    if (contentType === 52) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${obj.subPackageCode} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001313')}`;
      }
    }
    return text;
  }

  /*;是用于页面显示的时候换行展示*/
  let logsObj = {
    1: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001314')}${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001315')}`,
    2: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001316')}`,
    3: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001317')}`,
    4: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001318')}`,
    5: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001319')}`,
    6: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001320')}`,
    7: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001321')}${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')} [ ${obj.packageCode} ]`,
    8: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001323')} ${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')} [ ${obj.packageCode} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001324')} ${obj.ruleName} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001325')} ${obj.ymsLogisName} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001326')} ${typeObj[obj.dynamicMessage]}`,
    9: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001327')}${obj.platformItemSku}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001328')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    10: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001329')}${obj.platformItemSku}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001330')}${setConfigCharacters}SKU`,
    11: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001331')}${obj.platformItemSku}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001328')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    12: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001332')}${obj.platformItemSku}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001328')}${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001333')}`,
    13: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001332')}${obj.platformItemSku}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001330')}${setConfigCharacters}SKU`,
    14: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001323')} ${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')} [ ${obj.packageCode} ]`,
    15: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')} [ ${obj.packageCode} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001334')}`,
    16: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001335')}${obj.carrierShippingMethodName} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001336')}${obj.trackingNumber} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001337')}${obj.thirdPartyNo}`,
    17: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001338')}${obj.message}`,
    18: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001339')}${obj.trackingNumber}`,
    19: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001340')}${obj.message}`,
    20: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')} [ ${obj.packageCode} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001341')}`,
    21: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')} [ ${obj.packageCode} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001342')}`,
    22: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000879')} ${obj.packageCode} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001343')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001344')})`,
    23: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')} [ ${obj.packageCode} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001341')} ${obj.message}`,
    24: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001306')} ${obj.packageCode} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001345')}`,
    25: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001306')} ${obj.packageCode} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001346')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001347')} [ ${obj.oldCarrierContent} ] ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001348')} [ ${obj.newCarrierContent} ]`,
    26: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001306')} ${obj.packageCode} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001349')}`,
    27: handleAddress(obj),
    28: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001350')}`,
    29: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001351')}`,
    30: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000495')}`,
    31: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000494')}`,
    32: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001352')}`,
    33: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001353')}`,
    34: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001354')}${obj.iossName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001355')}${obj.iossCode}`,
    35: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001356')}${obj.iossName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001355')}${obj.iossCode}`,
    36: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001357')}${obj.iossName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001358')}${obj.iossCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001359')}${obj.platformIds}`,
    37: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001357')}${obj.iossName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001358')}${obj.iossCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001360')}${obj.shopIds}`,
    38: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001361')}${obj.iossName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001358')}${obj.iossCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001359')}${obj.platformIds}`,
    39: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001361')}${obj.iossName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001358')}${obj.iossCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001360')}${obj.shopIds}`,
    40: handleDynamicLogs(obj, system),
    41: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001362')}${obj.currency} ${obj.amount}`,
    42: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001363')}${obj.currency} ${obj.amount}`,
    43: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001364')}`,
    44: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001365')}`,
    45: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001366')}`,
    46: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001367')} ${obj.amount}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001368')}${obj.currency}`,
    47: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001369')}`,
    48: handleDynamicLogs(obj, system),
    49: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001370')}`,
    50: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001371')}${obj.estimateArrivalOfGoodsTime}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001372')}${obj.ymsSkuContent}`,
    51: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001373')}${obj.ymsSkuContent}`,
    52: handleDynamicLogs(obj, system),
    53: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001374')}`,
    54: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001375')}`,
    55: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000494')}${obj.cnValue}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000217')}`,
    56: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000495')}${obj.cnValue}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000217')}`,
    57: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000976')}${obj.cnValue}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000217')}`,
    58: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000226')}${obj.content}`,
    59: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001376')}`,
    60: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001377')}`,
    61: `${obj.subPackageCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001313')}`,
    62: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001378')}`,
    63: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001379')}`,
    64: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001380')}${obj.carrierShippingMethodName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001381')}${obj.trackingNumber}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001382')}${obj.thirdPartyNo}`,
    65: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001383')}`,
    66: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001384')}`,
    67: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001385')}`,
    68: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001386')}`,
    69: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001387')}`,
    70: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001388')}`,
    71: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001389')}${obj.rate}`,
    72: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001390')}${obj.rate}`,
    73: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001391')}`,
    74: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001392')}`,
    75: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001393')}`,
    76: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001394')}${obj.ymsPickingOrderCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001395')}`,
    77: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001162')}`,
    78: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001396')}`,
    79: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001397')}`,
    80: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001398')}${obj.code}】`,
    81: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001399')}`,
    82: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001400')}`,
    83: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001401')}`,
    84: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001402')}`,
    85: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001403')}`,
    86: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001404')}`,
    87: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001405')}`,
    88: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000649')}`,
    89: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001406')}${obj.describe}`,
    90: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001407')}  ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001408')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001409')}${obj.describe}`,
    91: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001410')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001408')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000649')}`,
    92: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001407')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001408')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000649')}`,
    93: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001410')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001408')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001411')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001406')}${obj.describe}`,
    94: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001412')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001413')}${obj.subsidyAmount}${obj.subsidyAmountCurrency}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001414')}${obj.modifySubsidyMoney}${obj.subsidyAmountCurrency}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001415')}${obj.describe}`,
    95: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001405')}`,
    96: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001416')}`,
    97: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001417')}`,
    98: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001418')}`,
    99: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001419')}`,
    100: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001420')}`,
    101: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001421')}`,
    102: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001422')}${obj.auditRemarks}`,
    103: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001423')}`,
    104: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001424')}`,
    105: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001425')}`,
    106: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001426')}${obj.carrierName}：${obj.carrierShippingMethodName}`,
    107: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001427')}`,
    108: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001160')}`,
    109: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001428')}${obj.packageCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001429')}${obj.warehouseName}`,
    110: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001431')}${obj.invalidReason || aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001430')}`,
    111: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001432')}${obj.oldName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001433')}${obj.newName}]`,
    112: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000879')}${obj.packageCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001323')}${obj.carrierName}_${obj.carrierShippingMethodName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001434')}(${obj.ruleName})`,
    113: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000879')}${obj.packageCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001323')}${obj.carrierName}_${obj.carrierShippingMethodName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001435')}`,
    114: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000879')}${obj.packageCode}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001323')}${obj.carrierName}_${obj.carrierShippingMethodName}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001436')}`,
    115: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001437')}`,
    116: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001438')}`,
    117: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001439')}`,
    118: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001440')}`,
    119: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001441')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001442')}${obj.trackingNumber})`,
    120: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001438')} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001443')}${obj.carrierErrorMsg})`,
    121: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001444')}`,
    122: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001445')}`,
    123: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001446')}`,
    124: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001447')}${obj.businessId}】`,
    125: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001448')}`,
    126: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001449')}`,
    127: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001450')}`,
    128: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001451')}`,
    129: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001452')}`,
    130: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001453')}`,
    131: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001454')}`,
    132: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001455')}`,
    133: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001456')}`,
    134: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001457')}${obj.invalidReason}`,
    135: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001458')}`,
    136: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001459')}`,
    137: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001460')}`,
    138: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001461')}${obj.renewalPrice}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001462')}${obj.renewalMonth} ${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001463')}`,
    139: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001464')}`,
    140: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001465')}${obj.auditRemarks}`,
    141: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001466')}${obj.content}`,
    142: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001467')}`,
    143: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001468')}${obj.packageTagName}`,
    144: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001469')}${obj.packageTagName}`,
    145: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001470')}`,
    146: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001471')}${obj.content}`,
    147: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001472')}`
  };
  return logsObj[contentType];

}
