
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';
// 拣货状态
import {setConfigCharacters} from "@/utils/common";

export const YMS_STATUS_LIST = [
  {value: 1, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001151')},
  {value: 2, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001152')},
  {value: 3, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001153')},
  {value: 4, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001154')},
  {value: 5, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001155')},
  {value: 6, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001156')},
  {value: 7, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001157')},
  {value: 8, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001158')},
];

// 出库单状态
export const YMS_PACKAGE_INFO_STATUS = [
  {value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001159')},
  {value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001160')},
  {value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001151')},
  {value: 4, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001161')},
  {value: 5, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001162')},
  {value: 6, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001163')},
  {value: 7, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001164')},
  {value: 8, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001158')}
];

export const PICK_STATUS_LIST = [
  {
    value: 1,
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001165')
  },
  {
    value: 2,
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001139')
  },
  {
    value: 3,
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001154')
  },
  {
    value: 4,
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001140')
  },
  {
    value: 5,
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001166')
  },
  {
    value: 21,
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001167')
  },
  {
    value: 99,
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001168')
  }
];

export const YMS_PACKAGE_STATUS = [
  {
    value: 0,
    title: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001138')}`
  },
  {
    value: 9,
    title: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001139')}`
  },
  {
    value: 10,
    title: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001140')}`
  },
  {
    value: 11,
    title: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001141')}`
  },
  {
    value: 12,
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001169')
  }
];

export const YMS_SUBPACKAGE_STATUS = [
  {value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001151')},
  {value: 4, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001161')},
  {value: 5, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001170')},
  {value: 6, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001163')},
  {value: 7, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001141')},
  {value: 8, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001158')}
];

// listing inputType
export const LISTING_INPUT_TYPE = [
  {
    value: '1',
    label: 'text'
  }, {
    value: '2',
    label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000452')
  }, {
    value: '3',
    label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001171')
  }, {
    value: '4',
    label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000451')
  }, {
    value: '5',
    label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001172')
  }
];


// 规则是否类型
export const RULE_TYPE_IS = ['order-service-A044', 'order-service-A045', 'order-service-A042', 'order-service-A040', 'order-service-A035', 'order-service-A036', 'order-service-A037', 'order-service-A038', 'order-service-A047', 'order-service-A048', 'order-service-A046',
  'CORE-YCCR-045', 'DISTRIBUTOR-DCCR-A024', 'DISTRIBUTOR-DCCR-A028'
];

// 规则单个input 类型
export const RULE_TYPE_INPUT = ['CORE-YCCR-003', 'CORE-YCCR-004', 'DISTRIBUTOR-DCCR-A005', 'DISTRIBUTOR-DCCR-A004'];

// 规则 两个checkbox input 类型
export const RULE_TYPE_TCI = [
  'CORE-YCCR-014', 'CORE-YCCR-015', 'CORE-YCCR-016', 'CORE-YCCR-017', 'CORE-YCCR-018', 'CORE-YCCR-019', 'CORE-YPDR-008', 'CORE-YPDR-006', 'CORE-YPDR-009', 'CORE-YCCR-010', 'CORE-YCCR-013', 'DISTRIBUTOR-DCCR-A012', 'DISTRIBUTOR-DCCR-A016', 'DISTRIBUTOR-DCCR-A017', 'DISTRIBUTOR-DCCR-A018', 'DISTRIBUTOR-DCCR-A019', 'DISTRIBUTOR-DCCR-A020', 'DISTRIBUTOR-DCCR-A021', 'DISTRIBUTOR-DCCR-A022'];

// 平台订单状态
export const PLATFORM_ORDER_STATUS = [
  {label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095'), value: 'null'},
  {label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001159'), value: 1},
  {label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001173'), value: 2},
  {label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001174'), value: 3},
  {label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001175'), value: 4},
  {label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001164'), value: 5},
  {label: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001158'), value: 6},
];

// 三方平台订单状态
export const THIRH_PARTY_PLATFORM_ORDER_STATUS = [
  {value: 0, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001176')},
  {value: 1, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001177')},
  {value: 2, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001164')},
  {value: 21, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001178')},
  {value: 3, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001027')},
  {value: 31, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001179')},
  {value: 4, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001158')},
  {value: 41, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001180')}
];

// 供应商--出库单类型
export const OUTBOUNDTYPELIST = [ // 1:销售出库 2:云卖中心仓备货 3:三方仓备货 null:全部
  {value: 0, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {value: 1, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001181')},
  {value: 2, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001182')},
  {value: 3, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001183')},
  {value: 4, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001184')},
];

// 云卖订单状态
export const CLOUDSALESORDERSTATUS = [
  {selected: true, value: 0, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001159')},
  {selected: false, value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001160')},
  {selected: false, value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001151')},
  {selected: false, value: 4, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001161')},
  {selected: false, value: 5, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001162')},
  {selected: false, value: 6, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001163')},
  {selected: false, value: 7, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001164')},
  {selected: false, value: 8, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001158')}
];

// 销售状态
export const SALESSTATUS = [
  {selected: true, value: null, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000395')},
  {selected: false, value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000338')},
  {selected: false, value: 0, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000394')},
];

// 审核状态
export const AUDITSTATUS = [
  {selected: true, value: 'null', title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 0, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001185')},
  {selected: false, value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001186')},
  {selected: false, value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001187')},
  {selected: false, value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000649')}
];

// 费用类型
export const EXPENSETYPE = [
  {selected: true, value: null, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001188')},
  {selected: false, value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001189')},
  {selected: false, value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001190')},
  {selected: false, value: 4, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001191')},
  {selected: false, value: 5, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001192')},
  {selected: false, value: 6, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001193')},
  {selected: false, value: 7, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001194')},
  {selected: false, value: 8, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001195')}
];

// 启用停用状态
export const ENABLEDISABLESTATUS = [
  {selected: true, value: null, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000494')},
  {selected: false, value: 0, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000495')}
];

//面单类型
export const EACESHEETTYPE = [
  {selected: true, value: null, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 'LOGIS', title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000108')},
  {selected: false, value: 'YMS', title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000109')},
  {selected: false, value: 'NONE', title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000110')},
  {selected: false, value: 'MIX', title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000111')}
];

// 异常状态
export const ABNORMALSTATE = [
  {selected: true, value: 0, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001196')},
  {selected: false, value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000335')}
];

// 商品状态
export const COMMODITYSTATUS = [
  {selected: true, value: null, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 0, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000338')},
  {selected: false, value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000395')},
  {selected: false, value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000795')},
  {selected: false, value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000796')}
];

// 费用流水状态
export const EXPENSEFLOWSTATUS = [
  {selected: true, value: null, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001197')},
  {selected: false, value: 0, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001198')}
];

// 注册来源
export const REGISTER_SOURCE = [
  {value: 0, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {value: 1, title: 'TONGTOOL'},
  {value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001199')},
  {value: 4, title: 'Shopify'},
  {value: 5, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001200')},
  {value: 6, title: 'H5'}
];

// 结算状态(供应商系统) 1 待审核状态 2 待确认 3 等待收款/付款 4 已收款/已付款 5 作废
export const SUPPLIER_SETTLEMENTSTATUS = [
  {selected: true, value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001201')},
  {selected: false, value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001202')},
  {selected: false, value: 4, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001203')}
];
// 结算状态(运营系统)
export const YMS_SETTLEMENTSTATUS = [
  {selected: false, value: 'null', title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {selected: false, value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001186')},
  {selected: true, value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001204')},
  {selected: false, value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001205')},
  {selected: false, value: 4, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001206')}
];
// 语种对应
export const mappingLang = {
  EN: 'usName',  // 英文
  FRA: 'frName', // 法文
  GER: 'deName', // 德文
  SPN: 'esName', //西班牙
  NL: 'nlName',  // 荷兰语
  PLN: 'plName', // 波兰
  PT: 'ptName',  // 葡萄牙
  IT: 'itName',  // 意大利
  CN: 'cnName'   // 中文
};

const fixPath = 'https://www.yms.com/yms-distributor-service/filenode/s'; // 固定写死
export const videos = {
  1: fixPath + aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001207'),
  2: fixPath + aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001208'),
  3: fixPath + aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001209'),
  4: fixPath + aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001210'),
  5: fixPath + aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001211'),
  6: fixPath + aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001212'),
};
export const DELIVERYORDERTYPE = [
  // {value: 1, title: '销售出库'},
  {value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001182')},
  {value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001183')},
  {value: 4, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001184')}
];

// 多语种名称
export const MULTILINGUALNAMESLIST = {
  CN: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001213'),
  US: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001214'),
// {type: 'frName', name: '法语'},
// {type: 'deName', name: '德语'},
// {type: 'esName', name: '西班牙语'},
// {type: 'nlName', name: '荷兰语'},
// {type: 'plName', name: '波兰语'},
// {type: 'ptName', name: '葡萄牙语'},
// {type: 'itName', name: '意大利语'}
}

// 消息模板
export const INFORMATIONTYPELIST = [
  {
    value: 1,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000335'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001215'),
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001216'),
    dynamicParamList: [aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001217')], // 动态参数
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001218')
  },
  {
    value: 2,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001219'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001220'),
    dynamicParamList: ['X'], // 动态参数
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001221')
  },
  {
    value: 3,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001222'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001223'),
    dynamicParamList: ['X'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001224'),
  },
  {
    value: 4,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001225'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001226'),
    dynamicParamList: ['X'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001227')
  },
  {
    value: 5,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001228'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001229'),
    dynamicParamList: ['X'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001230')
  },
  {
    value: 6,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001231'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001232'),
    dynamicParamList: ['X'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001233')
  },
  {
    value: 7,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000097'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001234'),
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001235'),
    dynamicParamList: [aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001217')],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001236')
  },
  {
    value: 8,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000338'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001237'),
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001238'),
    dynamicParamList: [aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001239'), 'SPU/SKU'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001240')
  },
  {
    value: 9,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001241'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001242'),
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001243'),
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001241'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001244')
  },
  {
    value: 10,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001245'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001246'),
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001247'),
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001245'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001248')
  },
  {
    value: 11,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001249'),  // 触发条件的文案
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001250'), // 消息类型名称
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001251'), // 消息的标题
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001249'),
    dynamicParamList: [aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000783')],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001252')
  },
  {
    value: 12,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001253'),  // 触发条件的文案
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001254'), // 消息类型名称
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001254'), // 消息的标题
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001253'),
    dynamicParamList: [aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001255')],
    messageTemplate: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001142')}<span class="highlight_color">${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001143')}</span>${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001144')}${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001145')}`
  },
  {
    value: 13,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001256'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001257'),
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001256'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001258')
  },
  {
    value: 14,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001259'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001260'),
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001259'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001261')
  },
  {
    value: 15,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001262'),
    name: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001146')}`,
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001262'),
    dynamicParamList: [`${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000344')}`],
    messageTemplate: `${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001147')}<span class="highlight_color">【${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001148')}</span>${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001149')}`
  },
  {
    value: 16,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001263'),  // 触发条件的文案
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001264'), // 消息类型名称
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001265'), // 消息的标题
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001263'),
    dynamicParamList: [aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001266'), aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000344')],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001267')
  },
  {
    value: 17,
    text: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001268'),
    name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001269'),
    title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001269'), // 消息的标题
    triggerConditionTxt: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001268'),
    dynamicParamList: ['SPU'],
    messageTemplate: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001270')
  },
];

// 供应商--订单列表--出库单状态
export const SUPPLIEROUTPACKAGEORDERSTATUS = [
  {value: 1, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001151')},
  {value: 2, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001155')},
  {value: 3, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001271')},
  {value: 4, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001141')},
  {value: 5, title: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001150')}`},
  {value: 6, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001272')},
  {value: 7, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001168')},
  {value: 8, title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001163')}
]

// 平台订单类型
export const PLATFORMORDERTYPE = [
  {value: 'null', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {value: 'GENERAL', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001273')},
  {value: 'TEMU_S', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001274')},
  {value: 'TEMU_P', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001275')},
  {value: 'ONE_STOP_SERVICE', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001276')},
]

// IOSS 类型
export const IOSSTYPE = [
  {value: 1, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001277')},
  {value: 2, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001278')},
  {value: 3, name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001279')},
]

// 翻译之后的云卖订单状态(订单轨迹)
export const YMSORDERSTATUSLIST = [
  {sort: 0, value: 'null', key: 'null', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000095')},
  {sort: 1, value: 0, key: 'createdTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001159')},
  {sort: 2, value: 13, key: 'createdTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001280')},
  {sort: 3, value: 1, key: 'printTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001281')},
  {sort: 4, value: 2, key: 'pickingFinishTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001282')},
  {sort: 5, value: 3, key: 'packingTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001283')},
  {sort: 6, value: 4, key: 'supperBoxedTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001284')},
  {sort: 7, value: 5, key: 'despatchTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001285')},
  {sort: 8, value: 11, key: 'allottedTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001286')},
  {sort: 9, value: 6, key: 'allottedTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001151')},
  {sort: 10, value: 7, key: 'pickedTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001155')},
  {sort: 11, value: 8, key: 'changedLabelTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001271')},
  {sort: 12, value: 9, key: 'boxedTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001287')},
  {sort: 13, value: 10, key: 'shipedTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001288')},
  {sort: 14, value: 12, key: 'cancelledTime', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001158')},
];

export const ATTRIBUTEMULTILINGUALNAME = [
  {key: 'cnName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000463')},
  {key: 'usName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001289')},
  {key: 'ukName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001290')},
  {key: 'auName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001291')},
  {key: 'euName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001292')},
  {key: 'frName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000468')},
  {key: 'deName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000469')},
  {key: 'esName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000470')},
  {key: 'nlName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000471')},
  {key: 'plName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000472')},
  {key: 'ptName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000473')},
  {key: 'itName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000474')},
  {key: 'roName', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000475')},
];

export const ATTRIBUTEVALUEDATA = [
  {key: 'cnValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000463')},
  {key: 'usValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001289')},
  {key: 'ukValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001290')},
  {key: 'auValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001291')},
  {key: 'euValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001292')},
  {key: 'frValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000468')},
  {key: 'deValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000469')},
  {key: 'esValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000470')},
  {key: 'nlValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000471')},
  {key: 'plValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000472')},
  {key: 'ptValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000473')},
  {key: 'itValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000474')},
  {key: 'roValue', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000475')},
];

export const PRODUCTTYPES = [
  {title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000614'), value: 1},
  {title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000616'), value: 2},
  {title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000615'), value: 3},
];

export const VISIBLEAFTERLOGIN = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001293');

// 无站点的平台
export const NOSITEPLATFORMLIST = [
  'wish', 'joom', '1688', 'aliexpress', 'fruugo', 'ozon', 'shopify', 'temu', 'tiktok',
  'walmart', 'aliexpressChoice', 'alibaba', 'temu_choice'
];

