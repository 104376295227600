
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 配置全局loading有遮罩层，防止误操作
 * @author jing
 * @date 2020/12/24 9:24
 * @params:
 */
import {Spin} from 'view-design';
import MSG from '@/assets/message/message/index';

export function startLoading(text) {
  Spin.show({
    render: (h) => {
      let txt = text ? text : aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001117');
      return h('div', {
        style: {
          zIndex: 9999,
          backgroundColor: '#fff',
          padding: '32px 30px',
          borderRadius: '12px'
        }
      }, [
        h('Icon', {
          'class': 'loading_modal_style',
          props: {
            type: 'ios-loading',
            size: 28
          }
        }),
        h('div', {
          style: {
            marginTop: '5px',
            fontSize: '17px'
          }
        }, txt)
      ]);
    }
  });
}

export function endLoading() {
  return new Promise(resolve => {
    Spin.hide();
    resolve(true)
  });
}

let needLoadingRequestCount = 0;

export function showLoadingModal(text) {
  if (needLoadingRequestCount === 0) {
    startLoading(text);
  }
  needLoadingRequestCount++;
}

export function hideLoadingModal(status) {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    if (status === 200) {
      endLoading();
    } else if (status === 504) {
      MSG.error({
        content: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001118'),
        duration: 5,
        level: 1,
        closable: true
      });
      endLoading();
    }
  }
}
